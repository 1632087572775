import React from "react";

import Select from "react-select";
import {Loading} from "store-fetch-wrappers/dist";
import {PulseStaffDropdownProps, usePulseStaffDropdown} from "./Hooks/usePulseStaffDropdown";

/** Dropdown that will show all users assigned in an entry or all users in the database. */
const PulseStaffDropdown = (props: PulseStaffDropdownProps) => {
    const {staffOptions, handleStaffChange, selectedOption, staffListStore} =
        usePulseStaffDropdown(props);
    return (
        <React.Fragment>
            {staffListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={staffOptions}
                    onChange={handleStaffChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Staff Members Found"}
                    placeholder="Select Staff Member"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
};

export default PulseStaffDropdown;
