import React from "react";
import {WitnessDetailsV1, YesNoType} from "../../../../../../../../../api/reports/api";
import {toArray} from "../../../../../../../../../utils/sortingUtils";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";

const WitnessDetails = (props: WitnessDetailsV1[]) => {
    return (
        <React.Fragment>
            {toArray(props).length > 0 && (
                <React.Fragment>
                    <ReportHeader
                        padding={"0.75rem"}
                        marginTop={"0px"}
                        alignment={"left"}
                        reportHeaderText={`Witnesses`}
                    />
                    {toArray(props).map((item: WitnessDetailsV1, index) => {
                        return (
                            <React.Fragment key={index}>
                                <PrintableFormRow rowName={"Witness Name"} rowContent={item.name} />
                                <PrintableFormRow
                                    rowName={"Contactable"}
                                    rowContent={item.contactable}
                                />
                                {item.contactable === YesNoType.Yes && (
                                    <PrintableFormRow
                                        rowName={"Telephone"}
                                        rowContent={item.telephone}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default WitnessDetails;
