import React from "react";
import {IncidentDetailsV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../../utils/momentUtils";
import {CalendarTypeEnum} from "../../../../../../../../../api/grs";
import VenueLocationDetails from "./VenueLocationDetails";
import FrontlineLocationDetails from "./FrontlineLocationDetails";

const IncidentDetails = (props: IncidentDetailsV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Incident Details`}
            />
            <PrintableFormRow rowName={"Division"} rowContent={props.divisionName} />
            <PrintableFormRow
                rowName={"Date Closed"}
                rowContent={
                    <p>
                        {props.dateOfIncident
                            ? formatUnixToDDMMYYYY(props.dateOfIncident)
                            : "Date not added"}
                    </p>
                }
            />
            {props.calendarType && props.calendarType === CalendarTypeEnum.Event ? (
                <VenueLocationDetails {...props.locationOfIncident} />
            ) : (
                <FrontlineLocationDetails
                    cadNumber={props.cadNumber}
                    frontlineDetails={props.frontlineDetails}
                    nhsRefNumber={props.nhsRefNumber}
                />
            )}
        </React.Fragment>
    );
};

export default IncidentDetails;
