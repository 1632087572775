import React from "react";
import {ManagerIncidentFormV1} from "../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../Form/FormHeader";
import InvestigatingManager from "./InvestigatingManager/InvestigatingManager";
import SeverityDetails from "./Severity/SeverityDetails";
import DutyOfCandourDetails from "./DutyOfCandour/DutyOfCandourDetails";
import InvestigationDetails from "./InvestigationDetails/InvestigationDetails";
import ClosureOfIncident from "./ClosureOfIncident/ClosureOfIncident";

const ManagerIncidentForm = (props: ManagerIncidentFormV1) => {
    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Manager Incident Form"} />
                <InvestigatingManager {...props.investigatingManager} />
                <SeverityDetails {...props.severity} />
                <DutyOfCandourDetails {...props.dutyOfCandour} />
                <InvestigationDetails {...props.investigationDetails} />
                <ClosureOfIncident {...props.closure} />
            </div>
        </React.Fragment>
    );
};

export default ManagerIncidentForm;
