import RouteModal from "../RouteModal/RouteModal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import React from "react";
import {createRoot} from "react-dom/client";

const UserLeaveConfirmation = (
    message: any,
    callback: any,
    confirmOpen: any,
    setConfirmOpen: any
) => {
    const container = document.createElement("div");

    container.setAttribute("custom-confirm-view", "");
    const root = createRoot(container);
    const handleConfirm = (callbackState?: any) => {
        root.unmount();
        callback(callbackState);
        setConfirmOpen(false);
        container.remove();
    };

    const handleCancel = (callbackState?: any) => {
        root.unmount();
        callback();
        setConfirmOpen(false);
        container.remove();
    };

    document.body.appendChild(container);
    const {header, content} = JSON.parse(message);

    root.render(
        <RouteModal
            modalSize={"sm"}
            title={header}
            onClose={handleCancel}
            bodyChildren={
                <div className={"row ml-0 mr-0"}>
                    <p>{content}</p>
                </div>
            }
            footerChildren={
                <div className={"row ml-0 mr-0"}>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Yes"}
                        onClick={handleConfirm}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={handleCancel}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </div>
            }
            isShown={true}
        />
    );
};

export default UserLeaveConfirmation;
