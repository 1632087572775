import React from "react";
import {
    EquipmentList,
    StaffEquipmentDefectFormV1,
    YesNoType
} from "../../../../../../../../api/reports/api";
import ReportHeader from "../../../Components/ReportHeader";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";

const StaffEquipmentDefectDetails = (props: StaffEquipmentDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Staff Section - Equipment Defect Form`}
            />
            <PrintableFormRow
                rowName={"Calendar"}
                rowContent={<p>{props.location.calendarName}</p>}
            />
            <PrintableFormRow
                rowName={"Incident date"}
                rowContent={
                    <p>
                        {props.dateOfDefect
                            ? formatUnixToDDMMYYYY(props.dateOfDefect)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow rowName={"Equipment"} rowContent={<p>{props.equipment}</p>} />
            {props.equipment === EquipmentList.Other && (
                <PrintableFormRow
                    rowName={"Please specify other"}
                    rowContent={<p>{props.otherEquipment}</p>}
                />
            )}
            <PrintableFormRow rowName={"Serial Number"} rowContent={<p>{props.serialNumber}</p>} />
            <PrintableFormRow
                rowName={"Location of equipment"}
                rowContent={<p>{props.locationOfEquipment}</p>}
            />
            <PrintableFormRow
                rowName={"Defect details"}
                rowContent={<p>{props.detailsOfDefect}</p>}
            />
            <PrintableFormRow
                rowName={"Equipment taken out of commission and correctly tagged?"}
                rowContent={<p>{props.equipmentCorrectlyTaggedAndDealtWith}</p>}
            />
            {props.equipmentCorrectlyTaggedAndDealtWith === YesNoType.No && (
                <PrintableFormRow
                    rowName={"If no, please state why."}
                    rowContent={<p>{props.detailsOfNotBeingTakenOutCorrectly}</p>}
                />
            )}
        </React.Fragment>
    );
};

export default StaffEquipmentDefectDetails;
