import React from "react";
import {DutyOfCandourV1, YesNoType} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import Dropdown from "../../../../../../../../Dropdown/Dropdown";
import {useDispatch} from "react-redux";
import {setDutyOfCandourDetails} from "../../../../../../../../../store/reports/actions/ReportActions";
import {DebounceInput} from "react-debounce-input";
import {StaffAccessLevel} from "../../../../../../../../../api/staff";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../../Button/DatePickerInputButton";
import AuthAmI from "../../../../../../../../AuthAmI/AuthAmI";
import ReportStateAmI from "../../../../../../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../../../../../../api/im";
import UploadDownloadActions from "../../../../../Upload/UploadDownloadActions";
import {useReport} from "../../../../../../../../Hooks/useReport";
import {canUserEditManagerIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";
import PulseStaffDropdown from "../../../../../../../../Dropdown/Components/PulseStaffDropdown";

const DutyOfCandourDetails = (props: DutyOfCandourV1) => {
    const dispatch = useDispatch();
    const report = useReport();
    const canEdit = canUserEditManagerIncidentSection();
    return (
        <React.Fragment>
            <FormHeader headerName={"Duty of Candour"} />
            <FormRow rowName={"Duty of Candour Engaged?"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.engaged}
                    changeOption={(item) => {
                        if (!item) return;
                        const details = getDutyOfCandourDetailsFromYesNoAnswer(
                            item.toString(),
                            props
                        );
                        dispatch(setDutyOfCandourDetails(details));
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.engaged === YesNoType.No ? (
                <FormRow rowName={"Reason For Not Engaging"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            const details: DutyOfCandourV1 = {
                                ...props,
                                notEngagedAnswer: event.target.value
                            };
                            dispatch(setDutyOfCandourDetails(details));
                        }}
                        disabled={!canEdit}
                        value={props.notEngagedAnswer}
                        placeholder="Enter not engaged reason here..."
                    />
                </FormRow>
            ) : (
                <React.Fragment>
                    <FormRow rowName={"Nominated Individual"} columnDetailClassName={"pl-0 pr-0"}>
                        <PulseStaffDropdown
                            searchable={false}
                            onChange={(item) => {
                                if (!item) return;
                                const updatedSection: DutyOfCandourV1 = {
                                    ...props,
                                    nominatedIndividual: item.staffId
                                };

                                dispatch(setDutyOfCandourDetails(updatedSection));
                            }}
                            clearable={false}
                            username={props.nominatedIndividual}
                            disabled={!canEdit}
                            accessLevelFilter={StaffAccessLevel.SystemAdministrator}
                        />
                    </FormRow>
                    <FormRow
                        rowName={"Date of Initial Contact"}
                        columnDetailClassName={"pl-0 pr-0"}
                    >
                        <DatePicker
                            selected={
                                props.dateOfInitialContact
                                    ? moment(props.dateOfInitialContact * 1000).toDate()
                                    : undefined
                            }
                            onChange={(date) => {
                                const newDate = date ? moment(date).unix() : undefined;
                                dispatch(
                                    setDutyOfCandourDetails({
                                        ...props,
                                        dateOfInitialContact: newDate
                                    })
                                );
                            }}
                            dateFormat="do MMMM yyyy"
                            portalId="root-portal"
                            isClearable={canEdit}
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                            disabled={!canEdit}
                        />
                    </FormRow>
                    <FormRow rowName={"Date of Letter Sent"} columnDetailClassName={"pl-0 pr-0"}>
                        <DatePicker
                            selected={
                                props.dateOfLetterSent
                                    ? moment(props.dateOfLetterSent * 1000).toDate()
                                    : undefined
                            }
                            onChange={(date) => {
                                const newDate = date ? moment(date).unix() : undefined;
                                dispatch(
                                    setDutyOfCandourDetails({
                                        ...props,
                                        dateOfLetterSent: newDate
                                    })
                                );
                            }}
                            dateFormat="do MMMM yyyy"
                            portalId="root-portal"
                            isClearable={canEdit}
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                            disabled={!canEdit}
                        />
                    </FormRow>
                    <FormRow
                        rowName={"Date of Final Letter and Report"}
                        columnDetailClassName={"pl-0 pr-0"}
                    >
                        <DatePicker
                            selected={
                                props.dateOfFinalLetterAndReport
                                    ? moment(props.dateOfFinalLetterAndReport * 1000).toDate()
                                    : undefined
                            }
                            onChange={(date) => {
                                const newDate = date ? moment(date).unix() : undefined;
                                dispatch(
                                    setDutyOfCandourDetails({
                                        ...props,
                                        dateOfFinalLetterAndReport: newDate
                                    })
                                );
                            }}
                            dateFormat="do MMMM yyyy"
                            portalId="root-portal"
                            isClearable={canEdit}
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                            disabled={!canEdit}
                        />
                    </FormRow>
                    <FormRow rowName={"Duty Of Candour File"} columnDetailClassName={"pl-3 pr-3"}>
                        {props.filename ? (
                            <p className="mb-2">{props.filename}</p>
                        ) : (
                            <p className="mb-2">No document has been uploaded</p>
                        )}
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <ReportStateAmI
                                currentReportState={report.state}
                                reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                            >
                                <UploadDownloadActions
                                    fileName={props.filename}
                                    onFileUploaded={(fileName) => {
                                        dispatch(
                                            setDutyOfCandourDetails({
                                                ...props,
                                                filename: fileName
                                            })
                                        );
                                    }}
                                />
                            </ReportStateAmI>
                        </AuthAmI>
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default DutyOfCandourDetails;

function getDutyOfCandourDetailsFromYesNoAnswer(
    yesNoAnswer: string,
    props: DutyOfCandourV1
): DutyOfCandourV1 {
    switch (yesNoAnswer) {
        case YesNoType.No:
            return {
                ...props,
                engaged: YesNoType.No,
                notEngagedAnswer: "",
                nominatedIndividual: "",
                dateOfInitialContact: undefined,
                dateOfFinalLetterAndReport: undefined,
                dateOfLetterSent: undefined
            };
        case YesNoType.Yes:
            return {
                ...props,
                engaged: YesNoType.Yes,
                notEngagedAnswer: undefined,
                nominatedIndividual: "",
                dateOfInitialContact: undefined,
                dateOfFinalLetterAndReport: undefined,
                dateOfLetterSent: undefined
            };
        default:
            return props;
    }
}
