import React from "react";
import {Report} from "../../../../api/im";
import {IncidentType} from "../../../../api/reports/api";
import EditIncidentForm from "./Forms/Incident/Edit/EditIncidentForm";
import EditComplaintForm from "./Forms/Complaint/Edit/EditComplaintForm";
import EditVehicleDefectForm from "./Forms/VehicleDefect/Edit/EditVehicleDefectForm";
import EditEquipmentDefectForm from "./Forms/EquipmentDefect/Edit/EditEquipmentDefectForm";

const ReportFormContainer = (report: Report) => {
    return (
        <React.Fragment>
            {report.type === IncidentType.Incident && (
                <EditIncidentForm {...JSON.parse(report.payload)} />
            )}
            {report.type === IncidentType.Complaint && (
                <EditComplaintForm {...JSON.parse(report.payload)} />
            )}
            {report.type === IncidentType.VehicleDefect && (
                <EditVehicleDefectForm {...JSON.parse(report.payload)} />
            )}
            {report.type === IncidentType.EquipmentDefect && (
                <EditEquipmentDefectForm {...JSON.parse(report.payload)} />
            )}
        </React.Fragment>
    );
};

export default ReportFormContainer;
