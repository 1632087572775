import React from "react";
import ReportHeader from "../../Components/ReportHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {IncidentType, VehicleDefectFormV1} from "../../../../../../../api/reports/api";
import StaffSectionVehicleDefectReport from "./PartA/StaffSectionVehicleDefectReport";
import AdminSectionVehicleDefectReport from "./PartB/AdminSectionVehicleDefectReport";
import ReportFooter from "../../Components/ReportFooter";
import PrintableNotes from "../Notes/PrintableNotes";

const PrintVehicleDefectReport = ({
    staffVehicleDefectForm,
    adminVehicleDefectForm,
    notes
}: VehicleDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"2rem"}
                marginTop={"0px"}
                alignment={"right"}
                reportHeaderText={`Medicare EMS Group UK - ${getUiFriendlyText(
                    IncidentType.VehicleDefect
                )}`}
            />
            <StaffSectionVehicleDefectReport {...staffVehicleDefectForm} />
            <AdminSectionVehicleDefectReport {...adminVehicleDefectForm} />
            <PrintableNotes {...notes} />
            <ReportFooter />
        </React.Fragment>
    );
};

export default PrintVehicleDefectReport;
