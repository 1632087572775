import {StoreServiceData} from "store-fetch-wrappers";
import {Report} from "../../../api/im";
import {createStoreState} from "store-fetch-wrappers/dist";

export const REPORT_STORE_STATE = createStoreState("report");

export interface ReportLoading extends StoreServiceData<Report> {
    type: typeof REPORT_STORE_STATE.LOADING;
}

export interface ReportError extends StoreServiceData<Report> {
    type: typeof REPORT_STORE_STATE.ERROR;
}

export interface ReportSuccess extends StoreServiceData<Report> {
    type: typeof REPORT_STORE_STATE.SUCCESS;
}

export type ReportDispatchTypes = ReportSuccess | ReportError | ReportLoading;
