import React from "react";
import {
    ComplaintFormInvestigationDetailsV1,
    ComplaintStatus,
    GradeOfComplaintType,
    YesNoType
} from "../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../Form/FormRow";
import {setComplaintInvestigationDetails} from "../../../../../../../../store/reports/actions/ReportActions";
import {StaffAccessLevel} from "../../../../../../../../api/staff";
import {useDispatch} from "react-redux";
import Dropdown from "../../../../../../../Dropdown/Dropdown";
import {DebounceInput} from "react-debounce-input";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import AuthAmI from "../../../../../../../AuthAmI/AuthAmI";
import ReportStateAmI from "../../../../../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../../../../../api/im";
import UploadDownloadActions from "../../../../Upload/UploadDownloadActions";
import {useReport} from "../../../../../../../Hooks/useReport";
import {canUserEditComplaintFormPartB} from "../../../../../../../Hooks/useCanUserEditForms";
import PulseStaffDropdown from "../../../../../../../Dropdown/Components/PulseStaffDropdown";

const InvestigationDetails = (props: ComplaintFormInvestigationDetailsV1) => {
    const dispatch = useDispatch();
    const report = useReport();
    const canEdit = canUserEditComplaintFormPartB();
    return (
        <React.Fragment>
            <FormHeader headerName={"Investigation Details"} />
            <FormRow rowName={"Investigating Manager"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseStaffDropdown
                    searchable={false}
                    onChange={(item) => {
                        if (!item) return;

                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                investigatingManager: item.staffId
                            })
                        );
                    }}
                    clearable={false}
                    username={props.investigatingManager}
                    disabled={!canEdit}
                    accessLevelFilter={StaffAccessLevel.SystemAdministrator}
                />
            </FormRow>
            <FormRow rowName={"Grade"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.gradeOfComplaint}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                gradeOfComplaint: item.toString()
                            })
                        );
                    }}
                    options={Object.values(GradeOfComplaintType)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Investigation Details"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                investigationDetails: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.investigationDetails}
                    placeholder="Enter investigation details here..."
                />
            </FormRow>
            <FormRow rowName={"Learning"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                learning: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.learning}
                    placeholder="Enter learning here..."
                />
            </FormRow>
            <FormRow rowName={"Status"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.status}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                status: item.toString()
                            })
                        );
                    }}
                    options={Object.values(ComplaintStatus)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Date Closed"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateClosed ? moment(props.dateClosed * 1000).toDate() : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                dateClosed: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Response Sent?"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.complaintResponseSent}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setComplaintInvestigationDetails({
                                ...props,
                                complaintResponseSent: item.toString()
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.complaintResponseSent === YesNoType.Yes && (
                <React.Fragment>
                    <FormRow rowName={"Date Response Sent"} columnDetailClassName={"pl-0 pr-0"}>
                        <DatePicker
                            selected={
                                props.dateResponseSent
                                    ? moment(props.dateResponseSent * 1000).toDate()
                                    : undefined
                            }
                            onChange={(date) => {
                                const newDate = date ? moment(date).unix() : undefined;
                                dispatch(
                                    setComplaintInvestigationDetails({
                                        ...props,
                                        dateResponseSent: newDate
                                    })
                                );
                            }}
                            dateFormat="do MMMM yyyy"
                            portalId="root-portal"
                            isClearable={canEdit}
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                            disabled={!canEdit}
                        />
                    </FormRow>
                    <FormRow rowName={"Response File"} columnDetailClassName={"pl-3 pr-3"}>
                        {props.filename ? (
                            <p className="mb-2">{props.filename}</p>
                        ) : (
                            <p className="mb-2">No document has been uploaded</p>
                        )}
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <ReportStateAmI
                                currentReportState={report.state}
                                reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                            >
                                <UploadDownloadActions
                                    fileName={props.filename}
                                    onFileUploaded={(fileName) => {
                                        dispatch(
                                            setComplaintInvestigationDetails({
                                                ...props,
                                                filename: fileName
                                            })
                                        );
                                    }}
                                />
                            </ReportStateAmI>
                        </AuthAmI>
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default InvestigationDetails;
