import React, {useRef, useState} from "react";
import {EquipmentDefectFormV1, IncidentType, YesNoType} from "../../../../../../../api/reports/api";
import {Prompt, useHistory} from "react-router-dom";
import FormHeader from "../../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import ReportFormMessage from "../../../Messages/ReportFormMessage";
import {useReport} from "../../../../../../Hooks/useReport";
import {Report, ReportState} from "../../../../../../../api/im";
import AdditionalNotesSection from "../../../../AddtionalNotes/AdditionalNotesSection";
import {useDispatch} from "react-redux";
import {usePageUrl} from "../../../../../../Hooks/usePageUrl";
import {canUserEditDefectForm} from "../../../../../../Hooks/useCanUserEditForms";
import {routeNames} from "../../../../../../Navigation/routeNames";
import {updateReportVersionNumber} from "../../Incident/Edit/EditIncidentForm";
import {saveReportToService} from "../../../../../../../store/reports/actions/ReportActions";
import {showErrorToast, showSuccessToast} from "../../../../../../../utils/toastUtils";
import StaffEquipmentDefectDetails from "./PartA/StaffEquipmentDefectDetails";
import {StaffAccessLevel} from "../../../../../../../api/staff";
import AuthAmI from "../../../../../../AuthAmI/AuthAmI";
import ReportStateAmI from "../../../../../ReportList/Components/Shared/ReportStateAmI";
import AdminEquipmentDefectForm from "./PartB/AdminEquipmentDefectForm";
import FormActionContainer from "../../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import PrintButton from "../../../Print/Button/PrintButton";
import PrintEquipmentDefectReport from "../../../Print/Forms/EquipmentDefect/PrintEquipmentDefectReport";

const EditEquipmentDefectForm = (props: EquipmentDefectFormV1) => {
    const report = useReport();
    const initialReport = useRef(report);
    const history = useHistory();
    const dispatch = useDispatch();
    const {searchQueries} = usePageUrl();
    const [saving, setSaving] = useState<boolean>(false);
    const canEdit = canUserEditDefectForm();

    const saveReport = async () => {
        setSaving(true);
        const valid = validateEquipmentDefectForm(report, props);
        if (!valid) {
            setSaving(false);
            return;
        }

        const payload: EquipmentDefectFormV1 = updateReportVersionNumber(props);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveReportToService(JSON.stringify(payload)));

        if (!success) {
            setSaving(false);
            return;
        }
        showSuccessToast("Saved Report");
        backToReportList();
    };

    const backToReportList = () => {
        const path = routeNames.reportList.path;
        history.push({
            pathname: path,
            search: searchQueries
        });
    };
    return (
        <React.Fragment>
            <Prompt
                when={!saving}
                message={JSON.stringify({
                    header: "Navigate Away",
                    content:
                        "You have unsaved changes! Any files uploaded, details added will not be saved! Are you sure you want to leave?"
                })}
            />
            <FormHeader headerName={`${getUiFriendlyText(IncidentType.EquipmentDefect)} Report`} />
            <ReportFormMessage />
            <AuthAmI
                accessLevels={[
                    StaffAccessLevel.Staff,
                    StaffAccessLevel.DutyManager,
                    StaffAccessLevel.SystemAdministrator
                ]}
            >
                <StaffEquipmentDefectDetails {...props.staffEquipmentDefectForm} />
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                >
                    <AdminEquipmentDefectForm {...props.adminEquipmentDefectForm} />
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <AdminEquipmentDefectForm {...props.adminEquipmentDefectForm} />
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                {initialReport.current.state === ReportState.CompletedReview && (
                    <AdditionalNotesSection notes={props.notes} reportVersion={props.version} />
                )}
            </AuthAmI>
            <FormActionContainer>
                <React.Fragment>
                    {canEdit && (
                        <MCButton
                            size={ButtonSize.Large}
                            roundedCorner
                            innerValue={"Save"}
                            onClick={saveReport}
                            colour={ButtonColourOptions.Yellow}
                        />
                    )}
                    <MCButton
                        size={ButtonSize.Large}
                        roundedCorner
                        innerValue={"Cancel"}
                        onClick={backToReportList}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <ReportStateAmI
                            currentReportState={initialReport.current.state}
                            reportStates={[ReportState.CompletedReview]}
                        >
                            <PrintButton toPrint={<PrintEquipmentDefectReport {...props} />} />
                        </ReportStateAmI>
                    </AuthAmI>
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditEquipmentDefectForm;

function validateEquipmentDefectForm(report: Report, form: EquipmentDefectFormV1): boolean {
    switch (report.state) {
        case ReportState.PendingReview:
            return validatePending(form);
        case ReportState.UnderReview:
            return true;
        case ReportState.CompletedReview:
            return canCompleteEquipmentDefectForm(form);
    }
}

function validatePending(form: EquipmentDefectFormV1): boolean {
    if (form.staffEquipmentDefectForm.location.calendarId === 0) {
        showErrorToast("A calendar must be selected");
        return false;
    }
    return true;
}

/** Validation for Vehicle defect form */
function canCompleteEquipmentDefectForm(form: EquipmentDefectFormV1): boolean {
    let valid = true;

    const {
        equipmentTakenOutOfCommission,
        equipmentNotTakenOutOfCommission,
        equipmentRepairable,
        positiveActionTaken,
        negativeActionTaken,
        dateClosed
    } = form.adminEquipmentDefectForm;

    if (
        equipmentTakenOutOfCommission === YesNoType.No &&
        equipmentNotTakenOutOfCommission.length === 0
    ) {
        showErrorToast("A reason must be provided for not taking equipment out of commission.");
        valid = false;
    }

    if (equipmentRepairable === YesNoType.Yes && positiveActionTaken.length === 0) {
        showErrorToast("Actions must be recorded for any repairs made to equipment.");
        valid = false;
    }

    if (equipmentRepairable === YesNoType.No && negativeActionTaken.length === 0) {
        showErrorToast("Actions must be recorded for actions against repairing the equipment.");
        valid = false;
    }

    if (!dateClosed) {
        showErrorToast("A closed date must be specified to complete report.");
        valid = false;
    }

    return valid;
}
