import React from "react";
import {AdminVehicleDefectFormV1, YesNoType} from "../../../../../../../../api/reports/api";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import ReportHeader from "../../../Components/ReportHeader";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";

const AdminSectionVehicleDefectReport = (props: AdminVehicleDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Admin Section - Vehicle Defect Form`}
            />
            <PrintableFormRow rowName={"Defect Grade"} rowContent={<p>{props.defectGrade}</p>} />
            <PrintableFormRow
                rowName={"Vehicle taken out of commission?"}
                rowContent={<p>{props.vehicleTakenOutOfCommission}</p>}
            />
            {props.vehicleTakenOutOfCommission === YesNoType.No && (
                <PrintableFormRow
                    rowName={"Please record why"}
                    rowContent={<p>{props.vehicleNotTakenOutOfCommissionReason}</p>}
                />
            )}
            <PrintableFormRow
                rowName={"Date booked for repair"}
                rowContent={
                    <p>
                        {props.dateBookedForRepair
                            ? formatUnixToDDMMYYYY(props.dateBookedForRepair)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow
                rowName={"Name of garage / provider"}
                rowContent={<p>{props.nameOfGarage}</p>}
            />
            <PrintableFormRow
                rowName={"Estimated time for repair (if applicable)"}
                rowContent={<p>{props.estimatedTimeForRepair}</p>}
            />
            <PrintableFormRow
                rowName={"Date vehicle returned"}
                rowContent={
                    <p>
                        {props.dateVehicleReturned
                            ? formatUnixToDDMMYYYY(props.dateVehicleReturned)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow
                rowName={"Comments"}
                rowContent={<p>{props.comments ? props.comments : "Comments not added"}</p>}
            />
            <PrintableFormRow
                rowName={"Vehicle defect file"}
                rowContent={<p>{props.filename ? props.filename : "File not added"}</p>}
            />
            <PrintableFormRow
                rowName={"Date closed"}
                rowContent={
                    <p>
                        {props.dateClosed
                            ? formatUnixToDDMMYYYY(props.dateClosed)
                            : "Date not added"}
                    </p>
                }
            />
        </React.Fragment>
    );
};

export default AdminSectionVehicleDefectReport;
