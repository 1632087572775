import {StoreServiceData} from "store-fetch-wrappers";
import {UserData} from "../../../api/staff";
import {createStoreState} from "store-fetch-wrappers/dist";

export const STAFF_STORE_STATE = createStoreState("staff_store");

interface StaffLoading extends StoreServiceData<UserData> {
    type: typeof STAFF_STORE_STATE.LOADING;
}
interface StaffError extends StoreServiceData<UserData> {
    type: typeof STAFF_STORE_STATE.ERROR;
}
interface StaffSuccess extends StoreServiceData<UserData> {
    type: typeof STAFF_STORE_STATE.SUCCESS;
}

export type StaffDispatchTypes = StaffSuccess | StaffError | StaffLoading;
