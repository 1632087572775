// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Incident Management (IM) API
 * API to manipulate IM entities
 *
 * OpenAPI spec version: 0.1-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:9003".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * A very flexible container for a reported issue. Any staff member can create a report, but only admins or those access levels listed in 'modifyAccessLevels' can subsequently update a report. The payload is a raw JSON string that can contain any content relevant to the report.
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * Unique identifier for the report. 0 will insert a brand new report.
     * @type {number}
     * @memberof Report
     */
    id: number;
    /**
     * Grouping name for this report. For example 'Serious Incident' or 'Fault Report'
     * @type {string}
     * @memberof Report
     */
    type: string;
    /**
     * Unique ID (username) for user that created the report. Can't be modified, and will be overridden when saved to the logged in user.
     * @type {string}
     * @memberof Report
     */
    staffId: string;
    /**
     * Real name of the user that created the report. Can't be modified, and will be overridden when saved to the logged in user.
     * @type {string}
     * @memberof Report
     */
    staffName: string;
    /**
     * Linux epoch for when the report was first created. Can't be modified, and will be overridden when first created by the server
     * @type {number}
     * @memberof Report
     */
    dateCreated: number;
    /**
     * Linux epoch for when the report was last modified. Will be overridden by the server on saves.
     * @type {number}
     * @memberof Report
     */
    dateModified: number;
    /**
     * Level(s) of users that can modify the report. Should always have at least one level populated.
     * @type {Array<StaffAccessLevel>}
     * @memberof Report
     */
    modifyAccessLevels: Array<StaffAccessLevel>;
    /**
     * 
     * @type {ReportState}
     * @memberof Report
     */
    state: ReportState;
    /**
     * Optional ID for the calendar this report is for
     * @type {number}
     * @memberof Report
     */
    calendarId?: number;
    /**
     * Optional name of the calendar this report is for
     * @type {string}
     * @memberof Report
     */
    calendarName?: string;
    /**
     * Raw JSON content for the report, any valid JSON is allowed. Approx 5MB limit
     * @type {string}
     * @memberof Report
     */
    payload: string;
}

/**
 * 
 * @export
 * @interface ReportCountResponse
 */
export interface ReportCountResponse {
    /**
     * The requested 'type' of report
     * @type {string}
     * @memberof ReportCountResponse
     */
    type: string;
    /**
     * Total number of reports for 'type'
     * @type {number}
     * @memberof ReportCountResponse
     */
    count: number;
}

/**
 * Subset of 'Report' for listing purposes
 * @export
 * @interface ReportListing
 */
export interface ReportListing {
    /**
     * Unique identifier for the report. 0 will insert a brand new report.
     * @type {number}
     * @memberof ReportListing
     */
    id: number;
    /**
     * Grouping name for this report. For example 'Serious Incident' or 'Fault Report'
     * @type {string}
     * @memberof ReportListing
     */
    type: string;
    /**
     * Unique ID (username) for user that created the report. Can't be modified, and will be overridden when saved to the logged in user.
     * @type {string}
     * @memberof ReportListing
     */
    staffId: string;
    /**
     * Real name of the user that created the report. Can't be modified, and will be overridden when saved to the logged in user.
     * @type {string}
     * @memberof ReportListing
     */
    staffName: string;
    /**
     * Linux epoch for when the report was first created. Can't be modified, and will be overridden when first created by the server
     * @type {number}
     * @memberof ReportListing
     */
    dateCreated: number;
    /**
     * Linux epoch for when the report was last modified. Will be overridden by the server on saves.
     * @type {number}
     * @memberof ReportListing
     */
    dateModified: number;
    /**
     * Level(s) of users that can modify the report. Should always have at least one level populated.
     * @type {Array<StaffAccessLevel>}
     * @memberof ReportListing
     */
    modifyAccessLevels: Array<StaffAccessLevel>;
    /**
     * 
     * @type {ReportState}
     * @memberof ReportListing
     */
    state: ReportState;
    /**
     * Optional ID for the calendar this report is for
     * @type {number}
     * @memberof ReportListing
     */
    calendarId?: number;
    /**
     * Optional name of the calendar this report is for
     * @type {string}
     * @memberof ReportListing
     */
    calendarName?: string;
}

/**
 * 
 * @export
 * @interface ReportPageRequest
 */
export interface ReportPageRequest {
    /**
     * Page number, starting from 0
     * @type {number}
     * @memberof ReportPageRequest
     */
    pageNum?: number;
    /**
     * Maximum number of reports to return in a single page
     * @type {number}
     * @memberof ReportPageRequest
     */
    numPerPage?: number;
    /**
     * Optional, requested 'type' of report
     * @type {string}
     * @memberof ReportPageRequest
     */
    type?: string;
    /**
     * Optional, 'Staff ID' that the report must have
     * @type {string}
     * @memberof ReportPageRequest
     */
    staffId?: string;
    /**
     * Optional, minimum date for the 'date created' field
     * @type {number}
     * @memberof ReportPageRequest
     */
    dateCreatedStartInclusive?: number;
    /**
     * Optional, maximum date for the 'date created' field
     * @type {number}
     * @memberof ReportPageRequest
     */
    dateCreatedEndExclusive?: number;
    /**
     * Optional, minimum date for the 'date modified' field
     * @type {number}
     * @memberof ReportPageRequest
     */
    dateModifiedStartInclusive?: number;
    /**
     * Optional, maximum date for the 'date modified' field
     * @type {number}
     * @memberof ReportPageRequest
     */
    dateModifiedEndExclusive?: number;
    /**
     * Optional, calendar ID the report must have
     * @type {number}
     * @memberof ReportPageRequest
     */
    calendarId?: number;
    /**
     * 
     * @type {ReportState}
     * @memberof ReportPageRequest
     */
    reportState?: ReportState;
    /**
     * 
     * @type {ReportPageSortField}
     * @memberof ReportPageRequest
     */
    sortField?: ReportPageSortField;
    /**
     * 
     * @type {SortOrder}
     * @memberof ReportPageRequest
     */
    sortOrder?: SortOrder;
}

/**
 * 
 * @export
 * @interface ReportPageResponse
 */
export interface ReportPageResponse {
    /**
     * Page number, starting from 0
     * @type {number}
     * @memberof ReportPageResponse
     */
    pageNum: number;
    /**
     * Maximum number of reports to return in a single page
     * @type {number}
     * @memberof ReportPageResponse
     */
    numPerPage: number;
    /**
     * The total number of reports in the DB that met the criteria
     * @type {number}
     * @memberof ReportPageResponse
     */
    totalResults: number;
    /**
     * Optional, requested 'type' of report
     * @type {string}
     * @memberof ReportPageResponse
     */
    type?: string;
    /**
     * Optional, 'Staff ID' that the report must have
     * @type {string}
     * @memberof ReportPageResponse
     */
    staffId?: string;
    /**
     * Optional, minimum date for the 'date created' field
     * @type {number}
     * @memberof ReportPageResponse
     */
    dateCreatedStartInclusive?: number;
    /**
     * Optional, maximum date for the 'date created' field
     * @type {number}
     * @memberof ReportPageResponse
     */
    dateCreatedEndExclusive?: number;
    /**
     * Optional, minimum date for the 'date modified' field
     * @type {number}
     * @memberof ReportPageResponse
     */
    dateModifiedStartInclusive?: number;
    /**
     * Optional, maximum date for the 'date modified' field
     * @type {number}
     * @memberof ReportPageResponse
     */
    dateModifiedEndExclusive?: number;
    /**
     * Optional, calendar ID the report must have
     * @type {number}
     * @memberof ReportPageResponse
     */
    calendarId?: number;
    /**
     * 
     * @type {ReportState}
     * @memberof ReportPageResponse
     */
    reportState?: ReportState;
    /**
     * 
     * @type {ReportPageSortField}
     * @memberof ReportPageResponse
     */
    sortField: ReportPageSortField;
    /**
     * 
     * @type {SortOrder}
     * @memberof ReportPageResponse
     */
    sortOrder: SortOrder;
    /**
     * The found reports, if any
     * @type {Array<ReportListing>}
     * @memberof ReportPageResponse
     */
    reports: Array<ReportListing>;
}

/**
 * The field to sort on when listing reports
 * @export
 * @enum {string}
 */
export enum ReportPageSortField {
    ReportType = 'ReportType',
    CreatedBy = 'CreatedBy',
    DateCreated = 'DateCreated',
    DateModified = 'DateModified',
    ReportState = 'ReportState'
}

/**
 * Save a document intended for use with a report
 * @export
 * @interface ReportSaveDocRequest
 */
export interface ReportSaveDocRequest {
    /**
     * The MIME type for the file (eg. application/pdf)
     * @type {string}
     * @memberof ReportSaveDocRequest
     */
    contentType: string;
    /**
     * Original filename for the file (file.txt)
     * @type {string}
     * @memberof ReportSaveDocRequest
     */
    filename: string;
    /**
     * BASE64 ENCODED file
     * @type {string}
     * @memberof ReportSaveDocRequest
     */
    fileBase64: string;
}

/**
 * Response from saving a document
 * @export
 * @interface ReportSaveDocResponse
 */
export interface ReportSaveDocResponse {
    /**
     * The filename of the saved document, this will be different to the original filename
     * @type {string}
     * @memberof ReportSaveDocResponse
     */
    filename: string;
}

/**
 * The current step the report is on within the flow
 * @export
 * @enum {string}
 */
export enum ReportState {
    PendingReview = 'PendingReview',
    UnderReview = 'UnderReview',
    CompletedReview = 'CompletedReview'
}

/**
 * The direction to sort results in. Ascending = 0 - 9, Descending = 9 - 0
 * @export
 * @enum {string}
 */
export enum SortOrder {
    Ascending = 'Ascending',
    Descending = 'Descending'
}

/**
 * Level of access for a staff member, dictates what things a user can see / manipulate
 * @export
 * @enum {string}
 */
export enum StaffAccessLevel {
    SystemAdministrator = 'SystemAdministrator',
    DutyManager = 'DutyManager',
    Staff = 'Staff'
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific report
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteReport.');
            }
            const localVarPath = `/reports/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a document, if it exists
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportDocument(filename: string, options: any = {}): RequestArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling deleteReportDocument.');
            }
            const localVarPath = `/reports/docs/byfile/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a report for a specific ID
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getReport.');
            }
            const localVarPath = `/reports/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a time-limited public URL for a specific document
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDocumentUrl(filename: string, options: any = {}): RequestArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling getReportDocumentUrl.');
            }
            const localVarPath = `/reports/docs/byfile/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the total number of reports in the system for the given type
         * @param {string} type Type name for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsCount(type: string, options: any = {}): RequestArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getReportsCount.');
            }
            const localVarPath = `/reports/bytype/{type}/count`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page of reports for a specific type, ordered by date descending (latest first)
         * @param {ReportPageRequest} reportPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsPage(reportPageRequest: ReportPageRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reportPageRequest' is not null or undefined
            if (reportPageRequest === null || reportPageRequest === undefined) {
                throw new RequiredError('reportPageRequest','Required parameter reportPageRequest was null or undefined when calling getReportsPage.');
            }
            const localVarPath = `/reports/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportPageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportPageRequest || {}) : (reportPageRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification that a report has been closed
         * @param {string} id ID of the report that is closed
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;closed&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportClosed(id: string, noun?: string, verb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling notificationReportClosed.');
            }
            const localVarPath = `/notifications/report/closed/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (noun !== undefined) {
                localVarQueryParameter['noun'] = noun;
            }

            if (verb !== undefined) {
                localVarQueryParameter['verb'] = verb;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a notification that a new report with the ID is available
         * @param {string} id ID of the report that is new
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;saved&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportNew(id: string, noun?: string, verb?: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling notificationReportNew.');
            }
            const localVarPath = `/notifications/report/new/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (noun !== undefined) {
                localVarQueryParameter['noun'] = noun;
            }

            if (verb !== undefined) {
                localVarQueryParameter['verb'] = verb;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a report
         * @param {Report} report 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(report: Report, options: any = {}): RequestArgs {
            // verify required parameter 'report' is not null or undefined
            if (report === null || report === undefined) {
                throw new RequiredError('report','Required parameter report was null or undefined when calling saveReport.');
            }
            const localVarPath = `/reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Report" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(report || {}) : (report || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a document that will be attached to a report
         * @param {ReportSaveDocRequest} reportSaveDocRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportDocument(reportSaveDocRequest: ReportSaveDocRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reportSaveDocRequest' is not null or undefined
            if (reportSaveDocRequest === null || reportSaveDocRequest === undefined) {
                throw new RequiredError('reportSaveDocRequest','Required parameter reportSaveDocRequest was null or undefined when calling saveReportDocument.');
            }
            const localVarPath = `/reports/docs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReportSaveDocRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(reportSaveDocRequest || {}) : (reportSaveDocRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific report
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).deleteReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a document, if it exists
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportDocument(filename: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).deleteReportDocument(filename, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a report for a specific ID
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a time-limited public URL for a specific document
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDocumentUrl(filename: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getReportDocumentUrl(filename, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the total number of reports in the system for the given type
         * @param {string} type Type name for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsCount(type: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportCountResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getReportsCount(type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a page of reports for a specific type, ordered by date descending (latest first)
         * @param {ReportPageRequest} reportPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsPage(reportPageRequest: ReportPageRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportPageResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getReportsPage(reportPageRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Send a notification that a report has been closed
         * @param {string} id ID of the report that is closed
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;closed&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportClosed(id: string, noun?: string, verb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).notificationReportClosed(id, noun, verb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Send a notification that a new report with the ID is available
         * @param {string} id ID of the report that is new
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;saved&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportNew(id: string, noun?: string, verb?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).notificationReportNew(id, noun, verb, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a report
         * @param {Report} report 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(report: Report, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).saveReport(report, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a document that will be attached to a report
         * @param {ReportSaveDocRequest} reportSaveDocRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportDocument(reportSaveDocRequest: ReportSaveDocRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportSaveDocResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).saveReportDocument(reportSaveDocRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a specific report
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReport(id: number, options?: any) {
            return ReportsApiFp(configuration).deleteReport(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a document, if it exists
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportDocument(filename: string, options?: any) {
            return ReportsApiFp(configuration).deleteReportDocument(filename, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a report for a specific ID
         * @param {number} id Unique ID for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(id: number, options?: any) {
            return ReportsApiFp(configuration).getReport(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a time-limited public URL for a specific document
         * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDocumentUrl(filename: string, options?: any) {
            return ReportsApiFp(configuration).getReportDocumentUrl(filename, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the total number of reports in the system for the given type
         * @param {string} type Type name for the report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsCount(type: string, options?: any) {
            return ReportsApiFp(configuration).getReportsCount(type, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a page of reports for a specific type, ordered by date descending (latest first)
         * @param {ReportPageRequest} reportPageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsPage(reportPageRequest: ReportPageRequest, options?: any) {
            return ReportsApiFp(configuration).getReportsPage(reportPageRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send a notification that a report has been closed
         * @param {string} id ID of the report that is closed
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;closed&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportClosed(id: string, noun?: string, verb?: string, options?: any) {
            return ReportsApiFp(configuration).notificationReportClosed(id, noun, verb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Send a notification that a new report with the ID is available
         * @param {string} id ID of the report that is new
         * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
         * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;saved&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationReportNew(id: string, noun?: string, verb?: string, options?: any) {
            return ReportsApiFp(configuration).notificationReportNew(id, noun, verb, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a report
         * @param {Report} report 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReport(report: Report, options?: any) {
            return ReportsApiFp(configuration).saveReport(report, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a document that will be attached to a report
         * @param {ReportSaveDocRequest} reportSaveDocRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveReportDocument(reportSaveDocRequest: ReportSaveDocRequest, options?: any) {
            return ReportsApiFp(configuration).saveReportDocument(reportSaveDocRequest, options)(axios, basePath);
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific report
     * @param {number} id Unique ID for the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReport(id: number, options?: any) {
        return ReportsApiFp(this.configuration).deleteReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a document, if it exists
     * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportDocument(filename: string, options?: any) {
        return ReportsApiFp(this.configuration).deleteReportDocument(filename, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a report for a specific ID
     * @param {number} id Unique ID for the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReport(id: number, options?: any) {
        return ReportsApiFp(this.configuration).getReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a time-limited public URL for a specific document
     * @param {string} filename Filename of the file to fetch, this is the filename returned by the save request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportDocumentUrl(filename: string, options?: any) {
        return ReportsApiFp(this.configuration).getReportDocumentUrl(filename, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the total number of reports in the system for the given type
     * @param {string} type Type name for the report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportsCount(type: string, options?: any) {
        return ReportsApiFp(this.configuration).getReportsCount(type, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a page of reports for a specific type, ordered by date descending (latest first)
     * @param {ReportPageRequest} reportPageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportsPage(reportPageRequest: ReportPageRequest, options?: any) {
        return ReportsApiFp(this.configuration).getReportsPage(reportPageRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send a notification that a report has been closed
     * @param {string} id ID of the report that is closed
     * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
     * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;closed&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public notificationReportClosed(id: string, noun?: string, verb?: string, options?: any) {
        return ReportsApiFp(this.configuration).notificationReportClosed(id, noun, verb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Send a notification that a new report with the ID is available
     * @param {string} id ID of the report that is new
     * @param {string} [noun] Noun to use in the email notification. For example &#39;Incident&#39; or &#39;Vehicle Defect&#39;. Default is &#39;IM&#39;
     * @param {string} [verb] Verb to use in the email notification. For example &#39;closed&#39; or &#39;created&#39;, should be lowercase. Default is &#39;saved&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public notificationReportNew(id: string, noun?: string, verb?: string, options?: any) {
        return ReportsApiFp(this.configuration).notificationReportNew(id, noun, verb, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a report
     * @param {Report} report 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public saveReport(report: Report, options?: any) {
        return ReportsApiFp(this.configuration).saveReport(report, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a document that will be attached to a report
     * @param {ReportSaveDocRequest} reportSaveDocRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public saveReportDocument(reportSaveDocRequest: ReportSaveDocRequest, options?: any) {
        return ReportsApiFp(this.configuration).saveReportDocument(reportSaveDocRequest, options)(this.axios, this.basePath);
    }

}

