import React from "react";
import ReactDOM from "react-dom";

import {CSSTransition} from "react-transition-group";
import Icon, {IconType} from "../../Icon/Icon";

const RouteModal = ({
    modalSize,
    title,
    bodyChildren,
    footerChildren,
    onClose,
    isShown
}: ModalProps) => {
    const getModalRoot = () => {
        const modalRoot = document.getElementById("modal-root");

        return modalRoot ? modalRoot : document.body;
    };

    return ReactDOM.createPortal(
        <React.Fragment>
            <CSSTransition in={isShown} unmountOnExit timeout={{enter: 0, exit: 300}}>
                <div className="pulse-modal modal-text-colour enter-done fadeIn">
                    <div
                        className={`pulse-modal-content-${modalSize} modal-background`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="pulse-modal-header modal-background">
                            <div className="pulse-modal-header-item">
                                <h4 className="pulse-modal-title">{title}</h4>
                            </div>
                            <div className="pulse-modal-header-item">
                                <Icon
                                    rootElement={"Span"}
                                    icon={IconType.Cross}
                                    className="pulse-modal-close-button body-font-colour"
                                    size={"Small"}
                                    onClick={onClose}
                                />
                            </div>
                        </div>
                        <div className="pulse-modal-body">{bodyChildren}</div>
                        {footerChildren && (
                            <div className="pulse-modal-footer modal-background">
                                {footerChildren}
                            </div>
                        )}
                    </div>
                </div>
            </CSSTransition>
        </React.Fragment>,
        getModalRoot()
    );
};

export default RouteModal;

interface ModalProps {
    modalSize: ModalSize;
    title: string;
    bodyChildren: JSX.Element | JSX.Element[] | boolean | string | number | null | undefined;
    footerChildren?: JSX.Element | JSX.Element[] | boolean | string | number | null | undefined;
    onClose: () => void;
    isShown: boolean;
}

type ModalSize = "sm" | "md" | "lg" | "xl";
