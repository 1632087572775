import {ReportPageResponse} from "../../../api/im";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const REPORT_LIST_STORE_STATE = createStoreState("report_list");

interface ReportListLoading extends StoreServiceData<ReportPageResponse> {
    type: typeof REPORT_LIST_STORE_STATE.LOADING;
}
interface ReportListError extends StoreServiceData<ReportPageResponse> {
    type: typeof REPORT_LIST_STORE_STATE.ERROR;
}
interface ReportListSuccess extends StoreServiceData<ReportPageResponse> {
    type: typeof REPORT_LIST_STORE_STATE.SUCCESS;
}

export type ReportListDispatchTypes = ReportListError | ReportListLoading | ReportListSuccess;
