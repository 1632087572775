import React from "react";
import {
    IncidentDetailsV1,
    VenueLocationDetailsV1
} from "../../../../../../../../../../api/reports/api";
import VenueDetailItem from "./VenueDetailItem";

const VenueDetails = (props: IncidentDetailsV1) => {
    return (
        <React.Fragment>
            {props.locationOfIncident.length > 0 ? (
                <React.Fragment>
                    {props.locationOfIncident.map((item: VenueLocationDetailsV1, index) => {
                        return (
                            <VenueDetailItem
                                uid={item.uid}
                                venueId={item.venueId}
                                calendarId={props.division}
                                venueName={item.venueName}
                                address={item.address}
                                postcode={item.postcode}
                                key={index}
                            />
                        );
                    })}
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">No details were added</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default VenueDetails;
