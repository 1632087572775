/** paged request config */
export const pagedRequestConfig: PagedRequestConfig = {
    resultsPerPage: 50
};

/** Base interface for paged request */
interface PagedRequestConfig {
    resultsPerPage: number;
}

export interface RequestFilterProps<T> {
    onRequestChanged: (request: T) => void;
}
