import React, {useRef, useState} from "react";
import {ComplaintFormV1, IncidentType, YesNoType} from "../../../../../../../api/reports/api";
import FormHeader from "../../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import ReportFormMessage from "../../../Messages/ReportFormMessage";
import {Prompt, useHistory} from "react-router-dom";
import AuthAmI from "../../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../../api/staff";
import ComplaintDetails from "./PartA/ComplaintDetails";
import InvestigationDetails from "./PartB/InvestigationDetails";
import ReportStateAmI from "../../../../../ReportList/Components/Shared/ReportStateAmI";
import {useReport} from "../../../../../../Hooks/useReport";
import {Report, ReportState} from "../../../../../../../api/im";
import AdditionalNotesSection from "../../../../AddtionalNotes/AdditionalNotesSection";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import FormActionContainer from "../../../../../../Form/FormActionContainer";
import {routeNames} from "../../../../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {usePageUrl} from "../../../../../../Hooks/usePageUrl";
import {canUserEditComplaintForm} from "../../../../../../Hooks/useCanUserEditForms";
import {showErrorToast, showSuccessToast} from "../../../../../../../utils/toastUtils";
import {updateReportVersionNumber} from "../../Incident/Edit/EditIncidentForm";
import {saveReportToService} from "../../../../../../../store/reports/actions/ReportActions";
import PrintButton from "../../../Print/Button/PrintButton";
import PrintComplaintReport from "../../../Print/Forms/Complaint/PrintComplaintReport";

const EditComplaintForm = (props: ComplaintFormV1) => {
    const report = useReport();
    const initialReport = useRef(report);
    const history = useHistory();
    const dispatch = useDispatch();
    const {searchQueries} = usePageUrl();
    const [saving, setSaving] = useState<boolean>(false);
    const canEdit = canUserEditComplaintForm();

    const saveReport = async () => {
        setSaving(true);

        const valid = validateComplaintForm(report, props);
        if (!valid) {
            setSaving(false);
            return;
        }
        const payload: ComplaintFormV1 = updateReportVersionNumber(props);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveReportToService(JSON.stringify(payload)));

        if (!success) {
            setSaving(false);
            return;
        }
        showSuccessToast("Saved Report");
        backToReportList();
    };

    const backToReportList = () => {
        const path = routeNames.reportList.path;
        history.push({
            pathname: path,
            search: searchQueries
        });
    };
    return (
        <React.Fragment>
            <Prompt
                when={!saving}
                message={JSON.stringify({
                    header: "Navigate Away",
                    content:
                        "You have unsaved changes! Any files uploaded, details added will not be saved! Are you sure you want to leave?"
                })}
            />
            <FormHeader headerName={`${getUiFriendlyText(IncidentType.Complaint)} Report`} />
            <ReportFormMessage />
            <AuthAmI
                accessLevels={[StaffAccessLevel.SystemAdministrator, StaffAccessLevel.DutyManager]}
            >
                <ComplaintDetails {...props.complaintDetails} />
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                >
                    <InvestigationDetails {...props.investigationDetails} />
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <InvestigationDetails {...props.investigationDetails} />
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                {initialReport.current.state === ReportState.CompletedReview && (
                    <AdditionalNotesSection notes={props.notes} reportVersion={props.version} />
                )}
            </AuthAmI>
            <FormActionContainer>
                <React.Fragment>
                    {canEdit && (
                        <MCButton
                            size={ButtonSize.Large}
                            roundedCorner
                            innerValue={"Save"}
                            onClick={saveReport}
                            colour={ButtonColourOptions.Yellow}
                        />
                    )}
                    <MCButton
                        size={ButtonSize.Large}
                        roundedCorner
                        innerValue={"Cancel"}
                        onClick={backToReportList}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <ReportStateAmI
                            currentReportState={initialReport.current.state}
                            reportStates={[ReportState.CompletedReview]}
                        >
                            <PrintButton toPrint={<PrintComplaintReport {...props} />} />
                        </ReportStateAmI>
                    </AuthAmI>
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditComplaintForm;

function validateComplaintForm(report: Report, complaintForm: ComplaintFormV1): boolean {
    switch (report.state) {
        case ReportState.PendingReview:
            return validatePending(complaintForm);
        case ReportState.UnderReview:
            return true;
        case ReportState.CompletedReview:
            return canCompleteForm(complaintForm);
    }
}

function validatePending(complaintForm: ComplaintFormV1): boolean {
    if (complaintForm.complaintDetails.division === 0) {
        showErrorToast("A division must be selected");
        return false;
    }
    return true;
}

function canCompleteForm(complaintForm: ComplaintFormV1): boolean {
    let canComplete = true;
    const {investigationDetails} = complaintForm;
    //If it has been marked as engaged but there is no file for it. We cannot allow it to be marked as complete
    if (investigationDetails.complaintResponseSent === YesNoType.Yes) {
        if (!investigationDetails.filename) {
            canComplete = false;
            showErrorToast("Response file is missing.");
        }
    }

    return canComplete;
}
