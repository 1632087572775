import React from "react";
import {Modal, useModal} from "pulse-modal";
import {Loading} from "store-fetch-wrappers";
import Icon, {IconType} from "../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";

const MobileDeletionConfirmationModal = ({
    modalTitle,
    modalBody,
    onConfirm,
    loading,
    disabled
}: Props) => {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <button className="delete-button" onClick={toggle} disabled={disabled}>
                <Icon rootElement={"Span"} icon={IconType.Bin} size={"Large"} />
                Delete
            </button>
            <Modal
                modalSize={"sm"}
                title={modalTitle}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">{modalBody}</p>
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="col text-center">
                                {loading && <Loading type={"three-ring"} showLoadingText={false} />}
                            </div>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                const success = await onConfirm();
                                if (!success) return;
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default MobileDeletionConfirmationModal;

interface Props {
    disabled?: boolean;
    modalTitle: string;
    modalBody: string;
    onConfirm: () => Promise<boolean>;
    loading: boolean;
}
