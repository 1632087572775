import React, {useRef, useState} from "react";
import {
    IncidentReportFormV1,
    IncidentType,
    SeriousIncidentReviewOutcomeType,
    YesNoType
} from "../../../../../../../api/reports/api";
import FormHeader from "../../../../../../Form/FormHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import AuthAmI from "../../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../../api/staff";
import StaffIncidentForm from "./PartA/StaffIncidentForm";
import {useReport} from "../../../../../../Hooks/useReport";
import ReportStateAmI from "../../../../../ReportList/Components/Shared/ReportStateAmI";
import {Report, ReportState} from "../../../../../../../api/im";
import ManagerIncidentForm from "./PartB/ManagerIncidentForm";
import AdditionalNotesSection from "../../../../AddtionalNotes/AdditionalNotesSection";
import {Prompt, useHistory} from "react-router-dom";
import {canUserEditIncidentForm} from "../../../../../../Hooks/useCanUserEditForms";
import FormActionContainer from "../../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import {usePageUrl} from "../../../../../../Hooks/usePageUrl";
import {routeNames} from "../../../../../../Navigation/routeNames";
import {showErrorToast, showSuccessToast} from "../../../../../../../utils/toastUtils";
import {useDispatch} from "react-redux";
import {saveReportToService} from "../../../../../../../store/reports/actions/ReportActions";
import ReportFormMessage from "../../../Messages/ReportFormMessage";
import PrintButton from "../../../Print/Button/PrintButton";
import PrintIncidentReport from "../../../Print/Forms/Incident/PrintIncidentReport";

const EditIncidentForm = (props: IncidentReportFormV1) => {
    const report = useReport();
    const initialReport = useRef(report);
    const canEdit = canUserEditIncidentForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const {searchQueries} = usePageUrl();
    const [saving, setSaving] = useState<boolean>(false);

    const saveReport = async () => {
        setSaving(true);
        const isValid = validateIncidentForm(report, props);
        if (!isValid) {
            setSaving(false);
            return;
        }

        const payload: IncidentReportFormV1 = updateReportVersionNumber(props);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveReportToService(JSON.stringify(payload)));

        if (!success) {
            setSaving(false);
            return;
        }
        showSuccessToast("Saved Report");
        backToReportList();
    };

    const backToReportList = () => {
        const path = routeNames.reportList.path;
        history.push({
            pathname: path,
            search: searchQueries
        });
    };
    return (
        <React.Fragment>
            <Prompt
                when={!saving}
                message={JSON.stringify({
                    header: "Navigate Away",
                    content:
                        "You have unsaved changes! Any files uploaded, details added will not be saved! Are you sure you want to leave?"
                })}
            />
            <FormHeader headerName={`${getUiFriendlyText(IncidentType.Incident)} Report`} />
            <ReportFormMessage />
            <AuthAmI
                accessLevels={[
                    StaffAccessLevel.SystemAdministrator,
                    StaffAccessLevel.DutyManager,
                    StaffAccessLevel.Staff
                ]}
            >
                <StaffIncidentForm {...props.staffReport} />
            </AuthAmI>
            {/** Staff members can only see the manager report if the report is not pending review*/}
            <AuthAmI accessLevels={[StaffAccessLevel.Staff]}>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.UnderReview, ReportState.CompletedReview]}
                >
                    <ManagerIncidentForm {...props.managerReport} />
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI
                accessLevels={[StaffAccessLevel.DutyManager, StaffAccessLevel.SystemAdministrator]}
            >
                <ManagerIncidentForm {...props.managerReport} />
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                {initialReport.current.state === ReportState.CompletedReview && (
                    <AdditionalNotesSection notes={props.notes} reportVersion={props.version} />
                )}
            </AuthAmI>
            <FormActionContainer>
                <React.Fragment>
                    {canEdit && (
                        <MCButton
                            size={ButtonSize.Large}
                            roundedCorner
                            innerValue={"Save"}
                            onClick={saveReport}
                            colour={ButtonColourOptions.Yellow}
                        />
                    )}
                    <MCButton
                        size={ButtonSize.Large}
                        roundedCorner
                        innerValue={"Cancel"}
                        onClick={backToReportList}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <ReportStateAmI
                            currentReportState={initialReport.current.state}
                            reportStates={[ReportState.CompletedReview]}
                        >
                            <PrintButton toPrint={<PrintIncidentReport {...props} />} />
                        </ReportStateAmI>
                    </AuthAmI>
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditIncidentForm;

function validateIncidentForm(report: Report, incidentReport: IncidentReportFormV1): boolean {
    switch (report.state) {
        case ReportState.UnderReview:
        case ReportState.PendingReview:
            return isStaffFormValid(incidentReport);
        case ReportState.CompletedReview:
            return canReportBeCompleted(incidentReport);
    }
}

function isStaffFormValid(incidentReport: IncidentReportFormV1): boolean {
    const {incidentDetails, incidentDescription} = incidentReport.staffReport;
    let valid = true;

    if (incidentDescription.witnesses.length === 0) {
        valid = true;
    } else {
        for (const witness of incidentDescription.witnesses) {
            if (witness.name.length < 3) {
                valid = false;
                showErrorToast("One or more witness names are invalid");
            }
        }
    }

    if (incidentDetails.division === 0) {
        valid = false;
        showErrorToast("A division must be selected");
    }

    return valid;
}

function canReportBeCompleted(incidentReport: IncidentReportFormV1): boolean {
    const {severity, dutyOfCandour} = incidentReport.managerReport;
    let valid = true;

    valid = isStaffFormValid(incidentReport);

    //If it has been marked as engaged but there is no file for it. We cannot allow it to be marked as complete
    if (dutyOfCandour.engaged === YesNoType.Yes) {
        if (!dutyOfCandour.filename) {
            valid = false;
            showErrorToast("Duty of Candour file is missing.");
        }
    }

    if (
        severity.seriousIncidentReviewOutcome &&
        severity.seriousIncidentReviewOutcome !== SeriousIncidentReviewOutcomeType.Not_Applicable
    ) {
        if (!severity.filename) {
            valid = false;
            showErrorToast("Severity file is missing.");
        }
    }

    return valid;
}

export function updateReportVersionNumber<T extends {version: number}>(form: T): T {
    const newVersionNumber = form.version + 1;

    return {
        ...form,
        version: newVersionNumber
    };
}
