import React, {useEffect, useState} from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {triggerPrint} from "../Helpers/printableHelpers";

const PrintButton = (props: PrintButtonProps) => {
    const [print, setPrint] = useState<boolean>(false);

    useEffect(() => {
        const content = document.getElementById("printable");
        if (!content) return;
        triggerPrint(content);
        setTimeout(() => {
            setPrint(false);
        }, 1500);
    }, [print]);
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                roundedCorner
                innerValue={"Print"}
                onClick={() => setPrint(true)}
                colour={ButtonColourOptions.DarkBlue}
            />
            {print && (
                <div id="printable" className="d-none">
                    {props.toPrint}
                </div>
            )}
        </React.Fragment>
    );
};

export default PrintButton;

interface PrintButtonProps {
    toPrint: JSX.Element;
}
