import {Dispatch} from "redux";
import {REPORT_STORE_STATE, ReportDispatchTypes} from "./ReportActionTypes";
import {Report, ReportState} from "../../../api/im";
import ImApiModel from "../../apiModel/ImApiModel";
import {
    AdditionalNotes,
    AdminEquipmentDefectFormV1,
    ClosureOfIncidentV1,
    ComplaintFormComplaintDetailsV1,
    ComplaintFormInvestigationDetailsV1,
    ComplaintFormV1,
    createBlankComplaintFormV1,
    createBlankEquipmentDefectFormV1,
    createBlankIncidentReportFormV1,
    createBlankVehicleDefectFormV1,
    DutyOfCandourV1,
    EquipmentDefectFormV1,
    StaffEquipmentDefectFormV1,
    FrontlineLocationDetailsV1,
    IncidentDescriptionV1,
    IncidentDetailsV1,
    IncidentReportFormV1,
    IncidentSeverityV1,
    IncidentType,
    InvestigatingManagerV1,
    ManagerInvestigationDetailsV1,
    StaffVehicleDefectFormV1,
    VehicleDefectFormV1,
    VenueLocationDetailsV1,
    WitnessDetailsV1,
    YesNoType,
    AdminVehicleDefectFormV1
} from "../../../api/reports/api";
import Store, {RootStore} from "../../Store";
import moment from "moment";
import {nanoid} from "nanoid";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {
    sendClosedReportNotification,
    sendNewReportNotification
} from "../../notifications/actions/NotificationsActions";
import {splitStringByCapitalLetter} from "../../../utils/textUtils";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {deepCopy} from "../../../utils/copyUtils";
import {fetchCalendarSummaryList} from "../../calendarSummaryList/actions/CalendarSummaryListActions";
import {fetchAllVenues} from "../../venueList/actions/VenueListActions";
import {fetchAllStaff} from "../../staffList/actions/StaffListActions";

//Incident report actions
export const setIncidentFormV1 = (reportForm: IncidentReportFormV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(reportForm)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Staff Section
//Incident Details Actions
export const setIncidentDetailsV1 = (incidentDetails: IncidentDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.staffReport.incidentDetails = incidentDetails;

        const newReport: Report = {
            ...reportCopy,
            calendarName: incidentDetails.divisionName,
            calendarId: incidentDetails.division,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Frontline Detail Actions
export const setFrontlineLocationDetails = (details: FrontlineLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDetails.frontlineDetails.findIndex(
            (el: FrontlineLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.staffReport.incidentDetails.frontlineDetails[index] = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const addFrontlineLocationDetails = (details: FrontlineLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        form.staffReport.incidentDetails.frontlineDetails.push(details);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const removeFrontlineLocationDetails = (details: FrontlineLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDetails.frontlineDetails.findIndex(
            (el: FrontlineLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.staffReport.incidentDetails.frontlineDetails.splice(index, 1);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Venue Location Detail actions

export const addVenueLocationDetails = (calendarId: number) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        form.staffReport.incidentDetails.locationOfIncident.push({
            uid: nanoid(),
            calendarId,
            venueId: 0
        });

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const setVenueLocationDetails = (details: VenueLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDetails.locationOfIncident.findIndex(
            (el: VenueLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.staffReport.incidentDetails.locationOfIncident[index] = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const removeVenueLocationDetails = (details: VenueLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDetails.locationOfIncident.findIndex(
            (el: VenueLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.staffReport.incidentDetails.locationOfIncident.splice(index, 1);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Incident Description actions
export const setIncidentDescription = (incidentDescription: IncidentDescriptionV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.staffReport.incidentDescription = incidentDescription;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const addNewWitnessDetails = (details: WitnessDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        form.staffReport.incidentDescription.witnesses.push(details);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const removeExistingWitnessDetails = (details: WitnessDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDescription.witnesses.findIndex(
            (el: WitnessDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.staffReport.incidentDescription.witnesses.splice(index, 1);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const setExistingWitnessDetails = (details: WitnessDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        const index = form.staffReport.incidentDescription.witnesses.findIndex(
            (el: WitnessDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        switch (details.contactable) {
            case YesNoType.Yes:
                form.staffReport.incidentDescription.witnesses[index] = details;
                break;
            case YesNoType.No:
                form.staffReport.incidentDescription.witnesses[index] = {
                    ...details,
                    telephone: ""
                };
                break;
        }

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Manager Section
//Investing Manager actions
export const setInvestigatingManager = (details: InvestigatingManagerV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.managerReport.investigatingManager = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Severity actions
export const setSeverityDetails = (details: IncidentSeverityV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.managerReport.severity = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Duty of Candour actions
export const setDutyOfCandourDetails = (details: DutyOfCandourV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.managerReport.dutyOfCandour = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Manager Investigation Details actions
export const setManagerInvestigationDetails = (details: ManagerInvestigationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.managerReport.investigationDetails = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Closure Of Incident actions
export const setClosureOfIncidentDetails = (details: ClosureOfIncidentV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: IncidentReportFormV1 = JSON.parse(reportCopy.payload);

        payload.managerReport.closure = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Complaint form actions
export const setComplaintDetailsForComplaintForm = (details: ComplaintFormComplaintDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const updatedComplaintsSection: ComplaintFormV1 = {
            ...payload,
            complaintDetails: details
        };

        const newReport: Report = {
            ...reportCopy,
            calendarId: details.division,
            calendarName: details.divisionName,
            payload: JSON.stringify(updatedComplaintsSection)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const addFrontlineLocationDetailsForComplaintForm = (
    details: FrontlineLocationDetailsV1
) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        payload.complaintDetails.frontlineDetails.push(details);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const setFrontlineLocationDetailsForComplaintForm = (
    details: FrontlineLocationDetailsV1
) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const index = payload.complaintDetails.frontlineDetails.findIndex(
            (el: FrontlineLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        payload.complaintDetails.frontlineDetails[index] = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const removeFrontlineLocationDetailsForComplaintForm = (uid: string) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const payload: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const index = payload.complaintDetails.frontlineDetails.findIndex(
            (el: FrontlineLocationDetailsV1) => el.uid === uid
        );

        if (index < 0) return;

        payload.complaintDetails.frontlineDetails.splice(index, 1);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const addVenueLocationDetailsForComplaintForm = (calendarId: number) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        form.complaintDetails.venueLocationDetails.push({
            uid: nanoid(),
            calendarId,
            venueId: 0
        });

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const setVenueLocationDetailsForComplaintForm = (details: VenueLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const index = form.complaintDetails.venueLocationDetails.findIndex(
            (el: VenueLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.complaintDetails.venueLocationDetails[index] = details;

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const removeVenueLocationDetailsForComplaintsFrom = (details: VenueLocationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = Store.getState().report.data;
        if (!reportCopy) return;

        const form: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const index = form.complaintDetails.venueLocationDetails.findIndex(
            (el: VenueLocationDetailsV1) => el.uid === details.uid
        );

        if (index < 0) return;

        form.complaintDetails.venueLocationDetails.splice(index, 1);

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(form)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

export const setComplaintInvestigationDetails = (details: ComplaintFormInvestigationDetailsV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = deepCopy(Store.getState().report.data);
        if (!reportCopy) return;

        const form: ComplaintFormV1 = JSON.parse(reportCopy.payload);

        const updatedComplaintForm: ComplaintFormV1 = {
            ...form,
            investigationDetails: details
        };

        const newReport: Report = {
            ...reportCopy,
            payload: JSON.stringify(updatedComplaintForm)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

//Notes
export const addNoteToReport = (type: string, reportVersion: number) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = deepCopy(Store.getState().report.data);
        if (!reportCopy) return;

        const reportWithNewNote = addNewNoteToReport(reportCopy, reportVersion);

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: reportWithNewNote
        });
    };
};

export const setNoteToReport = (type: string, note: AdditionalNotes) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = deepCopy(Store.getState().report.data);
        if (!reportCopy) return;

        const reportWithUpdatedNote = setExisingNoteInReport(reportCopy, note);

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: reportWithUpdatedNote
        });
    };
};

export const removeNoteFromReport = (type: string, noteUid: string) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        const reportCopy = deepCopy(Store.getState().report.data);
        if (!reportCopy) return;

        const reportWithRemovedNote = removeExisingNoteFromReport(reportCopy, noteUid);

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: reportWithRemovedNote
        });
    };
};

/** Adds new note to a report */
function addNewNoteToReport(report: Report, reportVersion: number): Report {
    const note: AdditionalNotes = {uid: nanoid(), comments: "", versionAdded: reportVersion};
    const reportForm = getFormForIncidentType(report);
    reportForm.notes.push(note);

    return {
        ...report,
        payload: JSON.stringify(reportForm)
    };
}

/** Removes note from a report */
function setExisingNoteInReport(report: Report, note: AdditionalNotes) {
    const reportForm = getFormForIncidentType(report);
    const index = reportForm.notes.findIndex((el: AdditionalNotes) => el.uid === note.uid);

    if (index < 0) return report;
    reportForm.notes[index] = note;

    return {
        ...report,
        payload: JSON.stringify(reportForm)
    };
}

/** Removes note from a report */
function removeExisingNoteFromReport(report: Report, noteUid: string) {
    const reportForm = getFormForIncidentType(report);
    const index = reportForm.notes.findIndex((el: AdditionalNotes) => el.uid === noteUid);

    if (index < 0) return report;
    reportForm.notes.splice(index, 1);

    return {
        ...report,
        payload: JSON.stringify(reportForm)
    };
}

/** Gets a parsed version of the form */
function getFormForIncidentType(report: Report) {
    switch (report.type) {
        case IncidentType.Complaint:
        case IncidentType.EquipmentDefect:
        case IncidentType.Incident:
        case IncidentType.VehicleDefect:
            return JSON.parse(report.payload);
    }
}

/** Sets the vehicle defect details */
export const setStaffVehicleDefectDetails = (details: StaffVehicleDefectFormV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>, state: () => RootStore) => {
        const report: Report | null | undefined = state().report.data;
        if (!report) return;

        const reportPayload: VehicleDefectFormV1 = JSON.parse(report.payload);

        reportPayload.staffVehicleDefectForm = details;

        const newReport: Report = {
            ...report,
            calendarId: details.location.calendarId,
            calendarName: details.location.calendarName,
            payload: JSON.stringify(reportPayload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

/** Sets the vehicle defect details (ADMIN) */
export const setAdminVehicleDefectDetails = (details: AdminVehicleDefectFormV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>, state: () => RootStore) => {
        const report: Report | null | undefined = state().report.data;
        if (!report) return;

        const reportPayload: VehicleDefectFormV1 = JSON.parse(report.payload);

        reportPayload.adminVehicleDefectForm = details;

        report.payload = JSON.stringify(reportPayload);

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

/** Sets the equipment defect details */
export const setStaffEquipmentDefectDetails = (details: StaffEquipmentDefectFormV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>, state: () => RootStore) => {
        const report: Report | null | undefined = state().report.data;
        if (!report) return;

        const reportPayload: EquipmentDefectFormV1 = JSON.parse(report.payload);

        reportPayload.staffEquipmentDefectForm = details;

        const newReport: Report = {
            ...report,
            calendarId: details.location.calendarId,
            calendarName: details.location.calendarName,
            payload: JSON.stringify(reportPayload)
        };

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: newReport
        });
    };
};

/** Sets the equipment defect details (ADMIN) */
export const setAdminEquipmentDefectDetails = (details: AdminEquipmentDefectFormV1) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>, state: () => RootStore) => {
        const report: Report | null | undefined = state().report.data;
        if (!report) return;

        const reportPayload: EquipmentDefectFormV1 = JSON.parse(report.payload);

        reportPayload.adminEquipmentDefectForm = details;

        report.payload = JSON.stringify(reportPayload);

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

//Report actions

/** Creates new report */
export const newReport = (user: UserData) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        dispatch({
            type: REPORT_STORE_STATE.LOADING,
            loading: true,
            error: null
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(fetchAllVenues());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(fetchCalendarSummaryList());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(fetchAllStaff());

        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: createBlankReport(user)
        });
    };
};

//Locally save report (Set)
export const setReportFromType = (type: IncidentType, report: Report, user: UserData) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: setReportType(type, report, user)
        });
    };
};

//Locally save report (Set)
export const setReport = (report: Report) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

//Nullify report (Reset)
export const nullifyReportStore = () => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        dispatch({
            type: REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

//Delete Report
export const deleteReportFromService = (id: number) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                REPORT_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().deleteReport(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
//Get Report
export const getReportFromService = (id: number) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(fetchAllVenues());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(fetchCalendarSummaryList());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(fetchAllStaff());

            return await getDataFromServiceWithRedux(
                REPORT_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().getReport(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

//Save report
export const saveReportToService = (payload: string) => {
    return async (dispatch: Dispatch<ReportDispatchTypes>, getState: () => RootStore) => {
        const report = getState().report.data;
        if (!report) return;

        try {
            const success = await postDataToServiceWithRedux(
                REPORT_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().saveReport(processReportOutgoing(report, payload)),
                statusCodeCallback
            );
            if (!success) return false;

            if (report.id === 0) {
                const savedReport = getState().report.data;
                dispatch({
                    type: REPORT_STORE_STATE.LOADING,
                    error: null,
                    loading: true,
                    data: savedReport
                });
                const notificationSent: boolean = await sendOpenedReport(getState);

                if (notificationSent) {
                    dispatch({
                        type: REPORT_STORE_STATE.SUCCESS,
                        error: null,
                        loading: false,
                        data: savedReport
                    });
                    return true;
                }
                return false;
            }

            // If the report is marked as complete. We send a notification saying that the report has been closed
            if (report.state === ReportState.CompletedReview) {
                const savedReport = getState().report.data;
                dispatch({
                    type: REPORT_STORE_STATE.LOADING,
                    error: null,
                    loading: true,
                    data: savedReport
                });
                const notificationSent: boolean = await sendClosedReport(getState);

                if (notificationSent) {
                    dispatch({
                        type: REPORT_STORE_STATE.SUCCESS,
                        error: null,
                        loading: false,
                        data: savedReport
                    });
                    return true;
                }
                return false;
            }

            return true;
        } catch (e: any) {
            dispatch({
                type: REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

function processReportOutgoing(report: Report, payload: string): Report {
    return {
        ...report,
        modifyAccessLevels: [
            StaffAccessLevel.SystemAdministrator,
            StaffAccessLevel.DutyManager,
            StaffAccessLevel.Staff
        ],
        payload
    };
}

async function sendOpenedReport(getState: () => RootStore): Promise<boolean> {
    const savedReport = getState().report.data;
    if (!savedReport) {
        return false;
    }
    const noun = getNoun(savedReport);
    const verb = getVerb(savedReport);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await Store.dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sendNewReportNotification(savedReport.id.toString(), noun, verb)
    );
}

async function sendClosedReport(getState: () => RootStore): Promise<boolean> {
    const report = getState().report.data;
    if (!report) {
        return false;
    }
    const noun = splitStringByCapitalLetter(report.type).toLowerCase();
    const verb = splitStringByCapitalLetter(report.state).toLowerCase();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await Store.dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sendClosedReportNotification(report.id.toString(), noun, verb)
    );
}

function getNoun(report: Report): string {
    return splitStringByCapitalLetter(report.type).toLowerCase();
}

function getVerb(report: Report): string {
    return splitStringByCapitalLetter(report.state).toLowerCase();
}

function createBlankReport(user: UserData): Report {
    return {
        id: 0,
        state: ReportState.PendingReview,
        type: IncidentType.None,
        staffId: user.username,
        staffName: `${user.firstName} ${user.lastName}`,
        dateCreated: moment().unix(),
        dateModified: 0,
        modifyAccessLevels: [
            StaffAccessLevel.SystemAdministrator,
            StaffAccessLevel.DutyManager,
            StaffAccessLevel.Staff
        ],
        payload: ""
    };
}

function setReportType(type: IncidentType, report: Report, user: UserData): Report {
    return {
        ...report,
        type,
        payload: createReportWithReportType(type, user)
    };
}

function createReportWithReportType(type: IncidentType, user: UserData): string {
    switch (type) {
        case IncidentType.Incident:
            return JSON.stringify(createBlankIncidentReportFormV1(user));
        case IncidentType.Complaint:
            return JSON.stringify(createBlankComplaintFormV1());
        case IncidentType.EquipmentDefect:
            return JSON.stringify(createBlankEquipmentDefectFormV1());
        case IncidentType.VehicleDefect:
            return JSON.stringify(createBlankVehicleDefectFormV1());
        case IncidentType.None:
            return "";
    }
}
