import React from "react";
import {AdditionalNotes} from "../../../../api/reports/api";
import FormHeaderWithAction from "../../../Form/FormHeaderWithAction";
import {IconType} from "../../../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import {DebounceInput} from "react-debounce-input";
import {
    removeNoteFromReport,
    setNoteToReport
} from "../../../../store/reports/actions/ReportActions";
import FormRow from "../../../Form/FormRow";
import {useDispatch} from "react-redux";
import {useReport} from "../../../Hooks/useReport";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import ReportStateAmI from "../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../api/im";
import UploadDownloadActions from "../Components/Upload/UploadDownloadActions";

const AdditionalNoteItem = (props: AdditionalNoteItemProps) => {
    const {toggle, isShown} = useModal();
    const report = useReport();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Note"}
                icon={IconType.Bin}
                onClick={toggle}
                showIcon={props.reportVersion === props.note.versionAdded}
                tooltipText={"Remove Note"}
            />
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setNoteToReport(report.type, {
                                ...props.note,
                                comments: event.target.value
                            })
                        );
                    }}
                    disabled={false}
                    value={props.note.comments}
                    placeholder="Enter comments here..."
                />
            </FormRow>
            <FormRow rowName={"File"} columnDetailClassName={"pl-3 pr-3"}>
                {props.note.filename ? (
                    <p className="mb-2">{props.note.filename}</p>
                ) : (
                    <p className="mb-2">No document has been uploaded</p>
                )}
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <ReportStateAmI
                        currentReportState={report.state}
                        reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                    >
                        <UploadDownloadActions
                            fileName={props.note.filename}
                            onFileUploaded={(fileName) => {
                                dispatch(
                                    setNoteToReport(report.type, {
                                        ...props.note,
                                        filename: fileName
                                    })
                                );
                            }}
                        />
                    </ReportStateAmI>
                </AuthAmI>
            </FormRow>
            <Modal
                modalSize={"sm"}
                title={"Remove Note"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">Are you sure you want to remove this note?</p>
                    </div>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                dispatch(removeNoteFromReport(report.type, props.note.uid));
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default AdditionalNoteItem;

interface AdditionalNoteItemProps {
    reportVersion: number;
    note: AdditionalNotes;
}
