import React from "react";
import {ManagerInvestigationDetailsV1} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import DatePicker from "react-datepicker";
import moment from "moment";
import {setManagerInvestigationDetails} from "../../../../../../../../../store/reports/actions/ReportActions";
import DatePickerInputButton from "../../../../../../../../Button/DatePickerInputButton";
import FormRow from "../../../../../../../../Form/FormRow";
import {useDispatch} from "react-redux";
import {DebounceInput} from "react-debounce-input";
import {canUserEditManagerIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";

const InvestigationDetails = (props: ManagerInvestigationDetailsV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditManagerIncidentSection();
    return (
        <React.Fragment>
            <FormHeader headerName={"Investigation Details"} />
            <FormRow rowName={"Date Started"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateStarted ? moment(props.dateStarted * 1000).toDate() : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setManagerInvestigationDetails({
                                ...props,
                                dateStarted: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow
                rowName={"Outcome And Finding Of The Investigation"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setManagerInvestigationDetails({
                                ...props,
                                outcome: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.outcome}
                    placeholder="Enter outcome and findings here..."
                />
            </FormRow>
            <FormRow rowName={"Lessons Learned"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setManagerInvestigationDetails({
                                ...props,
                                lessonsLearned: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.lessonsLearned}
                    placeholder="Enter lessons learned here..."
                />
            </FormRow>
        </React.Fragment>
    );
};

export default InvestigationDetails;
