import React from "react";
import {DutyOfCandourV1, YesNoType} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import {getStaffMemberFromList} from "./InvestigatingManager";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../../../store/Store";
import {formatUnixToDDMMYYYY} from "../../../../../../../../../utils/momentUtils";

const DutyOfCandour = (props: DutyOfCandourV1) => {
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Duty of Candour`}
            />
            <PrintableFormRow rowName={"Duty of Candour Engaged?"} rowContent={props.engaged} />
            {props.engaged === YesNoType.No ? (
                <PrintableFormRow
                    rowName={"Reason For Not Engaging"}
                    rowContent={props.notEngagedAnswer}
                />
            ) : (
                <React.Fragment>
                    <PrintableFormRow
                        rowName={"Nominated Individual"}
                        rowContent={getStaffMemberFromList(staffList, props.nominatedIndividual)}
                    />
                    <PrintableFormRow
                        rowName={"Date of Initial Contact"}
                        rowContent={
                            <p>
                                {props.dateOfInitialContact
                                    ? formatUnixToDDMMYYYY(props.dateOfInitialContact)
                                    : "Date not added"}
                            </p>
                        }
                    />
                    <PrintableFormRow
                        rowName={"Date of Letter Sent"}
                        rowContent={
                            <p>
                                {props.dateOfLetterSent
                                    ? formatUnixToDDMMYYYY(props.dateOfLetterSent)
                                    : "Date not added"}
                            </p>
                        }
                    />
                    <PrintableFormRow
                        rowName={"Date of Final Letter and Report"}
                        rowContent={
                            <p>
                                {props.dateOfFinalLetterAndReport
                                    ? formatUnixToDDMMYYYY(props.dateOfFinalLetterAndReport)
                                    : "Date not added"}
                            </p>
                        }
                    />
                    <PrintableFormRow
                        rowName={"Duty of Candour file"}
                        rowContent={<p>{props.filename ? props.filename : "File not added"}</p>}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
export default DutyOfCandour;
