import React from "react";
import {FrontlineLocationDetailsV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";

const FrontlineLocationDetails = (props: FrontlineLocationDetailsProps) => {
    return (
        <React.Fragment>
            {props.frontlineDetails.length > 0 && (
                <React.Fragment>
                    <ReportHeader
                        padding={"0.75rem"}
                        marginTop={"15px"}
                        alignment={"left"}
                        reportHeaderText={`Location Details`}
                    />
                    {props.frontlineDetails.map((item: FrontlineLocationDetailsV1, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ReportHeader
                                    padding={"0.75rem"}
                                    marginTop={"0px"}
                                    alignment={"left"}
                                    reportHeaderText={`Location (Frontline)`}
                                />
                                <PrintableFormRow
                                    rowName={"Public Place"}
                                    rowContent={item.publicPlace}
                                />
                                <PrintableFormRow
                                    rowName={"Patient Property"}
                                    rowContent={item.patientsProperty}
                                />
                                <PrintableFormRow rowName={"Hospital"} rowContent={item.hospital} />
                                <PrintableFormRow
                                    rowName={"Ambulance"}
                                    rowContent={item.ambulance}
                                />
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            )}
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Frontline Details`}
            />
            <PrintableFormRow rowName={"CAD Number"} rowContent={props.cadNumber} />
            {props.nhsRefNumber && (
                <PrintableFormRow rowName={"NHS Ref No."} rowContent={props.nhsRefNumber} />
            )}
        </React.Fragment>
    );
};

export default FrontlineLocationDetails;

interface FrontlineLocationDetailsProps {
    cadNumber?: string;
    nhsRefNumber?: string;
    frontlineDetails: FrontlineLocationDetailsV1[];
}
