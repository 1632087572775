import React from "react";
import {
    ComplaintFormComplaintDetailsV1,
    FrontlineLocationDetailsV1
} from "../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../Form/FormRow";
import moment from "moment";
import {
    addFrontlineLocationDetailsForComplaintForm,
    addVenueLocationDetailsForComplaintForm,
    setComplaintDetailsForComplaintForm
} from "../../../../../../../../store/reports/actions/ReportActions";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";
import {useDispatch} from "react-redux";
import {StaffAccessLevel} from "../../../../../../../../api/staff";
import {DebounceInput} from "react-debounce-input";
import {CalendarSummary, CalendarTypeEnum} from "../../../../../../../../api/grs";
import FormHeaderWithAction from "../../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../../Icon/Icon";
import {nanoid} from "nanoid";
import FrontlineDetails from "./FrontlineDetails/FrontlineDetails";
import VenueDetails from "./VenueDetails/VenueDetails";
import {canUserEditComplaintFormPartA} from "../../../../../../../Hooks/useCanUserEditForms";
import PulseStaffDropdown from "../../../../../../../Dropdown/Components/PulseStaffDropdown";
import CalendarListDropdown from "../../../../../../../Dropdown/Components/CalendarListDropdown";

const ComplaintDetails = (props: ComplaintFormComplaintDetailsV1) => {
    const dispatch = useDispatch();

    const canEdit = canUserEditComplaintFormPartA();

    const onLocationDetailsAdded = (calendarType: CalendarTypeEnum | undefined) => {
        if (!calendarType) return;

        switch (calendarType) {
            case CalendarTypeEnum.Event:
                dispatch(addVenueLocationDetailsForComplaintForm(props.division));
                return;
            case CalendarTypeEnum.Frontline:
                const details: FrontlineLocationDetailsV1 = {
                    uid: nanoid(),
                    publicPlace: "",
                    patientsProperty: "",
                    hospital: "",
                    ambulance: ""
                };

                dispatch(addFrontlineLocationDetailsForComplaintForm(details));
                return;
        }
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Complaint Details"} />
                <FormRow rowName={"Reference Number"}>
                    <p className="mb-0">{props.referenceNumber}</p>
                </FormRow>
                <FormRow rowName={"Date Received"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={
                            props.dateReceived
                                ? moment(props.dateReceived * 1000).toDate()
                                : undefined
                        }
                        onChange={(date) => {
                            const newDate = date ? moment(date).unix() : undefined;
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    dateReceived: newDate
                                })
                            );
                        }}
                        dateFormat="do MMMM yyyy"
                        portalId="root-portal"
                        isClearable={canEdit}
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select modified date"
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Received by"} columnDetailClassName={"pl-0 pr-0"}>
                    <PulseStaffDropdown
                        searchable={false}
                        onChange={(item) => {
                            if (!item) return;

                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    receivedBy: item.staffId
                                })
                            );
                        }}
                        clearable={false}
                        username={props.receivedBy}
                        disabled={!canEdit}
                        accessLevelFilter={StaffAccessLevel.SystemAdministrator}
                    />
                </FormRow>
                <FormRow rowName={"Complaints Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    complaintsName: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.complaintsName}
                        placeholder="Enter complaints name here..."
                    />
                </FormRow>
                <FormRow rowName={"Organisation"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    organisation: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.organisation}
                        placeholder="Enter organisation here..."
                    />
                </FormRow>
                <FormRow rowName={"Staff Involved"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    staffInvolved: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.staffInvolved}
                        placeholder="Enter staff involved here..."
                    />
                </FormRow>
                <FormRow rowName={"Details of Complaint"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    detailsOfComplaint: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.detailsOfComplaint}
                        placeholder="Enter details of complaint here..."
                    />
                </FormRow>
                <FormRow rowName={"Division"} columnDetailClassName={"pl-0 pr-0"}>
                    <CalendarListDropdown
                        searchable={false}
                        onChange={(calendar) => {
                            if (!calendar) return;
                            const details = getComplaintDetailsForCalendarType(calendar, props);

                            dispatch(setComplaintDetailsForComplaintForm(details));
                        }}
                        clearable={false}
                        calendarId={props.division}
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Date of Incident"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={
                            props.dateOfIncident
                                ? moment(props.dateOfIncident * 1000).toDate()
                                : undefined
                        }
                        onChange={(date) => {
                            const newDate = date ? moment(date).unix() : undefined;
                            dispatch(
                                setComplaintDetailsForComplaintForm({
                                    ...props,
                                    dateOfIncident: newDate
                                })
                            );
                        }}
                        dateFormat="do MMMM yyyy"
                        portalId="root-portal"
                        isClearable={canEdit}
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select modified date"
                        disabled={!canEdit}
                    />
                </FormRow>
                {props.calendarType && (
                    <React.Fragment>
                        <div className="mt-3">
                            <FormHeaderWithAction
                                headerName={"Location Details"}
                                icon={IconType.Add}
                                onClick={() => onLocationDetailsAdded(props.calendarType)}
                                showIcon={canEdit}
                                tooltipText={"Add Location Details"}
                            />
                            {props.calendarType === CalendarTypeEnum.Frontline ? (
                                <FrontlineDetails {...props} />
                            ) : (
                                <VenueDetails {...props} />
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default ComplaintDetails;

function getComplaintDetailsForCalendarType(
    calendar: CalendarSummary,
    props: ComplaintFormComplaintDetailsV1
): ComplaintFormComplaintDetailsV1 {
    switch (calendar.calendarType) {
        case CalendarTypeEnum.Frontline:
            return {
                ...props,
                venueLocationDetails: [],
                cadNumber: undefined,
                calendarType: calendar.calendarType,
                divisionName: calendar.name,
                division: calendar.id
            };
        case CalendarTypeEnum.Event:
            return {
                ...props,
                frontlineDetails: [],
                cadNumber: undefined,
                calendarType: calendar.calendarType,
                divisionName: calendar.name,
                division: calendar.id
            };
    }
}
