import {StoreServiceData, StoreState} from "store-fetch-wrappers";
import {ReportCountResponse} from "../../../api/im";

export const REPORT_TYPE_COUNT_STORE_STATE: StoreState = {
    LOADING: "REPORT_TYPE_COUNT_LOADING",
    ERROR: "REPORT_TYPE_COUNT_ERROR",
    SUCCESS: "REPORT_TYPE_COUNT_SUCCESS"
};

interface ReportTypeCountLoading extends StoreServiceData<ReportCountResponse> {
    type: typeof REPORT_TYPE_COUNT_STORE_STATE.LOADING;
}
interface ReportTypeCountError extends StoreServiceData<ReportCountResponse> {
    type: typeof REPORT_TYPE_COUNT_STORE_STATE.ERROR;
}
interface ReportTypeCountSuccess extends StoreServiceData<ReportCountResponse> {
    type: typeof REPORT_TYPE_COUNT_STORE_STATE.SUCCESS;
}

export type ReportTypeCountDispatchTypes =
    | ReportTypeCountSuccess
    | ReportTypeCountError
    | ReportTypeCountLoading;
