//Getting enum as a generic. Accepts string/numeric/heterogeneous

import {DefectGrade, EquipmentList, EquipmentReplaceAnswer, YesNoType} from "../api/reports/api";

export type Enum<E> = Record<keyof E, number | string> & {[k: number]: string};

export function getDefectGradeFromString(value: string) {
    return DefectGrade[value as keyof typeof DefectGrade];
}

export function getYesNoAnswerFromString(value: string) {
    return YesNoType[value as keyof typeof YesNoType];
}

export function getEquipmentReplacedAnswerFromString(value: string) {
    return EquipmentReplaceAnswer[value as keyof typeof EquipmentReplaceAnswer];
}

export function getEquipmentFromString(value: string) {
    return EquipmentList[value as keyof typeof EquipmentList];
}
