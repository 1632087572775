import React from "react";
import PrintableTableHead from "./PrintableTableHead";
import PrintableTableRow from "./PrintableTableRow";
import PrintableTable from "./PrintableTable";
import PrintableTD from "./PrintableTD";

const PrintableFormRow = (props: PrintableFormRowProps) => {
    return (
        <PrintableTable width={"800px"}>
            <PrintableTableHead>
                <PrintableTableRow rowType={"bodyRow"}>
                    <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={"1000px"}>
                        <h4 style={{marginBottom: `0`, fontWeight: `bold`}}>{props.rowName}</h4>
                    </PrintableTD>
                </PrintableTableRow>
            </PrintableTableHead>
            <PrintableTableHead>
                <PrintableTableRow rowType={"bodyRow"}>
                    <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={"1000px"}>
                        {props.rowContent}
                    </PrintableTD>
                </PrintableTableRow>
            </PrintableTableHead>
        </PrintableTable>
    );
};

export default PrintableFormRow;

interface PrintableFormRowProps {
    rowName: string;
    rowContent: any;
}
