import React from "react";
import {ManagerInvestigationDetailsV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../../utils/momentUtils";

const InvestigationDetails = (props: ManagerInvestigationDetailsV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Investigation Details`}
            />
            <PrintableFormRow
                rowName={"Date Started"}
                rowContent={
                    <p>
                        {props.dateStarted
                            ? formatUnixToDDMMYYYY(props.dateStarted)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow
                rowName={"Outcome and Findings of the Investigation"}
                rowContent={<p>{props.outcome ? props.outcome : "Outcome not added"}</p>}
            />
            <PrintableFormRow
                rowName={"Lessons Learned"}
                rowContent={
                    <p>{props.lessonsLearned ? props.lessonsLearned : "Lessons Learned"}</p>
                }
            />
        </React.Fragment>
    );
};

export default InvestigationDetails;
