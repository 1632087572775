import React from "react";
import ReportHeader from "../../Components/ReportHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {EquipmentDefectFormV1, IncidentType} from "../../../../../../../api/reports/api";
import ReportFooter from "../../Components/ReportFooter";
import PrintableNotes from "../Notes/PrintableNotes";
import StaffEquipmentDefectDetails from "./PartA/StaffEquipmentDefectDetails";
import AdminEquipmentDefectForm from "./PartB/AdminEquipmentDefectForm";

const PrintEquipmentDefectReport = ({
    staffEquipmentDefectForm,
    adminEquipmentDefectForm,
    notes
}: EquipmentDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"2rem"}
                marginTop={"0px"}
                alignment={"right"}
                reportHeaderText={`Medicare EMS Group UK - ${getUiFriendlyText(
                    IncidentType.EquipmentDefect
                )}`}
            />
            <StaffEquipmentDefectDetails {...staffEquipmentDefectForm} />
            <AdminEquipmentDefectForm {...adminEquipmentDefectForm} />
            <PrintableNotes {...notes} />
            <ReportFooter />
        </React.Fragment>
    );
};

export default PrintEquipmentDefectReport;
