import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {UserData} from "../../../api/staff";
import {STAFF_STORE_STATE} from "../actions/StaffActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserData>(null);

const staffReducer = (
    state: StoreServiceData<UserData> = defaultState,
    action: ServiceActionTypes<UserData>
): StoreServiceData<UserData> =>
    createReducer(state, action, STAFF_STORE_STATE, () => showErrorToast(action.error));

export default staffReducer;
