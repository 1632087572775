import React, {useEffect, useState} from "react";
import {useReport} from "../../../Hooks/useReport";
import {AdditionalNotes} from "../../../../api/reports/api";
import {toArray} from "../../../../utils/sortingUtils";
import {nanoid} from "nanoid";
import {useDispatch} from "react-redux";
import {addNoteToReport} from "../../../../store/reports/actions/ReportActions";
import {useUserData} from "../../../Hooks/useUserData";
import FormHeaderWithAction from "../../../Form/FormHeaderWithAction";
import {IconType} from "../../../Icon/Icon";
import AdditionalNoteItem from "./AdditionalNoteItem";

const AdditionalNotesSection = (props: AdditionalNotesSectionProps) => {
    const [notes, setNotes] = useState<AdditionalNotes[]>(toArray(props.notes));
    const report = useReport();
    const dispatch = useDispatch();
    const noteTooltipId = nanoid();
    const user = useUserData();

    useEffect(() => {
        setNotes(toArray(props.notes));
    }, [props.notes]);

    const addNotes = () => {
        dispatch(addNoteToReport(report.type, props.reportVersion));
    };

    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Additional Notes"}
                icon={IconType.Add}
                onClick={addNotes}
                showIcon={true}
                tooltipText={"Add Additional Notes"}
            />
            {props.notes.length > 0 ? (
                <React.Fragment>
                    {props.notes.map((item) => {
                        return (
                            <AdditionalNoteItem
                                note={item}
                                reportVersion={props.reportVersion}
                                key={item.uid}
                            />
                        );
                    })}
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            No additional notes were added, click the plus button to add a note
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default AdditionalNotesSection;

interface AdditionalNotesSectionProps {
    reportVersion: number;
    notes: AdditionalNotes[];
}
