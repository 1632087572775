import {FILE_STORE_STATE, FileDispatchTypes} from "./FileActionTypes";
import ImApiModel from "../../apiModel/ImApiModel";
import {
    postDataToServiceWithData,
    deleteDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {ReportSaveDocRequest} from "../../../api/im";
import {Dispatch} from "redux";
import {RootStore} from "../../Store";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyFileStore = () => {
    return async (dispatch: Dispatch<FileDispatchTypes>) => {
        dispatch({
            type: FILE_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const saveFileToService = (request: ReportSaveDocRequest) => {
    return async (dispatch: Dispatch<FileDispatchTypes>) => {
        try {
            return await postDataToServiceWithData(
                FILE_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().saveReportDocument(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: FILE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const deleteFileOnService = (filename: string) => {
    return async (dispatch: Dispatch<FileDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                FILE_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().deleteReportDocument(filename),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: FILE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const updateFileOnService = (filename: string, request: ReportSaveDocRequest) => {
    return async (dispatch: Dispatch<FileDispatchTypes>, getState: () => RootStore) => {
        try {
            await deleteDataFromServiceWithRedux(
                FILE_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().deleteReportDocument(filename),
                statusCodeCallback
            );
            const success = await postDataToServiceWithRedux(
                FILE_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().saveReportDocument(request),
                statusCodeCallback
            );

            if (success) {
                const newFilename = getState().file.data;
                if (!newFilename) return;

                return newFilename;
            }
        } catch (e: any) {
            dispatch({
                type: FILE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
