import React from "react";
import {AdditionalNotes} from "../../../../../../../api/reports/api";
import {toArray} from "../../../../../../../utils/sortingUtils";
import PrintableFormRow from "../../Components/PrintableFormRow";
import ReportHeader from "../../Components/ReportHeader";

const PrintableNotes = (props: AdditionalNotes[]) => {
    return (
        <React.Fragment>
            {toArray(props).length > 0 && (
                <React.Fragment>
                    <ReportHeader
                        padding={"0.75rem"}
                        marginTop={"15px"}
                        alignment={"left"}
                        reportHeaderText={`Additional Notes`}
                    />
                    {toArray(props).map((item: AdditionalNotes, index) => {
                        return (
                            <React.Fragment key={index}>
                                <PrintableFormRow
                                    rowName={"Comments"}
                                    rowContent={
                                        <p>
                                            {item.comments ? item.comments : "Comments not added"}
                                        </p>
                                    }
                                />
                                <PrintableFormRow
                                    rowName={"File"}
                                    rowContent={
                                        <p>{item.filename ? item.filename : "file not added"}</p>
                                    }
                                />
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PrintableNotes;
