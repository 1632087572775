import React from "react";
import {IncidentReportFormV1, IncidentType} from "../../../../../../../api/reports/api";
import ReportHeader from "../../Components/ReportHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import PrintableNotes from "../Notes/PrintableNotes";
import ReportFooter from "../../Components/ReportFooter";
import ManagerIncidentForm from "./PartB/ManagerIncidentForm";
import StaffIncidentForm from "./PartA/StaffIncidentForm";

const PrintIncidentReport = ({staffReport, managerReport, notes}: IncidentReportFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"2rem"}
                marginTop={"0px"}
                alignment={"right"}
                reportHeaderText={`Medicare EMS Group UK - ${getUiFriendlyText(
                    IncidentType.Incident
                )}`}
            />
            <StaffIncidentForm {...staffReport} />
            <ManagerIncidentForm {...managerReport} />
            <PrintableNotes {...notes} />
            <ReportFooter />
        </React.Fragment>
    );
};

export default PrintIncidentReport;
