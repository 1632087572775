import {StoreServiceData} from "store-fetch-wrappers";
import {ReportSaveDocResponse} from "../../../api/im";
import {createStoreState} from "store-fetch-wrappers/dist";

export const FILE_STORE_STATE = createStoreState("file_store");

interface FileLoading extends StoreServiceData<ReportSaveDocResponse> {
    type: typeof FILE_STORE_STATE.LOADING;
}
interface FileError extends StoreServiceData<ReportSaveDocResponse> {
    type: typeof FILE_STORE_STATE.ERROR;
}
interface FileSuccess extends StoreServiceData<ReportSaveDocResponse> {
    type: typeof FILE_STORE_STATE.SUCCESS;
}

export type FileDispatchTypes = FileLoading | FileError | FileSuccess;
