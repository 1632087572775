import React from "react";
import {useDispatch} from "react-redux";
import FormHeader from "../../../../../../../Form/FormHeader";
import {
    AdminVehicleDefectFormV1,
    DefectGrade,
    YesNoType
} from "../../../../../../../../api/reports/api";
import FormRow from "../../../../../../../Form/FormRow";
import Dropdown from "../../../../../../../Dropdown/Dropdown";
import {setAdminVehicleDefectDetails} from "../../../../../../../../store/reports/actions/ReportActions";
import {
    getDefectGradeFromString,
    getYesNoAnswerFromString
} from "../../../../../../../../utils/enumUtils";
import {DebounceInput} from "react-debounce-input";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import AuthAmI from "../../../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../../../api/staff";
import ReportStateAmI from "../../../../../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../../../../../api/im";
import UploadDownloadActions from "../../../../Upload/UploadDownloadActions";
import {useReport} from "../../../../../../../Hooks/useReport";
import {canUserEditDefectFormPartB} from "../../../../../../../Hooks/useCanUserEditForms";

const AdminVehicleDefectDetails = (props: AdminVehicleDefectFormV1) => {
    const dispatch = useDispatch();
    const report = useReport();
    const canEdit = canUserEditDefectFormPartB();
    return (
        <React.Fragment>
            <FormHeader headerName={"Admin Section - Vehicle Defect Form"} />
            <FormRow rowName={"Defect Grade"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.defectGrade}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                defectGrade: getDefectGradeFromString(item.toString())
                            })
                        );
                    }}
                    options={Object.values(DefectGrade)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow
                rowName={"Vehicle taken out of commission?"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <Dropdown
                    initialValue={props.vehicleTakenOutOfCommission}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                vehicleTakenOutOfCommission: getYesNoAnswerFromString(
                                    item.toString()
                                ),
                                vehicleNotTakenOutOfCommissionReason: ""
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.vehicleTakenOutOfCommission === YesNoType.No && (
                <FormRow rowName={"Please record why"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        onChange={(event) => {
                            dispatch(
                                setAdminVehicleDefectDetails({
                                    ...props,
                                    vehicleNotTakenOutOfCommissionReason: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.vehicleNotTakenOutOfCommissionReason}
                        placeholder="Record why here..."
                    />
                </FormRow>
            )}
            <FormRow rowName={"Date booked for repair"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateBookedForRepair
                            ? moment(props.dateBookedForRepair * 1000).toDate()
                            : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                dateBookedForRepair: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Name of garage / provider"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                nameOfGarage: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.nameOfGarage}
                    placeholder="Garage/Provider details here..."
                />
            </FormRow>
            <FormRow
                rowName={"Estimated time for repair (if applicable)"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                estimatedTimeForRepair: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.estimatedTimeForRepair}
                    placeholder="Estimated time for repair (if applicable)..."
                />
            </FormRow>
            <FormRow rowName={"Date vehicle returned"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateVehicleReturned
                            ? moment(props.dateVehicleReturned * 1000).toDate()
                            : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                dateVehicleReturned: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                comments: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.comments}
                    placeholder="Enter comments here..."
                />
            </FormRow>
            <FormRow rowName={"Vehicle defect file"} columnDetailClassName={"pl-3 pr-3"}>
                {props.filename ? (
                    <p className="mb-2">{props.filename}</p>
                ) : (
                    <p className="mb-2">No document has been uploaded</p>
                )}
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <ReportStateAmI
                        currentReportState={report.state}
                        reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                    >
                        <UploadDownloadActions
                            fileName={props.filename}
                            onFileUploaded={(fileName) => {
                                dispatch(
                                    setAdminVehicleDefectDetails({
                                        ...props,
                                        filename: fileName
                                    })
                                );
                            }}
                        />
                    </ReportStateAmI>
                </AuthAmI>
            </FormRow>
            <FormRow rowName={"Date closed"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateClosed ? moment(props.dateClosed * 1000).toDate() : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setAdminVehicleDefectDetails({
                                ...props,
                                dateClosed: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default AdminVehicleDefectDetails;
