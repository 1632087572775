import {REPORT_LIST_STORE_STATE, ReportListDispatchTypes} from "./ReportListActionTypes";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import ImApiModel from "../../apiModel/ImApiModel";
import {Dispatch} from "redux";
import {ReportPageRequest} from "../../../api/im";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyReportListingStore = () => {
    return async (dispatch: Dispatch<ReportListDispatchTypes>) => {
        dispatch({
            type: REPORT_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const fetchReportListing = (request: ReportPageRequest) => {
    return async (dispatch: Dispatch<ReportListDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                REPORT_LIST_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().getReportsPage(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REPORT_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
