import {Dispatch} from "redux";
import {NOTIFICATIONS_STORE_STATE, NotificationsDispatchTypes} from "./NotificationsActionTypes";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import ImApiModel from "../../apiModel/ImApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Sends a notification anytime a new report is made. */
export const nullifyReportNotification = () => {
    return async (dispatch: Dispatch<NotificationsDispatchTypes>) => {
        dispatch({
            type: NOTIFICATIONS_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Sends a notification anytime a new report is made. */
export const sendNewReportNotification = (id: string, noun?: string, verb?: string) => {
    return async (dispatch: Dispatch<NotificationsDispatchTypes>) => {
        try {
            return postDataToServiceWithRedux(
                NOTIFICATIONS_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().notificationReportNew(id, noun, verb),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: NOTIFICATIONS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Sends a notification anytime a report is closed. */
export const sendClosedReportNotification = (id: string, noun?: string, verb?: string) => {
    return async (dispatch: Dispatch<NotificationsDispatchTypes>) => {
        try {
            return postDataToServiceWithRedux(
                NOTIFICATIONS_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().notificationReportClosed(id, noun, verb),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: NOTIFICATIONS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
