import React from "react";
import {DebounceInput} from "react-debounce-input";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {FrontlineLocationDetailsV1} from "../../../../../../../../../api/reports/api";
import FormHeaderWithAction from "../../../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../../../Icon/Icon";
import FormRow from "../../../../../../../../Form/FormRow";
import {
    removeFrontlineLocationDetailsForComplaintForm,
    setFrontlineLocationDetailsForComplaintForm
} from "../../../../../../../../../store/reports/actions/ReportActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../../Button/MCButton";
import {canUserEditComplaintFormPartA} from "../../../../../../../../Hooks/useCanUserEditForms";

const FrontlineDetailItem = (props: FrontlineLocationDetailsV1) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    const canEdit = canUserEditComplaintFormPartA();
    return (
        <React.Fragment>
            <FormHeaderWithAction
                showIcon={canEdit}
                icon={IconType.Bin}
                onClick={toggle}
                tooltipText={"Remove Location Details"}
                headerName={"Location (Frontline)"}
            />
            <FormRow rowName={"Public Place"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    inputType={"input"}
                    onChange={(event) =>
                        dispatch(
                            setFrontlineLocationDetailsForComplaintForm({
                                ...props,
                                publicPlace: event.target.value
                            })
                        )
                    }
                    disabled={!canEdit}
                    value={props.publicPlace}
                    placeholder="Enter public place here..."
                />
            </FormRow>
            <FormRow rowName={"Patient Property"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    inputType={"input"}
                    onChange={(event) =>
                        dispatch(
                            setFrontlineLocationDetailsForComplaintForm({
                                ...props,
                                patientsProperty: event.target.value
                            })
                        )
                    }
                    disabled={!canEdit}
                    value={props.patientsProperty}
                    placeholder="Enter patient property here..."
                />
            </FormRow>
            <FormRow rowName={"Hospital"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    inputType={"input"}
                    onChange={(event) =>
                        dispatch(
                            setFrontlineLocationDetailsForComplaintForm({
                                ...props,
                                hospital: event.target.value
                            })
                        )
                    }
                    disabled={!canEdit}
                    value={props.hospital}
                    placeholder="Enter hospital here..."
                />
            </FormRow>
            <FormRow rowName={"Ambulance"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    inputType={"input"}
                    onChange={(event) =>
                        dispatch(
                            setFrontlineLocationDetailsForComplaintForm({
                                ...props,
                                ambulance: event.target.value
                            })
                        )
                    }
                    disabled={!canEdit}
                    value={props.ambulance}
                    placeholder="Enter ambulance here..."
                />
            </FormRow>
            <Modal
                modalSize={"sm"}
                title={"Remove Location Details"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            Are you sure you want to remove these location details?
                        </p>
                    </div>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                dispatch(removeFrontlineLocationDetailsForComplaintForm(props.uid));
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default FrontlineDetailItem;
