import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {NOTIFICATIONS_STORE_STATE} from "../actions/NotificationsActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const notificationsReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, NOTIFICATIONS_STORE_STATE, () => showErrorToast(action.error));

export default notificationsReducer;
