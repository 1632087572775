import React from "react";
import {StaffVehicleDefectFormV1} from "../../../../../../../../api/reports/api";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";
import ReportHeader from "../../../Components/ReportHeader";

const StaffSectionVehicleDefectReport = (props: StaffVehicleDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Staff Section - Vehicle Defect Form`}
            />
            <PrintableFormRow
                rowName={"Calendar"}
                rowContent={<p>{props.location.calendarName}</p>}
            />
            <PrintableFormRow
                rowName={"Incident date"}
                rowContent={
                    <p>
                        {props.dateOfDefect
                            ? formatUnixToDDMMYYYY(props.dateOfDefect)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow
                rowName={"Vehicle registration"}
                rowContent={<p>{props.vehicleRegistration}</p>}
            />
            <PrintableFormRow
                rowName={"Defect details"}
                rowContent={<p>{props.detailsOfDefect}</p>}
            />
        </React.Fragment>
    );
};

export default StaffSectionVehicleDefectReport;
