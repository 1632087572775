import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {FILE_STORE_STATE} from "../actions/FileActionTypes";
import {ReportSaveDocResponse} from "../../../api/im";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<ReportSaveDocResponse>(null);

const fileReducer = (
    state: StoreServiceData<ReportSaveDocResponse> = defaultState,
    action: ServiceActionTypes<ReportSaveDocResponse>
): StoreServiceData<ReportSaveDocResponse> =>
    createReducer(state, action, FILE_STORE_STATE, () => showErrorToast(action.error));

export default fileReducer;
