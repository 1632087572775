import {useReport} from "./useReport";
import {useUserData} from "./useUserData";
import {ReportState, StaffAccessLevel} from "../../api/im";
import useMcConfig from "./useMcConfig";
import {getUserAccessLevel} from "../../utils/userDataUtils";

export function canUserEditIncidentForm() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;
    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return true;
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return report.state === ReportState.PendingReview;
    }
}

export function canUserEditStaffIncidentSection() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;

    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return report.staffId === user.username;
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return report.state === ReportState.PendingReview && report.staffId === user.username;
        default:
            return false;
    }
}

export function canUserEditManagerIncidentSection() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;

    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return report.state !== ReportState.CompletedReview;
        case StaffAccessLevel.DutyManager:
            return report.state === ReportState.PendingReview;
        case StaffAccessLevel.Staff:
            return false;
        default:
            return false;
    }
}

export function canUserEditComplaintFormPartA() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;

    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return report.staffId === user.username;
        case StaffAccessLevel.DutyManager:
            return report.state === ReportState.PendingReview;
        case StaffAccessLevel.Staff:
            return false;
        default:
            return false;
    }
}

export function canUserEditComplaintFormPartB() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;

    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return report.state !== ReportState.CompletedReview;
        case StaffAccessLevel.DutyManager:
            return false;
        case StaffAccessLevel.Staff:
            return false;
        default:
            return false;
    }
}

export function canUserEditComplaintForm() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    if (user.username.length === 0) return false;
    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return true;
        case StaffAccessLevel.DutyManager:
            return report.state === ReportState.PendingReview;
        case StaffAccessLevel.Staff:
            return false;
        default:
            return false;
    }
}

export function canUserEditDefectForm() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();

    if (user.username.length === 0) return false;
    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return report.staffId === user.username && report.state === ReportState.PendingReview;
        case StaffAccessLevel.SystemAdministrator:
            return true;
    }
}

export function canUserEditDefectFormPartA() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();

    if (user.username.length === 0) return false;

    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return report.staffId === user.username && report.state === ReportState.PendingReview;
        case StaffAccessLevel.SystemAdministrator:
            return report.staffId === user.username;
    }
}

export function canUserEditDefectFormPartB() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {config} = useMcConfig();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const user = useUserData();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const report = useReport();

    if (user.username.length === 0) return false;
    if (!config.auth) return false;

    const systemAccessLevel = getUserAccessLevel(user, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return report.staffId === user.username && report.state === ReportState.PendingReview;
        case StaffAccessLevel.SystemAdministrator:
            return report.state !== ReportState.CompletedReview;
    }
}
