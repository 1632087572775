import React from "react";
import {useDispatch} from "react-redux";
import {DebounceInput} from "react-debounce-input";
import FrontlineDetailItem from "./FrontlineDetailItem";
import {
    ComplaintFormComplaintDetailsV1,
    FrontlineLocationDetailsV1
} from "../../../../../../../../../api/reports/api";
import {setComplaintDetailsForComplaintForm} from "../../../../../../../../../store/reports/actions/ReportActions";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import {canUserEditComplaintFormPartA} from "../../../../../../../../Hooks/useCanUserEditForms";

const FrontlineDetails = (props: ComplaintFormComplaintDetailsV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditComplaintFormPartA();
    return (
        <React.Fragment>
            {props.frontlineDetails.length > 0 ? (
                <React.Fragment>
                    {props.frontlineDetails.map((item: FrontlineLocationDetailsV1, index) => {
                        return <FrontlineDetailItem {...item} key={index} />;
                    })}
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">No details were added</h5>
                    </div>
                </div>
            )}
            <FormHeader headerName={"Frontline Details"} />
            <FormRow rowName={"CAD Number"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    inputType={"input"}
                    onChange={(event) =>
                        dispatch(
                            setComplaintDetailsForComplaintForm({
                                ...props,
                                cadNumber: event.target.value
                            })
                        )
                    }
                    disabled={!canEdit}
                    value={props.cadNumber}
                    placeholder="Enter CAD number here..."
                />
            </FormRow>
        </React.Fragment>
    );
};

export default FrontlineDetails;
