import {FILE_DOWNLOAD_STORE_STATE, FileDownloadDispatchTypes} from "./FileDownloadActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import ImApiModel from "../../apiModel/ImApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyDownloadLinkStore = () => {
    return async (dispatch: Dispatch<FileDownloadDispatchTypes>) => {
        dispatch({
            type: FILE_DOWNLOAD_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getFileDownloadLink = (filename: string) => {
    return async (dispatch: Dispatch<FileDownloadDispatchTypes>) => {
        try {
            return await getDataFromServiceWithData(
                FILE_DOWNLOAD_STORE_STATE,
                dispatch,
                () => ImApiModel.getReportsApi().getReportDocumentUrl(filename),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: FILE_DOWNLOAD_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
