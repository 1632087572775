import {useSelector} from "react-redux";
import {Report, ReportState} from "../../api/im";
import {RootStore} from "../../store/Store";

export const useReport = () => {
    const report: Report = {
        id: 0,
        state: ReportState.UnderReview,
        type: "",
        staffId: "",
        staffName: "",
        dateCreated: 0,
        dateModified: 0,
        modifyAccessLevels: [],
        payload: ""
    };

    const reportFromStore = useSelector((state: RootStore) => state.report).data;

    if (reportFromStore) {
        return reportFromStore;
    }

    return report;
};
