import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";

const FormRowWithTooltip = ({
    children,
    rowName,
    rowClassName,
    formRowClassName,
    columnHeaderClassName,
    columnDetailClassName,
    icon,
    tooltipText
}: FormRowProps) => {
    return (
        <div className={`mc-form-row ${formRowClassName}`}>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnHeaderClassName}`}>
                    <h6 className="mb-0">
                        {rowName}
                        <Tooltip
                            tooltipText={tooltipText}
                            wrapperClassName={"ml-2 cursor-pointer"}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon rootElement={"Span"} icon={icon} size={"Small"} />
                        </Tooltip>
                    </h6>
                </div>
            </div>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnDetailClassName}`}>{children}</div>
            </div>
        </div>
    );
};

export default FormRowWithTooltip;

interface FormRowProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnHeaderClassName?: string;
    columnDetailClassName?: string;
    formRowClassName?: string;
    icon: IconType;
    tooltipText: string;
}
