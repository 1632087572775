import React from "react";
import FormHeader from "../../../../../../../Form/FormHeader";
import {
    EquipmentList,
    GrsLocation,
    StaffEquipmentDefectFormV1,
    YesNoType
} from "../../../../../../../../api/reports/api";
import {setStaffEquipmentDefectDetails} from "../../../../../../../../store/reports/actions/ReportActions";
import FormRow from "../../../../../../../Form/FormRow";
import {useDispatch} from "react-redux";
import {canUserEditDefectFormPartA} from "../../../../../../../Hooks/useCanUserEditForms";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import Dropdown from "../../../../../../../Dropdown/Dropdown";
import {
    getEquipmentFromString,
    getYesNoAnswerFromString
} from "../../../../../../../../utils/enumUtils";
import {DebounceInput} from "react-debounce-input";
import CalendarListDropdown from "../../../../../../../Dropdown/Components/CalendarListDropdown";

const StaffEquipmentDefectDetails = (props: StaffEquipmentDefectFormV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditDefectFormPartA();
    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Staff Section - Equipment Defect Form"} />
                <FormRow rowName={"Calendar"} columnDetailClassName={"pl-0 pr-0"}>
                    <CalendarListDropdown
                        searchable={false}
                        onChange={(calendar) => {
                            if (!calendar) return;
                            const location: GrsLocation = {
                                calendarId: calendar.id,
                                calendarType: calendar.calendarType,
                                calendarName: calendar.name
                            };
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    location
                                })
                            );
                        }}
                        clearable={false}
                        calendarId={props.location.calendarId}
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Incident Date"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={
                            props.dateOfDefect
                                ? moment(props.dateOfDefect * 1000).toDate()
                                : undefined
                        }
                        onChange={(date) => {
                            const newDate = date ? moment(date).unix() : undefined;
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    dateOfDefect: newDate
                                })
                            );
                        }}
                        dateFormat="do MMMM yyyy"
                        portalId="root-portal"
                        isClearable={canEdit}
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select modified date"
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Equipment"} columnDetailClassName={"pl-0 pr-0"}>
                    <Dropdown
                        initialValue={props.equipment}
                        changeOption={(item) => {
                            if (!item) return;
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    equipment: getEquipmentFromString(item.toString()),
                                    otherEquipment: ""
                                })
                            );
                        }}
                        options={Object.values(EquipmentList)}
                        disabled={!canEdit}
                    />
                </FormRow>
                {props.equipment === EquipmentList.Other && (
                    <FormRow rowName={"Please specify other"} columnDetailClassName={"pl-0 pr-0"}>
                        <DebounceInput
                            debounceTimeout={300}
                            className={"input-fields"}
                            inputType={"input"}
                            onChange={(event) =>
                                dispatch(
                                    setStaffEquipmentDefectDetails({
                                        ...props,
                                        otherEquipment: event.target.value
                                    })
                                )
                            }
                            disabled={!canEdit}
                            value={props.otherEquipment}
                            placeholder="Enter other equipment here..."
                        />
                    </FormRow>
                )}
                <FormRow rowName={"Serial Number"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    serialNumber: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.serialNumber}
                        placeholder="Enter serial number here..."
                    />
                </FormRow>
                <FormRow rowName={"Location of equipment"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    locationOfEquipment: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.locationOfEquipment}
                        placeholder="Enter location here..."
                    />
                </FormRow>
                <FormRow rowName={"Defect details"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    detailsOfDefect: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.detailsOfDefect}
                        placeholder="Enter details here..."
                    />
                </FormRow>
                <FormRow
                    rowName={"Equipment taken out of commission and correctly tagged?"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <Dropdown
                        initialValue={props.equipmentCorrectlyTaggedAndDealtWith}
                        changeOption={(item) => {
                            if (!item) return;
                            dispatch(
                                setStaffEquipmentDefectDetails({
                                    ...props,
                                    equipmentCorrectlyTaggedAndDealtWith: getYesNoAnswerFromString(
                                        item.toString()
                                    )
                                })
                            );
                        }}
                        options={Object.values(YesNoType)}
                        disabled={!canEdit}
                    />
                </FormRow>
                {props.equipmentCorrectlyTaggedAndDealtWith === YesNoType.No && (
                    <FormRow
                        rowName={"If no, please state why."}
                        columnDetailClassName={"pl-0 pr-0"}
                    >
                        <DebounceInput
                            debounceTimeout={300}
                            className={"input-fields text-area-inputs"}
                            element="textarea"
                            inputType={"input"}
                            onChange={(event) =>
                                dispatch(
                                    setStaffEquipmentDefectDetails({
                                        ...props,
                                        detailsOfNotBeingTakenOutCorrectly: event.target.value
                                    })
                                )
                            }
                            disabled={!canEdit}
                            value={props.detailsOfNotBeingTakenOutCorrectly}
                            placeholder="Enter details here..."
                        />
                    </FormRow>
                )}
            </div>
        </React.Fragment>
    );
};
export default StaffEquipmentDefectDetails;
