import React from "react";
import {
    CurrentIncidentStatusType,
    InvestigatingManagerV1
} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import {StaffAccessLevel} from "../../../../../../../../../api/staff";
import {useDispatch} from "react-redux";
import {setInvestigatingManager} from "../../../../../../../../../store/reports/actions/ReportActions";
import Dropdown from "../../../../../../../../Dropdown/Dropdown";
import {canUserEditManagerIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";
import PulseStaffDropdown from "../../../../../../../../Dropdown/Components/PulseStaffDropdown";

const InvestigatingManager = (props: InvestigatingManagerV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditManagerIncidentSection();
    return (
        <React.Fragment>
            <FormHeader headerName={"Investigating Manager"} />
            <FormRow rowName={"Name"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseStaffDropdown
                    searchable={false}
                    onChange={(item) => {
                        if (!item) return;
                        const updatedSection: InvestigatingManagerV1 = {
                            ...props,
                            username: item.staffId
                        };

                        dispatch(setInvestigatingManager(updatedSection));
                    }}
                    clearable={false}
                    username={props.username}
                    disabled={!canEdit}
                    accessLevelFilter={StaffAccessLevel.SystemAdministrator}
                />
            </FormRow>
            <FormRow rowName={"Current Incident Status"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.currentIncidentStatus}
                    changeOption={(item) => {
                        if (!item) return;
                        const updatedSection: InvestigatingManagerV1 = {
                            ...props,
                            currentIncidentStatus: item.toString()
                        };

                        dispatch(setInvestigatingManager(updatedSection));
                    }}
                    options={Object.values(CurrentIncidentStatusType)}
                    disabled={!canEdit}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default InvestigatingManager;
