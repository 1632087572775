import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {Report} from "../../../api/im";
import {REPORT_STORE_STATE} from "../actions/ReportActionTypes";
import {IncidentReportFormV1, IncidentType} from "../../../api/reports/api";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Report>(null);

const reportReducer = (
    state: StoreServiceData<Report> = defaultState,
    action: ServiceActionTypes<Report>
): StoreServiceData<Report> =>
    createReducer(
        {...state, data: processReportIncoming(state.data)},
        action,
        REPORT_STORE_STATE,
        () => showErrorToast(action.error)
    );

export default reportReducer;

function processReportIncoming(report: Report | undefined | null) {
    if (!report) return null;

    let newReport = {
        ...report
    };

    switch (report.type) {
        case IncidentType.Incident:
            const incidentForm: IncidentReportFormV1 = JSON.parse(report.payload);
            if (!incidentForm.version) {
                incidentForm.version = 0;
            }

            if (!incidentForm.notes) {
                incidentForm.notes = [];
            }
            newReport = {
                ...newReport,
                payload: JSON.stringify(incidentForm)
            };
            break;
        case IncidentType.Complaint:
            const complaintForm: IncidentReportFormV1 = JSON.parse(report.payload);
            if (!complaintForm.version) {
                complaintForm.version = 0;
            }

            if (!complaintForm.notes) {
                complaintForm.notes = [];
            }

            newReport = {
                ...newReport,
                payload: JSON.stringify(complaintForm)
            };
            break;
    }

    return newReport;
}
