import React, {useEffect} from "react";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useDispatch} from "react-redux";
import {routeNames} from "../../../Navigation/routeNames";

const NotFound = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.reportList.name,
                path: routeNames.reportList.path
            }
        ];
        //Set Nav Items
        dispatch(setNavigationItems(navItems));
    }, []);

    return (
        <div className="page background-stars">
            <h3 className="text-center title pt-5 header-font">Error 404 - Not Found</h3>
            <div className="mt-5 text-center">
                <p className="body-font">Could not find the resource that was requested.</p>
            </div>
        </div>
    );
};

export default NotFound;
