import React from "react";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {VenueLocationDetailsV1} from "../../../../../../../../../api/reports/api";
import FormHeaderWithAction from "../../../../../../../../Form/FormHeaderWithAction";
import FormRow from "../../../../../../../../Form/FormRow";
import VenueDropdown from "../../../../../../../../Dropdown/VenueDropdown";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../../Button/MCButton";
import {
    removeVenueLocationDetailsForComplaintsFrom,
    setVenueLocationDetailsForComplaintForm
} from "../../../../../../../../../store/reports/actions/ReportActions";
import {IconType} from "../../../../../../../../Icon/Icon";
import {canUserEditComplaintFormPartA} from "../../../../../../../../Hooks/useCanUserEditForms";

const VenueDetailItem = (props: VenueLocationDetailsV1) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    const canEdit = canUserEditComplaintFormPartA();
    return (
        <React.Fragment>
            <FormHeaderWithAction
                showIcon={canEdit}
                icon={IconType.Bin}
                onClick={toggle}
                tooltipText={"Remove Location Details"}
                headerName={"Location"}
            />
            <FormRow rowName={"Incident Location"} columnDetailClassName={"pl-0 pr-0"}>
                <VenueDropdown
                    searchable={false}
                    changeOption={(item) => {
                        if (!item) return;

                        const details: VenueLocationDetailsV1 = {
                            ...props,
                            venueId: item.id,
                            venueName: item.name,
                            address: item.address,
                            postcode: item.postcode
                        };
                        dispatch(setVenueLocationDetailsForComplaintForm(details));
                    }}
                    calendarIdForVenue={props.calendarId}
                    clearable={false}
                    id={props.venueId}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.address && (
                <FormRow rowName={"Address"}>
                    <p className="mb-0 fadeIn">{props.address}</p>
                </FormRow>
            )}
            {props.postcode && (
                <FormRow rowName={"Postcode"}>
                    <p className="mb-0 fadeIn">{props.postcode}</p>
                </FormRow>
            )}
            <Modal
                modalSize={"sm"}
                title={"Remove Location Details"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            Are you sure you want to remove these location details?
                        </p>
                    </div>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                dispatch(removeVenueLocationDetailsForComplaintsFrom(props));
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default VenueDetailItem;
