import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const NOTIFICATIONS_STORE_STATE = createStoreState("notifications_store");

interface NotificationsLoading extends StoreServiceData<string> {
    type: typeof NOTIFICATIONS_STORE_STATE.LOADING;
}

interface NotificationsError extends StoreServiceData<string> {
    type: typeof NOTIFICATIONS_STORE_STATE.ERROR;
}

interface NotificationsSuccess extends StoreServiceData<string> {
    type: typeof NOTIFICATIONS_STORE_STATE.SUCCESS;
}

export type NotificationsDispatchTypes =
    | NotificationsError
    | NotificationsLoading
    | NotificationsSuccess;
