import React from "react";
import PrintableTable from "./PrintableTable";
import PrintableTableHead from "./PrintableTableHead";
import PrintableTableRow from "./PrintableTableRow";
import PrintableTH from "./PrintableTH";

const ReportHeader = (props: ReportHeaderProps) => {
    return (
        <React.Fragment>
            <PrintableTable width={"800px"} marginTop={props.marginTop}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH
                            alignment={props.alignment}
                            padding={props.padding}
                            maxWidth={"1000px"}
                        >
                            <h4 style={{marginBottom: `0`}}>{props.reportHeaderText}</h4>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
        </React.Fragment>
    );
};

export default ReportHeader;

interface ReportHeaderProps {
    reportHeaderText: string;
    alignment: "left" | "center" | "right" | "justify" | "char" | undefined;
    marginTop: string;
    padding: string;
}
