import React from "react";
import {StaffIncidentFormV1} from "../../../../../../../../api/reports/api";
import ReportHeader from "../../../Components/ReportHeader";
import IncidentDetails from "./Sections/IncidentDetails";
import ReporterDetails from "./Sections/ReporterDetails";
import IncidentDescription from "./Sections/IncidentDescription";

const StaffIncidentForm = (props: StaffIncidentFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Staff Incident Form`}
            />
            <IncidentDetails {...props.incidentDetails} />
            <ReporterDetails {...props.reporterDetails} />
            <IncidentDescription {...props.incidentDescription} />
        </React.Fragment>
    );
};

export default StaffIncidentForm;
