import React from "react";
import {ComplaintFormV1, IncidentType} from "../../../../../../../api/reports/api";
import ReportHeader from "../../Components/ReportHeader";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import PrintableNotes from "../Notes/PrintableNotes";
import ReportFooter from "../../Components/ReportFooter";
import ComplaintDetails from "./PartA/ComplaintDetails";
import InvestigationDetails from "./PartB/InvestigationDetails";

const PrintComplaintReport = ({complaintDetails, investigationDetails, notes}: ComplaintFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"2rem"}
                marginTop={"0px"}
                alignment={"right"}
                reportHeaderText={`Medicare EMS Group UK - ${getUiFriendlyText(
                    IncidentType.Complaint
                )}`}
            />
            <ComplaintDetails {...complaintDetails} />
            <InvestigationDetails {...investigationDetails} />
            <PrintableNotes {...notes} />
            <ReportFooter />
        </React.Fragment>
    );
};

export default PrintComplaintReport;
