import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {REPORT_TYPE_COUNT_STORE_STATE} from "../actions/ReportTypeCountActionTypes";
import {ReportCountResponse} from "../../../api/im";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<ReportCountResponse>(null);

const reportTypeCountReducer = (
    state: StoreServiceData<ReportCountResponse> = defaultState,
    action: ServiceActionTypes<ReportCountResponse>
): StoreServiceData<ReportCountResponse> =>
    createReducer(state, action, REPORT_TYPE_COUNT_STORE_STATE, () => showErrorToast(action.error));

export default reportTypeCountReducer;
