import React from "react";
import {
    IncidentCategoryType,
    IncidentDescriptionType,
    IncidentDescriptionV1
} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import Dropdown from "../../../../../../../../Dropdown/Dropdown";
import {useDispatch} from "react-redux";
import {setIncidentDescription} from "../../../../../../../../../store/reports/actions/ReportActions";
import FormRowWithTooltip from "../../../../../../../../Form/FormRowWithTooltip";
import {IconType} from "../../../../../../../../Icon/Icon";
import {DebounceInput} from "react-debounce-input";
import {canUserEditStaffIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";
import WitnessDetails from "./Witness/WitnessDetails";

const IncidentDescription = (props: IncidentDescriptionV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditStaffIncidentSection();

    return (
        <React.Fragment>
            <FormHeader headerName={"Incident Description"} />
            <FormRow rowName={"Type"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.type}
                    changeOption={(type) => {
                        if (!type) return;
                        const details: IncidentDescriptionV1 = {
                            ...props,
                            type: type.toString()
                        };
                        dispatch(setIncidentDescription(details));
                    }}
                    options={Object.values(IncidentDescriptionType)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Category"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.category}
                    changeOption={(category) => {
                        if (!category) return;
                        const details: IncidentDescriptionV1 = {
                            ...props,
                            category: category.toString()
                        };
                        dispatch(setIncidentDescription(details));
                    }}
                    options={Object.values(IncidentCategoryType)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRowWithTooltip
                rowName={"Description"}
                columnDetailClassName={"pl-0 pr-0"}
                icon={IconType.Inquisitor}
                tooltipText={getDescriptionTooltipText()}
            >
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        const details: IncidentDescriptionV1 = {
                            ...props,
                            description: event.target.value
                        };
                        dispatch(setIncidentDescription(details));
                    }}
                    disabled={!canEdit}
                    value={props.description}
                    placeholder="Enter description here..."
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                rowName={"Action Taken"}
                columnDetailClassName={"pl-0 pr-0"}
                icon={IconType.Inquisitor}
                tooltipText={getActionTakenTooltipText()}
            >
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        const details: IncidentDescriptionV1 = {
                            ...props,
                            actionTaken: event.target.value
                        };
                        dispatch(setIncidentDescription(details));
                    }}
                    disabled={!canEdit}
                    value={props.actionTaken}
                    placeholder="Enter action taken here..."
                />
            </FormRowWithTooltip>
            <WitnessDetails {...props} />
        </React.Fragment>
    );
};

export default IncidentDescription;

function getDescriptionTooltipText(): string {
    return "Please enter facts only and avoid using personal opinions and views. Please do not include identifiable information in the description. Refer to callsigns, vehicles or job titles. Do not include patient identifiable information.";
}

function getActionTakenTooltipText(): string {
    return "Please include any actions you have taken in relation to the incident. This could include apologising to a patient or client, verbally reporting the incident to a manager or on call manager, removing faulty equipment etc.";
}
