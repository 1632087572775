import React from "react";
import {ClosureOfIncidentV1, YesNoType} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../../utils/momentUtils";

const ClosureOfIncident = (props: ClosureOfIncidentV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Closure of Incident`}
            />
            <PrintableFormRow
                rowName={"Date Closed"}
                rowContent={
                    <p>
                        {props.dateClosed
                            ? formatUnixToDDMMYYYY(props.dateClosed)
                            : "Date not added"}
                    </p>
                }
            />
            <PrintableFormRow
                rowName={"Feedback Provided"}
                rowContent={<p>{props.feedbackProvided}</p>}
            />
            <PrintableFormRow
                rowName={"External Reporting Required?"}
                rowContent={<p>{props.externalReportRequired}</p>}
            />
            {props.externalReportRequired === YesNoType.Yes && (
                <React.Fragment>
                    <PrintableFormRow
                        rowName={"Reported To?"}
                        rowContent={<p>{props.reportedTo}</p>}
                    />
                    <PrintableFormRow
                        rowName={"Date Reported"}
                        rowContent={
                            <p>
                                {props.dateReported
                                    ? formatUnixToDDMMYYYY(props.dateReported)
                                    : "Date not added"}
                            </p>
                        }
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ClosureOfIncident;
