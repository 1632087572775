import React from "react";
import {AdminEquipmentDefectFormV1, YesNoType} from "../../../../../../../../api/reports/api";
import ReportHeader from "../../../Components/ReportHeader";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import {getUiFriendlyText} from "../../../../../../../../utils/textUtils";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";

const AdminEquipmentDefectForm = (props: AdminEquipmentDefectFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Admin Section - Equipment Defect Form`}
            />
            <PrintableFormRow rowName={"Defect Grade"} rowContent={<p>{props.defectGrade}</p>} />
            <PrintableFormRow
                rowName={"Equipment taken out of commission?"}
                rowContent={<p>{props.equipmentTakenOutOfCommission}</p>}
            />
            {props.equipmentTakenOutOfCommission === YesNoType.Yes ? (
                <PrintableFormRow
                    rowName={"Has equipment been correctly tagged?"}
                    rowContent={<p>{props.hasEquipmentBeenCorrectlyTagged}</p>}
                />
            ) : (
                <PrintableFormRow
                    rowName={"Please record why"}
                    rowContent={<p>{props.equipmentNotTakenOutOfCommission}</p>}
                />
            )}
            <PrintableFormRow
                rowName={"Equipment repairable?"}
                rowContent={<p>{props.equipmentRepairable}</p>}
            />
            {props.equipmentRepairable === YesNoType.Yes ? (
                <PrintableFormRow
                    rowName={"Please record actions taken"}
                    rowContent={<p>{props.positiveActionTaken}</p>}
                />
            ) : (
                <PrintableFormRow
                    rowName={"Please record actions taken"}
                    rowContent={<p>{props.negativeActionTaken}</p>}
                />
            )}
            <PrintableFormRow
                rowName={"Equipment replaced for operational use?"}
                rowContent={<p>{getUiFriendlyText(props.equipmentReplacedForOperationalUse)}</p>}
            />
            <PrintableFormRow
                rowName={"Comments"}
                rowContent={<p>{props.comments ? props.comments : "Comments not added"}</p>}
            />
            <PrintableFormRow
                rowName={"Equipment defect file"}
                rowContent={<p>{props.filename ? props.filename : "File not added"}</p>}
            />
            <PrintableFormRow
                rowName={"Date closed"}
                rowContent={
                    <p>
                        {props.dateClosed
                            ? formatUnixToDDMMYYYY(props.dateClosed)
                            : "Date not added"}
                    </p>
                }
            />
        </React.Fragment>
    );
};

export default AdminEquipmentDefectForm;
