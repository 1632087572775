import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import fileReducer from "./file/reducer/FileReducer";
import fileDownloadReducer from "./fileDownload/reducer/FileDownloadReducer";
import reportReducer from "./reports/reducer/ReportReducer";
import venueListReducer from "./venueList/reducer/VenueListReducer";
import calendarSummaryListReducer from "./calendarSummaryList/reducer/CalendarSummaryListReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import reportListReducer from "./reportList/reducer/ReportListReducer";
import reportTypeCountReducer from "./reportTypeCount/reducer/ReportTypeCountReducer";
import staffReducer from "./staffStore/reducer/StaffReducer";
import calendarReducer from "./calendar/reducer/CalendarReducer";
import notificationsReducer from "./notifications/reducer/NotificationsReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    report: reportReducer,
    venueList: venueListReducer,
    calendarSummaryList: calendarSummaryListReducer,
    staffList: staffListReducer,
    reportList: reportListReducer,
    reportTypeCount: reportTypeCountReducer,
    file: fileReducer,
    fileDownload: fileDownloadReducer,
    staff: staffReducer,
    calendar: calendarReducer,
    notifications: notificationsReducer
});

export default RootReducer;
