import React, {useEffect, useState} from "react";
import {DDProps} from "./Helpers/dropdownUtils";
import {getUiFriendlyText} from "../../utils/textUtils";
import Select, {SingleValue} from "react-select";

const Dropdown = ({clearable, options, initialValue, disabled, changeOption}: DropdownProps) => {
    const dropdownOptions = generateOptions(options);
    const [selectedOption, setSelectedOption] = useState<DDProps>();

    useEffect(() => {
        const index = dropdownOptions.findIndex((item) => item.value === initialValue);

        setSelectedOption(index > -1 ? dropdownOptions[index] : undefined);
    }, [initialValue]);

    /** Fired when a new option is selected */
    const handleOptionChange = (option: SingleValue<DDProps>) => {
        if (!option) {
            changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        setSelectedOption(option);
        changeOption(option.value);
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={dropdownOptions}
                onChange={handleOptionChange}
                isSearchable={false}
                value={selectedOption}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={disabled}
                isClearable={clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default Dropdown;

interface DropdownProps {
    initialValue: string | number | null;
    changeOption: (value: string | number | undefined) => void;
    options: string[];
    disabled?: boolean;
    clearable?: boolean;
}

function generateOptions(options: string[]): DDProps[] {
    return options.map((item) => {
        return {
            label: getUiFriendlyText(item),
            value: item
        };
    });
}
