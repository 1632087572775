import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {REPORT_LIST_STORE_STATE} from "../actions/ReportListActionTypes";
import {ReportPageResponse, ReportPageSortField, SortOrder} from "../../../api/im";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<ReportPageResponse>(generateBlankReportListResponse());

const reportListReducer = (
    state: StoreServiceData<ReportPageResponse> = defaultState,
    action: ServiceActionTypes<ReportPageResponse>
): StoreServiceData<ReportPageResponse> =>
    createReducer(state, action, REPORT_LIST_STORE_STATE, () => showErrorToast(action.error));

export default reportListReducer;

function generateBlankReportListResponse(): ReportPageResponse {
    return {
        numPerPage: 0,
        pageNum: 0,
        reports: [],
        sortField: ReportPageSortField.CreatedBy,
        sortOrder: SortOrder.Descending,
        totalResults: 0
    };
}
