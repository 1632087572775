import React from "react";
import {Report} from "../../../../api/im";
import FormHeader from "../../../Form/FormHeader";
import FormActionContainer from "../../../Form/FormActionContainer";
import {IncidentType} from "../../../../api/reports/api";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {useDispatch} from "react-redux";
import {setReportFromType} from "../../../../store/reports/actions/ReportActions";
import {useUserData} from "../../../Hooks/useUserData";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import {Modal, useModal} from "pulse-modal";

const ReportTypeButtons = (props: Report) => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {toggle, isShown} = useModal();

    const renderOptionButtons = (key: string, report: Report) => {
        switch (key) {
            case IncidentType.Complaint:
                return (
                    <AuthAmI
                        accessLevels={[
                            StaffAccessLevel.DutyManager,
                            StaffAccessLevel.SystemAdministrator
                        ]}
                    >
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={getUiFriendlyText(key)}
                                className="w-100"
                                onClick={() => {
                                    // Don't want to reset the form if we reselect
                                    if (key === report.type) return;
                                    dispatch(
                                        setReportFromType(
                                            getIncidentTypesFromString(key),
                                            props,
                                            user
                                        )
                                    );
                                }}
                                colour={
                                    key === report.type
                                        ? ButtonColourOptions.Yellow
                                        : ButtonColourOptions.DarkBlue
                                }
                                disabled={props.id > 0}
                            />
                        </div>
                    </AuthAmI>
                );
            case IncidentType.Incident:
                return (
                    <div className="col-6 report-button">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={getUiFriendlyText(key)}
                            className="w-100"
                            onClick={() => {
                                // Don't want to reset the form if we reselect
                                if (key === report.type) return;
                                dispatch(
                                    setReportFromType(getIncidentTypesFromString(key), props, user)
                                );
                            }}
                            colour={
                                key === report.type
                                    ? ButtonColourOptions.Yellow
                                    : ButtonColourOptions.DarkBlue
                            }
                            disabled={props.id > 0}
                        />
                    </div>
                );
            case IncidentType.VehicleDefect:
            case IncidentType.EquipmentDefect:
                return (
                    <React.Fragment>
                        <div className="col-6 report-button">
                            <MCButton
                                size={ButtonSize.ExtraLarge}
                                innerValue={getUiFriendlyText(key)}
                                className="w-100"
                                onClick={() => {
                                    toggle();
                                    // Don't want to reset the form if we reselect
                                    if (key === report.type) return;
                                    dispatch(
                                        setReportFromType(
                                            getIncidentTypesFromString(key),
                                            props,
                                            user
                                        )
                                    );
                                }}
                                colour={
                                    key === report.type
                                        ? ButtonColourOptions.Yellow
                                        : ButtonColourOptions.DarkBlue
                                }
                                disabled={props.id > 0}
                            />
                        </div>
                        <Modal
                            modalSize={"sm"}
                            title={"Attention"}
                            bodyChildren={
                                <div className="row ml-0 mr-0">
                                    <p className="mb-0">
                                        Did the defect cause any patient/clinician harm? If yes,
                                        please submit an incident report along with this defect
                                        report.
                                    </p>
                                </div>
                            }
                            footerChildren={
                                <div className="row ml-0 mr-0">
                                    <MCButton
                                        size={ButtonSize.Large}
                                        innerValue={"Dismiss"}
                                        onClick={toggle}
                                        colour={ButtonColourOptions.DarkBlue}
                                        roundedCorner
                                    />
                                </div>
                            }
                            onClose={toggle}
                            isShown={isShown}
                        />
                    </React.Fragment>
                );
            case IncidentType.None:
                return <div />;
        }
    };

    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Report Type"} />
                <FormActionContainer>
                    <React.Fragment>
                        {Object.keys(IncidentType).map((item: string) => {
                            return renderOptionButtons(item, props);
                        })}
                    </React.Fragment>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default ReportTypeButtons;

function getIncidentTypesFromString(value: string): IncidentType {
    return IncidentType[value as keyof typeof IncidentType];
}
