import React from "react";
import {ComplaintFormComplaintDetailsV1} from "../../../../../../../../api/reports/api";
import ReportHeader from "../../../Components/ReportHeader";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../../store/Store";
import {getStaffMemberFromList} from "../../Incident/PartB/Sections/InvestigatingManager";
import {CalendarTypeEnum} from "../../../../../../../../api/grs";
import VenueLocationDetails from "../../Incident/PartA/Sections/VenueLocationDetails";
import FrontlineLocationDetails from "../../Incident/PartA/Sections/FrontlineLocationDetails";

const ComplaintDetails = (props: ComplaintFormComplaintDetailsV1) => {
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Complaint Details`}
            />
            <PrintableFormRow rowName={"Reference Number"} rowContent={props.referenceNumber} />
            <PrintableFormRow
                rowName={"Date Received"}
                rowContent={
                    props.dateReceived ? formatUnixToDDMMYYYY(props.dateReceived) : "Date not added"
                }
            />
            <PrintableFormRow
                rowName={"Received By"}
                rowContent={getStaffMemberFromList(staffList, props.receivedBy)}
            />
            <PrintableFormRow rowName={"Complaints Name"} rowContent={props.complaintsName} />
            <PrintableFormRow rowName={"Organisation"} rowContent={props.organisation} />
            <PrintableFormRow rowName={"Staff Involved"} rowContent={props.staffInvolved} />
            <PrintableFormRow
                rowName={"Details of Complaint"}
                rowContent={props.detailsOfComplaint}
            />
            <PrintableFormRow rowName={"Division"} rowContent={props.divisionName} />
            <PrintableFormRow
                rowName={"Date of Incident"}
                rowContent={
                    props.dateOfIncident
                        ? formatUnixToDDMMYYYY(props.dateOfIncident)
                        : "Date not added"
                }
            />
            {props.calendarType && props.calendarType === CalendarTypeEnum.Event ? (
                <VenueLocationDetails {...props.venueLocationDetails} />
            ) : (
                <FrontlineLocationDetails
                    cadNumber={props.cadNumber}
                    frontlineDetails={props.frontlineDetails}
                />
            )}
        </React.Fragment>
    );
};

export default ComplaintDetails;
