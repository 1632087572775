import React from "react";
import {
    AdminEquipmentDefectFormV1,
    DefectGrade,
    EquipmentReplaceAnswer,
    YesNoType
} from "../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../Form/FormHeader";
import Dropdown from "../../../../../../../Dropdown/Dropdown";
import {setAdminEquipmentDefectDetails} from "../../../../../../../../store/reports/actions/ReportActions";
import {
    getDefectGradeFromString,
    getEquipmentReplacedAnswerFromString,
    getYesNoAnswerFromString
} from "../../../../../../../../utils/enumUtils";
import FormRow from "../../../../../../../Form/FormRow";
import {useDispatch} from "react-redux";
import {canUserEditDefectFormPartB} from "../../../../../../../Hooks/useCanUserEditForms";
import {DebounceInput} from "react-debounce-input";
import AuthAmI from "../../../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../../../api/staff";
import ReportStateAmI from "../../../../../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../../../../../api/im";
import UploadDownloadActions from "../../../../Upload/UploadDownloadActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import {useReport} from "../../../../../../../Hooks/useReport";

const AdminEquipmentDefectForm = (props: AdminEquipmentDefectFormV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditDefectFormPartB();
    const report = useReport();
    return (
        <React.Fragment>
            <FormHeader headerName={"Admin Section - Equipment Defect Form"} />
            <FormRow rowName={"Defect Grade"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.defectGrade}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                defectGrade: getDefectGradeFromString(item.toString())
                            })
                        );
                    }}
                    options={Object.values(DefectGrade)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow
                rowName={"Equipment taken out of commission?"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <Dropdown
                    initialValue={props.equipmentTakenOutOfCommission}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                equipmentTakenOutOfCommission: getYesNoAnswerFromString(
                                    item.toString()
                                )
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.equipmentTakenOutOfCommission === YesNoType.Yes ? (
                <FormRow
                    rowName={"Has equipment been correctly tagged?"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <Dropdown
                        initialValue={props.hasEquipmentBeenCorrectlyTagged}
                        changeOption={(item) => {
                            if (!item) return;
                            dispatch(
                                setAdminEquipmentDefectDetails({
                                    ...props,
                                    hasEquipmentBeenCorrectlyTagged: getYesNoAnswerFromString(
                                        item.toString()
                                    )
                                })
                            );
                        }}
                        options={Object.values(YesNoType)}
                        disabled={!canEdit}
                    />
                </FormRow>
            ) : (
                <FormRow rowName={"Please record why"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setAdminEquipmentDefectDetails({
                                    ...props,
                                    equipmentNotTakenOutOfCommission: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.equipmentNotTakenOutOfCommission}
                        placeholder="Enter why here..."
                    />
                </FormRow>
            )}
            <FormRow rowName={"Equipment repairable?"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.equipmentRepairable}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                equipmentRepairable: getYesNoAnswerFromString(item.toString())
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.equipmentRepairable === YesNoType.Yes ? (
                <FormRow
                    rowName={"Please record actions taken"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setAdminEquipmentDefectDetails({
                                    ...props,
                                    positiveActionTaken: event.target.value,
                                    negativeActionTaken: ""
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.positiveActionTaken}
                        placeholder="Record actions here..."
                    />
                </FormRow>
            ) : (
                <FormRow
                    rowName={"Please record actions taken"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setAdminEquipmentDefectDetails({
                                    ...props,
                                    negativeActionTaken: event.target.value,
                                    positiveActionTaken: ""
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.negativeActionTaken}
                        placeholder="Record actions here..."
                    />
                </FormRow>
            )}
            <FormRow
                rowName={"Equipment replaced for operational use?"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <Dropdown
                    initialValue={props.equipmentReplacedForOperationalUse}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                equipmentReplacedForOperationalUse:
                                    getEquipmentReplacedAnswerFromString(item.toString())
                            })
                        );
                    }}
                    options={Object.values(EquipmentReplaceAnswer)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element="textarea"
                    onChange={(event) => {
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                comments: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.comments}
                    placeholder="Enter comments here..."
                />
            </FormRow>
            <FormRow rowName={"Equipment defect file"} columnDetailClassName={"pl-3 pr-3"}>
                {props.filename ? (
                    <p className="mb-2">{props.filename}</p>
                ) : (
                    <p className="mb-2">No document has been uploaded</p>
                )}
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <ReportStateAmI
                        currentReportState={report.state}
                        reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                    >
                        <UploadDownloadActions
                            fileName={props.filename}
                            onFileUploaded={(fileName) => {
                                dispatch(
                                    setAdminEquipmentDefectDetails({
                                        ...props,
                                        filename: fileName
                                    })
                                );
                            }}
                        />
                    </ReportStateAmI>
                </AuthAmI>
            </FormRow>
            <FormRow rowName={"Date closed"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateClosed ? moment(props.dateClosed * 1000).toDate() : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setAdminEquipmentDefectDetails({
                                ...props,
                                dateClosed: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default AdminEquipmentDefectForm;
