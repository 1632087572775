import {Dispatch} from "redux";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {
    CALENDAR_SUMMARY_LIST_STORE_STATE,
    CalendarSummaryListDispatchTypes
} from "./CalendarSummaryListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyCalendarSummaryListStore = () => {
    return async (dispatch: Dispatch<CalendarSummaryListDispatchTypes>) => {
        dispatch({
            type: CALENDAR_SUMMARY_LIST_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const fetchCalendarSummaryList = () => {
    return async (dispatch: Dispatch<CalendarSummaryListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                CALENDAR_SUMMARY_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarSummary(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_SUMMARY_LIST_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
