import React from "react";
import {ComponentChildrenProps} from "../../../../../../utils/componentUtils";

const PrintableTH = (props: PrintableTH) => {
    return (
        <th
            style={{
                maxWidth: props.maxWidth,
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: "#eeeeee",
                fontSize: "1em",
                padding: props.padding
            }}
            align={props.alignment}
        >
            {props.children}
        </th>
    );
};

export default PrintableTH;

interface PrintableTH extends ComponentChildrenProps {
    alignment: "left" | "center" | "right" | "justify" | "char" | undefined;
    padding: string;
    maxWidth: string;
}
