import {Dispatch} from "redux";
import {VENUE_LIST_STORE_STATE, VenueListDispatchTypes} from "./VenueListActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyVenueListStore = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        dispatch({
            type: VENUE_LIST_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: []
        });
    };
};

export const fetchAllVenues = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getAllVenues(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const fetchVenuesForCalendarId = (id: number) => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getVenuesForCalendarId(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
