import React from "react";
import {ComponentChildrenProps} from "../../../../../../utils/componentUtils";

const PrintableTable = (props: PrintableTableProps) => {
    return (
        <table
            style={{
                width: props.width ? props.width : "100%",
                borderCollapse: "collapse",
                marginTop: props.marginTop
            }}
        >
            {props.children}
        </table>
    );
};

export default PrintableTable;

interface PrintableTableProps extends ComponentChildrenProps {
    marginTop?: string;
    width?: string;
}
