import React from "react";
import {WitnessDetailsV1, YesNoType} from "../../../../../../../../../../api/reports/api";
import FormHeaderWithAction from "../../../../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../../../../Icon/Icon";
import {canUserEditStaffIncidentSection} from "../../../../../../../../../Hooks/useCanUserEditForms";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {DebounceInput} from "react-debounce-input";
import FormRow from "../../../../../../../../../Form/FormRow";
import {
    removeExistingWitnessDetails,
    setExistingWitnessDetails
} from "../../../../../../../../../../store/reports/actions/ReportActions";
import Dropdown from "../../../../../../../../../Dropdown/Dropdown";
import MCButton, {
    ButtonColourOptions,
    ButtonSize
} from "../../../../../../../../../Button/MCButton";

const WitnessDetailsItem = (props: WitnessDetailsV1) => {
    const canEdit = canUserEditStaffIncidentSection();
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Witness"}
                icon={IconType.Bin}
                onClick={toggle}
                showIcon={canEdit}
                tooltipText={"Remove Witness"}
            />
            <FormRow rowName={"Witness Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields"}
                    onChange={(event) => {
                        dispatch(
                            setExistingWitnessDetails({
                                ...props,
                                name: event.target.value
                            })
                        );
                    }}
                    disabled={!canEdit}
                    value={props.name}
                    placeholder="Enter witness name here..."
                />
            </FormRow>
            <FormRow rowName={"Contactable"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.contactable}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setExistingWitnessDetails({
                                ...props,
                                contactable: item.toString()
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.contactable === YesNoType.Yes && (
                <FormRow rowName={"Telephone"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        onChange={(event) => {
                            dispatch(
                                setExistingWitnessDetails({
                                    ...props,
                                    telephone: event.target.value
                                })
                            );
                        }}
                        disabled={!canEdit}
                        value={props.telephone}
                        placeholder="Enter telephone here..."
                    />
                </FormRow>
            )}
            <Modal
                modalSize={"sm"}
                title={"Remove Witness Details"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">Are you sure you want to remove this witness?</p>
                    </div>
                }
                footerChildren={
                    <div className="row mr-0 ml-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                dispatch(removeExistingWitnessDetails(props));
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default WitnessDetailsItem;
