import {useDispatch} from "react-redux";
import {ReportPageRequest} from "../../../../../../api/im";
import {fetchReportListing} from "../../../../../../store/reportList/actions/ReportListActions";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {routeNames} from "../../../../../Navigation/routeNames";
import {useReportListRequest} from "./useReportListRequest";

export function useReportListView() {
    const dispatch = useDispatch();
    const {request, updateRequest} = useReportListRequest({initialFetch: fetchData});
    const {searchQueries} = usePageUrl();
    const history = useHistory();

    const onReportRequestChanged = (entity: ReportPageRequest) => {
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(entity) === JSON.stringify(request)) return;

        fetchData(entity);
    };

    // Fetches Audits
    function fetchData(entity: ReportPageRequest) {
        updateRequest(entity);
        dispatch(fetchReportListing(entity));
    }

    function createReport() {
        history.push({
            pathname: routeNames.reportCreate.path,
            search: searchQueries
        });
    }

    return {
        fetchData,
        onReportRequestChanged,
        request,
        createReport
    };
}
