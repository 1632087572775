import React from "react";
import {useReport} from "../../../../Hooks/useReport";
import {useUserData} from "../../../../Hooks/useUserData";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../api/staff";
import ReportStateAmI from "../../../ReportList/Components/Shared/ReportStateAmI";
import {ReportState} from "../../../../../api/im";
import ReportFormMessageBlock from "./ReportFormMessageBlock";

const ReportFormMessage = () => {
    const user = useUserData();
    const report = useReport();

    return (
        <React.Fragment>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.PendingReview]}
                >
                    {user.username !== report.staffId && (
                        <ReportFormMessageBlock
                            message={
                                "You are viewing a read only version of the staff report. If changes are required to the staff report, contact the original author to make changes."
                            }
                        />
                    )}
                </ReportStateAmI>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.UnderReview]}
                >
                    {user.username !== report.staffId && (
                        <ReportFormMessageBlock
                            message={
                                "You are viewing a read only version of the staff report. If changes are required to the staff report, mark the report as 'Pending Review' and contact the original author to make changes."
                            }
                        />
                    )}
                </ReportStateAmI>
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.UnderReview]}
                >
                    {user.username === report.staffId && (
                        <ReportFormMessageBlock
                            message={
                                "This is a read only version. This report is currently under review. Scroll down to see the progress of the report."
                            }
                        />
                    )}
                </ReportStateAmI>
                <ReportStateAmI
                    currentReportState={report.state}
                    reportStates={[ReportState.CompletedReview]}
                >
                    {user.username === report.staffId && (
                        <ReportFormMessageBlock
                            message={
                                "This report has been completed. Scroll down to view the full report!"
                            }
                        />
                    )}
                </ReportStateAmI>
            </AuthAmI>
        </React.Fragment>
    );
};

export default ReportFormMessage;
