import React from "react";
import {ComponentChildrenProps} from "../../../../../../utils/componentUtils";

const PrintableTD = (props: PrintableTD) => {
    return (
        <td
            style={{
                minWidth: props.minWidth,
                maxWidth: "350px",
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: "#1a2a35",
                fontSize: "0.9em",
                padding: props.padding,
                backgroundColor: props.backgroundColor
            }}
            align={props.alignment}
        >
            {props.children}
        </td>
    );
};

export default PrintableTD;

interface PrintableTD extends ComponentChildrenProps {
    alignment: "left" | "center" | "right" | "justify" | "char" | undefined;
    padding: string;
    minWidth?: string;
    maxWidth?: string;
    backgroundColor?: string;
}
