import {Venue} from "../../../api/grs";
import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const VENUE_LIST_STORE_STATE = createStoreState("venue_list");

export interface VenueListLoading extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.LOADING;
}

export interface VenueListError extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.ERROR;
}

export interface VenueListSuccess extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.SUCCESS;
}

export type VenueListDispatchTypes = VenueListSuccess | VenueListError | VenueListLoading;
