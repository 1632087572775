import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditReportFormParent from "./Components/EditReportFormParent";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../store/Store";
import {useUserData} from "../../Hooks/useUserData";
import {newReport} from "../../../store/reports/actions/ReportActions";

const ServiceWrapper = WithServiceState(EditReportFormParent);

const CreateReport = () => {
    const dispatch = useDispatch();

    const reportStore = useSelector((state: RootStore) => state.report);
    const user = useUserData();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.reportList.name,
                path: routeNames.reportList.path
            },
            {
                name: routeNames.reportCreate.name,
                path: routeNames.reportCreate.path
            }
        ];

        dispatch(setNavigationItems(navItems));
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;

        dispatch(newReport(user));
    }, [user]);
    return (
        <React.Fragment>
            <div className="page-container">
                <ServiceWrapper
                    {...reportStore}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                />
            </div>
        </React.Fragment>
    );
};

export default CreateReport;
