import React from "react";
import FormHeader from "../../../../../../../../Form/FormHeader";
import {
    HarmLevelType,
    IncidentSeverityV1,
    OutcomeType,
    SeriousIncidentReviewOutcomeType
} from "../../../../../../../../../api/reports/api";
import Dropdown from "../../../../../../../../Dropdown/Dropdown";
import {setSeverityDetails} from "../../../../../../../../../store/reports/actions/ReportActions";
import FormRow from "../../../../../../../../Form/FormRow";
import {useDispatch} from "react-redux";
import AuthAmI from "../../../../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../../../../api/staff";
import ReportStateAmI from "../../../../../../../ReportList/Components/Shared/ReportStateAmI";
import {useReport} from "../../../../../../../../Hooks/useReport";
import {ReportState} from "../../../../../../../../../api/im";
import UploadDownloadActions from "../../../../../Upload/UploadDownloadActions";
import {canUserEditManagerIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";

const SeverityDetails = (props: IncidentSeverityV1) => {
    const dispatch = useDispatch();
    const report = useReport();
    const canEdit = canUserEditManagerIncidentSection();
    return (
        <React.Fragment>
            <FormHeader headerName={"Severity"} />
            <FormRow rowName={"Outcome"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.outcome}
                    changeOption={(item) => {
                        if (!item) return;
                        const severity: IncidentSeverityV1 = {
                            ...props,
                            outcome: item.toString()
                        };

                        dispatch(setSeverityDetails(severity));
                    }}
                    options={Object.values(OutcomeType)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Harm Level"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.harmLevel}
                    changeOption={(item) => {
                        if (!item) return;
                        const severity: IncidentSeverityV1 = {
                            ...props,
                            harmLevel: item.toString()
                        };

                        dispatch(setSeverityDetails(severity));
                    }}
                    options={Object.values(HarmLevelType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.seriousIncidentReviewOutcome && (
                <FormRow
                    rowName={"Serious Incident Review Outcome"}
                    columnDetailClassName={"pl-0 pr-0"}
                >
                    <Dropdown
                        initialValue={props.seriousIncidentReviewOutcome}
                        changeOption={(item) => {
                            if (!item) return;
                            const severity: IncidentSeverityV1 = {
                                ...props,
                                seriousIncidentReviewOutcome: item.toString()
                            };

                            dispatch(setSeverityDetails(severity));
                        }}
                        options={Object.values(SeriousIncidentReviewOutcomeType)}
                        disabled={!canEdit}
                    />
                </FormRow>
            )}
            {props.seriousIncidentReviewOutcome &&
                props.seriousIncidentReviewOutcome !==
                    SeriousIncidentReviewOutcomeType.Not_Applicable && (
                    <FormRow rowName={"Serious Incident File"} columnDetailClassName={"pl-3 pr-3"}>
                        {props.filename ? (
                            <p className="mb-2">{props.filename}</p>
                        ) : (
                            <p className="mb-2">No document has been uploaded</p>
                        )}
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <ReportStateAmI
                                currentReportState={report.state}
                                reportStates={[ReportState.PendingReview, ReportState.UnderReview]}
                            >
                                <UploadDownloadActions
                                    fileName={props.filename}
                                    onFileUploaded={(fileName) => {
                                        const severity: IncidentSeverityV1 = {
                                            ...props,
                                            filename: fileName
                                        };

                                        dispatch(setSeverityDetails(severity));
                                    }}
                                />
                            </ReportStateAmI>
                        </AuthAmI>
                    </FormRow>
                )}
        </React.Fragment>
    );
};

export default SeverityDetails;
