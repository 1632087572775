import React from "react";
import {StaffIncidentFormV1} from "../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../Form/FormHeader";
import IncidentDetails from "./IncidentDetails/IncidentDetails";
import ReporterDetails from "./ReporterDetails/ReporterDetails";
import IncidentDescription from "./IncidentDescription/IncidentDescription";

const StaffIncidentForm = (props: StaffIncidentFormV1) => {
    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={"Staff Incident Form"} />
                <IncidentDetails {...props.incidentDetails} />
                <ReporterDetails {...props.reporterDetails} />
                <IncidentDescription {...props.incidentDescription} />
            </div>
        </React.Fragment>
    );
};

export default StaffIncidentForm;
