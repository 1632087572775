import React from "react";
import Icon, {IconType} from "../Icon/Icon";

function FAB({onClick}: Props) {
    return (
        <button className="fab-button" onClick={onClick}>
            <Icon rootElement={"Span"} icon={IconType.Plus} size={"Small"} />
        </button>
    );
}

export default FAB;

interface Props {
    onClick: () => void;
}
