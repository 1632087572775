import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {CalendarSummary} from "../../../api/grs";
import {CALENDAR_SUMMARY_LIST_STORE_STATE} from "../actions/CalendarSummaryListActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<CalendarSummary[]>([]);

const calendarSummaryListReducer = (
    state: StoreServiceData<CalendarSummary[]> = defaultState,
    action: ServiceActionTypes<CalendarSummary[]>
): StoreServiceData<CalendarSummary[]> =>
    createReducer(state, action, CALENDAR_SUMMARY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default calendarSummaryListReducer;
