import React from "react";
import {VenueLocationDetailsV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import {toArray} from "../../../../../../../../../utils/sortingUtils";
import PrintableFormRow from "../../../../Components/PrintableFormRow";

const VenueLocationDetails = (props: VenueLocationDetailsV1[]) => {
    return (
        <React.Fragment>
            {toArray(props).length > 0 && (
                <React.Fragment>
                    <ReportHeader
                        padding={"0.75rem"}
                        marginTop={"15px"}
                        alignment={"left"}
                        reportHeaderText={`Location Details`}
                    />
                    {toArray(props).map((item: VenueLocationDetailsV1, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ReportHeader
                                    padding={"0.75rem"}
                                    marginTop={"0px"}
                                    alignment={"left"}
                                    reportHeaderText={`Location`}
                                />
                                <PrintableFormRow
                                    rowName={"Incident Location"}
                                    rowContent={item.venueName}
                                />
                                <PrintableFormRow rowName={"Address"} rowContent={item.address} />
                                <PrintableFormRow rowName={"Postcode"} rowContent={item.postcode} />
                            </React.Fragment>
                        );
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default VenueLocationDetails;
