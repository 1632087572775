import React from "react";
import {ReportPageResponse, ReportState} from "../../../../../api/im";
import FilterContainer from "../../../../Filters/FilterContainer";
import ReportListFilters from "../Shared/ReportListFilters";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import {getUiFriendlyText} from "../../../../../utils/textUtils";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import ReportListActionItem from "./ReportListActionItem";
import {useReportListView} from "../Shared/Hooks/useReportListView";

const ReportListDesktopView = (props: ReportPageResponse) => {
    const {onReportRequestChanged, request, fetchData} = useReportListView();

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <ReportListFilters request={request} onRequestChanged={onReportRequestChanged} />
            </FilterContainer>
            <div className="mt-4">
                <PulseTableWithServerPagination
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                    totalResults={props.totalResults}
                    onPageChanged={(newPageNum) => {
                        if (!request) return;

                        fetchData({
                            ...request,
                            pageNum: newPageNum
                        });
                    }}
                    items={toTableRow(props)}
                    customRenderers={{
                        reportState: (item: ReportListTableRow) => (
                            <React.Fragment>
                                <p className="mb-0">{getUiFriendlyText(item.reportState)}</p>
                            </React.Fragment>
                        ),
                        actions: (item: ReportListTableRow) => (
                            <React.Fragment>
                                <ReportListActionItem
                                    item={item}
                                    onActionConfirmed={async () => {
                                        if (!request) return;

                                        fetchData(request);
                                    }}
                                />
                            </React.Fragment>
                        )
                    }}
                    headers={{
                        reportType: "Report Type",
                        createdBy: "Original Author",
                        dateCreated: "Date Created",
                        dateModified: "Date Modified",
                        division: "Division",
                        reportState: "Report State",
                        actions: "Actions"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no reports that match the filters
                                </h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default ReportListDesktopView;

export interface ReportListTableRow {
    reportType: string;
    createdBy: string;
    dateCreated: string;
    dateModified: string;
    division: string;
    reportState: ReportState;
    actions: number;
}

function toTableRow(response: ReportPageResponse): ReportListTableRow[] {
    return response.reports.map((item) => {
        return {
            reportType: getUiFriendlyText(item.type),
            createdBy: item.staffName,
            dateCreated: formatUnixToDDMMYYYY(item.dateCreated),
            dateModified: formatUnixToDDMMYYYY(item.dateModified),
            division: item.calendarName || "Calendar Deleted",
            reportState: item.state,
            actions: item.id
        };
    });
}
