export const routeNames = {
    reportList: {
        name: "View Reports",
        path: "/reports/list"
    },
    reportCreate: {
        name: "Create Report",
        path: "/create-report"
    },
    reportReview: {
        name: "Review Report",
        path: "/review-report"
    },
    reportEdit: {
        name: "Edit Report",
        path: "/edit-report"
    },
    reportView: {
        name: "View Report",
        path: "/view-report"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
