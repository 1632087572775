import React from "react";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import DatePicker from "react-datepicker";
import moment from "moment";
import {setClosureOfIncidentDetails} from "../../../../../../../../../store/reports/actions/ReportActions";
import DatePickerInputButton from "../../../../../../../../Button/DatePickerInputButton";
import {
    ClosureOfIncidentV1,
    ReportedToType,
    YesNoType
} from "../../../../../../../../../api/reports/api";
import {useDispatch} from "react-redux";
import Dropdown from "../../../../../../../../Dropdown/Dropdown";
import {canUserEditManagerIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";

const ClosureOfIncident = (props: ClosureOfIncidentV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditManagerIncidentSection();
    return (
        <React.Fragment>
            <FormHeader headerName={"Closure of Incident"} />
            <FormRow rowName={"Date Closed"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateClosed ? moment(props.dateClosed * 1000).toDate() : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setClosureOfIncidentDetails({
                                ...props,
                                dateClosed: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Feedback Provided"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.feedbackProvided}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setClosureOfIncidentDetails({
                                ...props,
                                feedbackProvided: item.toString()
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"External Reporting Required?"} columnDetailClassName={"pl-0 pr-0"}>
                <Dropdown
                    initialValue={props.externalReportRequired}
                    changeOption={(item) => {
                        if (!item) return;
                        dispatch(
                            setClosureOfIncidentDetails({
                                ...props,
                                externalReportRequired: item.toString()
                            })
                        );
                    }}
                    options={Object.values(YesNoType)}
                    disabled={!canEdit}
                />
            </FormRow>
            {props.externalReportRequired === YesNoType.Yes && (
                <React.Fragment>
                    <FormRow rowName={"Reported To?"} columnDetailClassName={"pl-0 pr-0"}>
                        <Dropdown
                            initialValue={props.reportedTo}
                            changeOption={(item) => {
                                if (!item) return;
                                dispatch(
                                    setClosureOfIncidentDetails({
                                        ...props,
                                        reportedTo: item.toString()
                                    })
                                );
                            }}
                            options={Object.values(ReportedToType)}
                            disabled={!canEdit}
                        />
                    </FormRow>
                    <FormRow rowName={"Date Reported"} columnDetailClassName={"pl-0 pr-0"}>
                        <DatePicker
                            selected={
                                props.dateReported
                                    ? moment(props.dateReported * 1000).toDate()
                                    : undefined
                            }
                            onChange={(date) => {
                                const newDate = date ? moment(date).unix() : undefined;
                                dispatch(
                                    setClosureOfIncidentDetails({
                                        ...props,
                                        dateReported: newDate
                                    })
                                );
                            }}
                            dateFormat="do MMMM yyyy"
                            portalId="root-portal"
                            isClearable={canEdit}
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                            disabled={!canEdit}
                        />
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default ClosureOfIncident;
