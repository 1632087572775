import React from "react";
import {ReporterDetailsV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableTableRow from "../../../../Components/PrintableTableRow";
import PrintableTableBody from "../../../../Components/PrintableTableBody";
import PrintableTable from "../../../../Components/PrintableTable";
import PrintableTH from "../../../../Components/PrintableTH";
import PrintableTD from "../../../../Components/PrintableTD";

const ReporterDetails = (props: ReporterDetailsV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Reporter Details`}
            />
            <PrintableTable marginTop={"0px"} width={"800px"}>
                <PrintableTableBody>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            Name
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            Email
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            Skill Level
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            Telephone
                        </PrintableTH>
                    </PrintableTableRow>
                    <PrintableTableRow rowType={"bodyRow"}>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            {props.name}
                        </PrintableTD>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            {props.email}
                        </PrintableTD>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            {props.skillLevel}
                        </PrintableTD>
                        <PrintableTD alignment={"left"} padding="1rem" maxWidth={"100px"}>
                            {props.telephone}
                        </PrintableTD>
                    </PrintableTableRow>
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default ReporterDetails;
