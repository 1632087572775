import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useHistory} from "react-router-dom";
import {login} from "../../../../store/auth/actions/AuthActions";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {CustomLoadingWheel} from "react-state-helpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import useMcConfig from "../../../Hooks/useMcConfig";
import {routeNames} from "../../../Navigation/routeNames";
import {getConfig} from "../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

const LoggedIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const authStoreState = useSelector((state: RootStore) => state.auth);
    const autoLoginTimeout: any | undefined = undefined;
    const timer = useRef(autoLoginTimeout);

    const {config} = useMcConfig();

    const currentRoute = localStorage.getItem("current_route");

    useEffect(() => {
        dispatch(setNavigationItems([]));

        timer.current = setTimeout(() => {
            retryLogin();
        }, 5000);
    }, []);

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (authStoreState.data && authStoreState.authenticated) {
            navigateHome(authStoreState.data);
            clearTimeout(timer.current);
            return;
        }

        timer.current = setTimeout(() => {
            retryLogin();
        }, 5000);
    }, [authStoreState.data, authStoreState.authenticated]);

    const navigateHome = async (user: UserData) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (currentRoute) {
            history.push(currentRoute);
            return;
        }

        const mcConfig = await getConfig();
        const accessLevel = getUserAccessLevel(user, mcConfig);

        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                history.push(routeNames.reportList.path);
                break;
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                history.push(routeNames.reportList.path);
                break;
        }
    };

    const retryLogin = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        loginToSystem();
    };

    const loginToSystem = () => {
        dispatch(login());
    };

    return (
        <div className="page background-stars">
            <h3 className="text-center title mt-5 pt-5 header-font">
                Loading {config.systemDetails.defaultName}...
            </h3>
            <CustomLoadingWheel />
            <div className="mt-5 text-center">
                <strong className="header-font">Checking Your Credentials</strong>
            </div>
            <p className="mt-4 text-center body-font">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Please wait while your credentials are checked. If you aren't redirected promptly,{" "}
                <br></br>
                click the button below <br></br>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Click here"
                    className="mt-4"
                    onClick={loginToSystem}
                    colour={ButtonColourOptions.Yellow}
                />
            </p>
        </div>
    );
};

export default LoggedIn;
