import React, {useEffect, useState} from "react";
import {Provider, useDispatch} from "react-redux";
import store from "./store/Store";
import Routes from "./components/Navigation/Routes/Routes";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {getMcConfig} from "./store/mcConfig/actions/McConfigActions";
import CacheBusterWrapper from "./components/CacheBuster/CacheBusterWrapper";
import UserLeaveConfirmation from "./components/Navigation/UserLeaveConfirmation/UserLeaveConfirmation";

const App = () => {
    return (
        <CacheBusterWrapper>
            <Provider store={store}>
                <RouteWrapper />
                <ToastContainer
                    position="bottom-center"
                    pauseOnHover
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    draggable
                    autoClose={5000}
                />
            </Provider>
        </CacheBusterWrapper>
    );
};

export default App;

function RouteWrapper() {
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState(true);
    useEffect(() => {
        dispatch(getMcConfig());
    }, []);
    return (
        <Router
            getUserConfirmation={(message, callback) => {
                return UserLeaveConfirmation(message, callback, confirmOpen, setConfirmOpen);
            }}
        >
            <Routes />
        </Router>
    );
}
