import React from "react";
import FormHeaderWithAction from "../../../../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../../../../Icon/Icon";
import {canUserEditStaffIncidentSection} from "../../../../../../../../../Hooks/useCanUserEditForms";
import {
    IncidentDescriptionV1,
    WitnessDetailsV1,
    YesNoType
} from "../../../../../../../../../../api/reports/api";
import {nanoid} from "nanoid";
import {addNewWitnessDetails} from "../../../../../../../../../../store/reports/actions/ReportActions";
import {useDispatch} from "react-redux";
import WitnessDetailsItem from "./WitnessDetailsItem";

const WitnessDetails = (props: IncidentDescriptionV1) => {
    const canEdit = canUserEditStaffIncidentSection();
    const dispatch = useDispatch();
    const addWitnessDetails = () => {
        const newWitness: WitnessDetailsV1 = {
            uid: nanoid(),
            name: "",
            telephone: "",
            contactable: YesNoType.No
        };

        dispatch(addNewWitnessDetails(newWitness));
    };
    return (
        <div className="mt-4">
            <FormHeaderWithAction
                headerName={"Witnesses"}
                tooltipText={"Add Witnesses"}
                icon={IconType.Add}
                showIcon={canEdit}
                onClick={addWitnessDetails}
            />
            {props.witnesses.length > 0 ? (
                <React.Fragment>
                    {props.witnesses.map((item, index) => {
                        return <WitnessDetailsItem {...item} key={index} />;
                    })}
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">No witnesses were added</h5>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WitnessDetails;
