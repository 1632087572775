import {useUserData} from "../../../Hooks/useUserData";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useEffect} from "react";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {fetchAllStaff} from "../../../../store/staffList/actions/StaffListActions";
import {fetchCalendarSummaryList} from "../../../../store/calendarSummaryList/actions/CalendarSummaryListActions";

export function useReportList() {
    const user = useUserData();
    const dispatch = useDispatch();
    const reportListStore = useSelector((state: RootStore) => state.reportList);

    useEffect(() => {
        if (user.username.length === 0) return;

        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.reportList.name,
                path: routeNames.reportList.path
            },
            {
                name: routeNames.reportCreate.name,
                path: routeNames.reportCreate.path
            }
        ];

        dispatch(setNavigationItems(navigationItems));

        dispatch(fetchAllStaff());
        dispatch(fetchCalendarSummaryList());
    }, [user]);

    return {
        reportListStore
    };
}
