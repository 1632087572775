import {ReportPageSortField, ReportState, SortOrder} from "../../../../../../api/im";
import {StaffAccessLevel, UserData} from "../../../../../../api/staff";
import moment from "moment";
import {useUserData} from "../../../../../Hooks/useUserData";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";

export function useReportListRequestMutations() {
    const user = useUserData();
    const {config} = useMcConfig();
    function getQuery(key: string, value: string | number | undefined): string {
        return `${key}=${value}`;
    }

    function getDate(
        unixStamp: string | number | undefined | null,
        startEndType: StartEndType
    ): number | undefined {
        if (!unixStamp) {
            return undefined;
        }

        switch (startEndType) {
            case "start":
                return moment.unix(+unixStamp).clone().startOf("month").unix();
            case "end":
                return moment.unix(+unixStamp).clone().endOf("month").unix();
        }
    }

    function getFilterOptionsForReportTypeDropdown() {
        switch (user.accessLevel) {
            case StaffAccessLevel.Staff:
                return StaffIncidentTypeFilters;
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.SystemAdministrator:
                return ExtendedIncidentTypes;
        }
    }

    function getExtendedIncidentTypesFromString(value?: string): ExtendedIncidentTypes {
        return ExtendedIncidentTypes[value as keyof typeof ExtendedIncidentTypes];
    }

    function getSortOrderFromString(value: string): SortOrder {
        return SortOrder[value as keyof typeof SortOrder];
    }

    function getReportStateFromString(value?: string): ReportState {
        return ReportState[value as keyof typeof ReportState];
    }

    function getSortFieldFromString(value: string): ReportPageSortField {
        return ReportPageSortField[value as keyof typeof ReportPageSortField];
    }

    function getStaffIdForRequest(
        targetUser: UserData,
        selectedStaffId?: string | null
    ): string | undefined {
        const systemAccessLevel = getUserAccessLevel(user, config);
        switch (systemAccessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return selectedStaffId || undefined;
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                return targetUser.username;
        }
    }

    return {
        getQuery,
        getDate,
        getFilterOptionsForReportTypeDropdown,
        getStaffIdForRequest,
        getSortFieldFromString,
        getReportStateFromString,
        getSortOrderFromString,
        getExtendedIncidentTypesFromString
    };
}

export type StartEndType = "start" | "end";

// eslint-disable-next-line no-shadow
export enum ExtendedIncidentTypes {
    Incident = "Incident",
    Complaint = "Complaint",
    VehicleDefect = "VehicleDefect",
    EquipmentDefect = "EquipmentDefect"
}
// eslint-disable-next-line no-shadow
export enum StaffIncidentTypeFilters {
    Incident = "Incident",
    VehicleDefect = "VehicleDefect",
    EquipmentDefect = "EquipmentDefect"
}
