import React from "react";
import {InvestigatingManagerV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../../../store/Store";
import {UserData} from "../../../../../../../../../api/staff";

const InvestigatingManager = (props: InvestigatingManagerV1) => {
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Investigating Manager`}
            />
            <PrintableFormRow
                rowName={"Name"}
                rowContent={getStaffMemberFromList(staffList, props.username)}
            />
            <PrintableFormRow
                rowName={"Current Incident Status"}
                rowContent={props.currentIncidentStatus}
            />
        </React.Fragment>
    );
};

export default InvestigatingManager;

export function getStaffMemberFromList(list: UserData[], userName: string): string {
    const index = list.findIndex((item) => item.username === userName);

    if (index < 0) {
        return "Staff member delete";
    }

    return `${list[index].firstName} ${list[index].lastName}`;
}
