import React from "react";
import {ReportPageResponse} from "../../../../../api/im";
import FilterContainer from "../../../../Filters/FilterContainer";
import ReportListFilters from "../Shared/ReportListFilters";
import {useReportListView} from "../Shared/Hooks/useReportListView";
import ReportListMobileItem from "./ReportListMobileItem";
import Pagination from "../../../../Pagination/Pagination";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import FAB from "../../../../Button/FAB";

function ReportListMobileView(props: ReportPageResponse) {
    const {onReportRequestChanged, request, fetchData, createReport} = useReportListView();
    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <ReportListFilters request={request} onRequestChanged={onReportRequestChanged} />
            </FilterContainer>
            {props.reports.length > 0 ? (
                <React.Fragment>
                    <div className="mobile-list-wrapper">
                        {props.reports.map((report, index) => {
                            return (
                                <ReportListMobileItem
                                    report={report}
                                    onReportDeleted={async () => {
                                        if (!request) return;

                                        fetchData(request);
                                    }}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                    <Pagination
                        totalResults={props.totalResults}
                        onPageChanged={(newPageNum) => {
                            if (!request) return;

                            fetchData({
                                ...request,
                                pageNum: newPageNum
                            });
                        }}
                        resultsPerPage={pagedRequestConfig.resultsPerPage}
                        initialPageNumber={request?.pageNum}
                    />
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There are no reports that match the filters
                        </h5>
                    </div>
                </div>
            )}
            <FAB onClick={createReport} />
        </React.Fragment>
    );
}

export default ReportListMobileView;
