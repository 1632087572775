import React from "react";
import {
    FrontlineLocationDetailsV1,
    IncidentDetailsV1
} from "../../../../../../../../../api/reports/api";
import FormHeader from "../../../../../../../../Form/FormHeader";
import FormRow from "../../../../../../../../Form/FormRow";
import {CalendarSummary, CalendarTypeEnum} from "../../../../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {
    addFrontlineLocationDetails,
    addVenueLocationDetails,
    setIncidentDetailsV1
} from "../../../../../../../../../store/reports/actions/ReportActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../../Button/DatePickerInputButton";
import FrontlineDetails from "./FrontlineDetails/FrontlineDetails";
import FormHeaderWithAction from "../../../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../../../Icon/Icon";
import {nanoid} from "nanoid";
import VenueDetails from "./VenueDetails/VenueDetails";
import {canUserEditStaffIncidentSection} from "../../../../../../../../Hooks/useCanUserEditForms";
import CalendarListDropdown from "../../../../../../../../Dropdown/Components/CalendarListDropdown";

const IncidentDetails = (props: IncidentDetailsV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditStaffIncidentSection();

    const onLocationDetailsAdded = (calendarType: CalendarTypeEnum | undefined) => {
        if (!calendarType) return;

        switch (calendarType) {
            case CalendarTypeEnum.Event:
                dispatch(addVenueLocationDetails(props.division));
                return;
            case CalendarTypeEnum.Frontline:
                const details: FrontlineLocationDetailsV1 = {
                    uid: nanoid(),
                    publicPlace: "",
                    patientsProperty: "",
                    hospital: "",
                    ambulance: ""
                };

                dispatch(addFrontlineLocationDetails(details));
                return;
        }
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Incident Details"} />
            <FormRow rowName={"Division"} columnDetailClassName={"pl-0 pr-0"}>
                <CalendarListDropdown
                    searchable={false}
                    onChange={(calendar) => {
                        if (!calendar) return;
                        const details = getIncidentDetailsFromCalendarType(calendar, props);

                        dispatch(setIncidentDetailsV1(details));
                    }}
                    clearable={false}
                    calendarId={props.division}
                    disabled={!canEdit}
                />
            </FormRow>
            <FormRow rowName={"Incident Date"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={
                        props.dateOfIncident
                            ? moment(props.dateOfIncident * 1000).toDate()
                            : undefined
                    }
                    onChange={(date) => {
                        const newDate = date ? moment(date).unix() : undefined;
                        dispatch(
                            setIncidentDetailsV1({
                                ...props,
                                dateOfIncident: newDate
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    isClearable={canEdit}
                    customInput={<DatePickerInputButton />}
                    placeholderText="Select modified date"
                    disabled={!canEdit}
                />
            </FormRow>

            {props.calendarType && (
                <React.Fragment>
                    <div className="mt-3">
                        <FormHeaderWithAction
                            headerName={"Location Details"}
                            icon={IconType.Add}
                            onClick={() => onLocationDetailsAdded(props.calendarType)}
                            showIcon={canEdit}
                            tooltipText={"Add Location Details"}
                        />
                        {props.calendarType === CalendarTypeEnum.Frontline ? (
                            <FrontlineDetails {...props} />
                        ) : (
                            <VenueDetails {...props} />
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default IncidentDetails;

function getIncidentDetailsFromCalendarType(
    calendar: CalendarSummary,
    props: IncidentDetailsV1
): IncidentDetailsV1 {
    switch (calendar.calendarType) {
        case CalendarTypeEnum.Frontline:
            return {
                ...props,
                division: calendar.id,
                divisionName: calendar.name,
                calendarType: CalendarTypeEnum.Frontline,
                locationOfIncident: []
            };
        case CalendarTypeEnum.Event:
            return {
                ...props,
                division: calendar.id,
                divisionName: calendar.name,
                calendarType: CalendarTypeEnum.Event,
                frontlineDetails: [],
                nhsRefNumber: undefined,
                cadNumber: undefined
            };
    }
}
