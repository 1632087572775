import React from "react";
import {IncidentDescriptionV1} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";
import WitnessDetails from "./WitnessDetails";

const IncidentDescription = (props: IncidentDescriptionV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Incident Description`}
            />
            <PrintableFormRow rowName={"Type"} rowContent={props.type} />
            <PrintableFormRow rowName={"Category"} rowContent={props.category} />
            <PrintableFormRow
                rowName={"Description"}
                rowContent={props.description ? props.description : "Description not added"}
            />
            <PrintableFormRow
                rowName={"Action Taken"}
                rowContent={props.actionTaken ? props.actionTaken : "Action taken not added"}
            />
            <WitnessDetails {...props.witnesses} />
        </React.Fragment>
    );
};

export default IncidentDescription;
