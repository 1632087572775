import {ReportPageRequest, ReportPageSortField, SortOrder} from "../../../../../../api/im";
import {useEffect, useState} from "react";
import {useQuery} from "../../../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {useUserData} from "../../../../../Hooks/useUserData";
import {pagedRequestConfig} from "../../../../../Filters/helpers/filterHelpers";
import {useReportListRequestMutations} from "./useReportListRequestMutations";

export function useReportListRequest({initialFetch}: Props) {
    const [request, setRequest] = useState<ReportPageRequest>();
    const {
        getDate,
        getQuery,
        getReportStateFromString,
        getSortFieldFromString,
        getSortOrderFromString,
        getStaffIdForRequest
    } = useReportListRequestMutations();
    const query = useQuery();
    const user = useUserData();
    const history = useHistory();

    useEffect(() => {
        const initialRequest = getInitialRequest();

        updateRequest(initialRequest);
        initialFetch(initialRequest);
    }, []);

    function getInitialRequest(): ReportPageRequest {
        const {type, cms, cds, sortField, staffId, sortOrder, reportState, calendarId, pageNum} =
            ReportQueryStrings;
        const targetType = query.get(type);
        const targetSortOrder = query.get(sortOrder);
        const targetSortField = query.get(sortField);
        const targetCds = query.get(cds);
        const targetStaffId = query.get(staffId);
        const targetCms = query.get(cms);
        const targetCalendarId = query.get(calendarId);
        const targetPageNum = query.get(pageNum);
        const targetReportState = query.get(reportState);

        return {
            pageNum: targetPageNum ? +targetPageNum : 0,
            type: targetType || undefined,
            numPerPage: pagedRequestConfig.resultsPerPage,
            staffId: getStaffIdForRequest(user, targetStaffId),
            sortOrder: targetSortOrder
                ? getSortOrderFromString(targetSortOrder)
                : SortOrder.Descending,
            sortField: targetSortField
                ? getSortFieldFromString(targetSortField)
                : ReportPageSortField.DateCreated,
            calendarId: targetCalendarId ? +targetCalendarId : undefined,
            reportState: targetReportState
                ? getReportStateFromString(targetReportState)
                : undefined,
            dateCreatedStartInclusive: getDate(targetCds, "start"),
            dateCreatedEndExclusive: getDate(targetCds, "end"),
            dateModifiedStartInclusive: getDate(targetCms, "start"),
            dateModifiedEndExclusive: getDate(targetCms, "end")
        };
    }

    function updateRequest(newRequest: ReportPageRequest) {
        setRequest(newRequest);
        buildUrlHistory(newRequest);
    }

    function buildUrlHistory(entity: ReportPageRequest) {
        const queryStrings: string[] = [];
        const {
            type,
            cms,
            reportState,
            cds,
            cme,
            cde,
            staffId,
            sortField,
            sortOrder,
            calendarId,
            pageNum
        } = ReportQueryStrings;

        if (entity.type) {
            queryStrings.push(getQuery(type, entity.type));
        }
        if (entity.sortOrder) {
            queryStrings.push(getQuery(sortOrder, entity.sortOrder));
        }
        if (entity.sortField) {
            queryStrings.push(getQuery(sortField, entity.sortField));
        }

        if (user.accessLevel === StaffAccessLevel.SystemAdministrator && entity.staffId) {
            queryStrings.push(getQuery(staffId, entity.staffId));
        }

        if (entity.dateCreatedStartInclusive) {
            queryStrings.push(getQuery(cds, entity.dateCreatedStartInclusive));
        }
        if (entity.dateCreatedEndExclusive) {
            queryStrings.push(getQuery(cde, entity.dateCreatedEndExclusive));
        }
        if (entity.dateModifiedStartInclusive) {
            queryStrings.push(getQuery(cms, entity.dateModifiedStartInclusive));
        }
        if (entity.dateModifiedEndExclusive) {
            queryStrings.push(getQuery(cme, entity.dateModifiedEndExclusive));
        }

        if (entity.calendarId) {
            queryStrings.push(getQuery(calendarId, entity.calendarId));
        }
        if (entity.reportState) {
            queryStrings.push(getQuery(reportState, entity.reportState));
        }

        queryStrings.push(getQuery(pageNum, entity.pageNum));

        if (entity) history.push({search: queryStrings.join("&")});
    }

    return {
        request,
        updateRequest
    };
}

interface Props {
    initialFetch: (request: ReportPageRequest) => void;
}

const ReportQueryStrings = {
    type: "type",
    sortOrder: "sortOrder",
    sortField: "sortField",
    staffId: "staffId",
    cds: "cds",
    cde: "cde",
    cms: "cms",
    cme: "cme",
    calendarId: "calendarId",
    pageNum: "pageNum",
    reportState: "reportState"
};
