import {StoreServiceData} from "store-fetch-wrappers";
import {CalendarSummary} from "../../../api/grs";
import {createStoreState} from "store-fetch-wrappers/dist";

export const CALENDAR_SUMMARY_LIST_STORE_STATE = createStoreState("calendar_summary_list");

export interface CalendarSummaryListLoading extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE_STATE.LOADING;
}

export interface CalendarSummaryListError extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE_STATE.ERROR;
}

export interface CalendarSummaryListSuccess extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_SUMMARY_LIST_STORE_STATE.SUCCESS;
}

export type CalendarSummaryListDispatchTypes =
    | CalendarSummaryListSuccess
    | CalendarSummaryListError
    | CalendarSummaryListLoading;
