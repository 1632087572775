import React from "react";
import {
    ReportPageRequest,
    ReportPageSortField,
    ReportState,
    SortOrder
} from "../../../../../api/im";
import moment from "moment";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import {StaffAccessLevel} from "../../../../../api/staff";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import {useReportListRequestMutations} from "./Hooks/useReportListRequestMutations";
import PulseEnumDropdown from "../../../../Dropdown/Components/PulseEnumDropdown";
import CalendarListDropdown from "../../../../Dropdown/Components/CalendarListDropdown";
import PulseStaffDropdown from "../../../../Dropdown/Components/PulseStaffDropdown";

const ReportListFilters = ({request, onRequestChanged}: Props) => {
    const {getDate} = useReportListRequestMutations();
    const {
        getFilterOptionsForReportTypeDropdown,
        getExtendedIncidentTypesFromString,
        getSortOrderFromString,
        getSortFieldFromString,
        getReportStateFromString
    } = useReportListRequestMutations();
    function unixToMomentOrNull(unix: number | undefined | null) {
        if (!unix) return;
        return moment.unix(unix);
    }
    function dateToMomentOrNull(date: Date | null) {
        if (!date) return;
        return moment(date);
    }

    if (!request) return null;

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-item">
                        <h6>Date Created</h6>
                        <DatePicker
                            selected={unixToMomentOrNull(
                                request?.dateCreatedStartInclusive
                            )?.toDate()}
                            onChange={(date) => {
                                const momentObj = dateToMomentOrNull(date);
                                const start = getDate(momentObj?.unix(), "start");
                                const end = getDate(momentObj?.unix(), "end");

                                onRequestChanged({
                                    ...request,
                                    dateCreatedStartInclusive: start,
                                    dateCreatedEndExclusive: end,
                                    pageNum: 0
                                });
                            }}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            isClearable
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select created date"
                        />
                    </div>
                    <div className="filter-item">
                        <h6>Date Modified</h6>
                        <DatePicker
                            selected={unixToMomentOrNull(
                                request?.dateModifiedStartInclusive
                            )?.toDate()}
                            onChange={(date) => {
                                const momentObj = dateToMomentOrNull(date);
                                const start = getDate(momentObj?.unix(), "start");
                                const end = getDate(momentObj?.unix(), "end");

                                onRequestChanged({
                                    ...request,
                                    dateModifiedStartInclusive: start,
                                    dateModifiedEndExclusive: end,
                                    pageNum: 0
                                });
                            }}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            isClearable
                            customInput={<DatePickerInputButton />}
                            placeholderText="Select modified date"
                        />
                    </div>
                    <div className="filter-item">
                        <h6>Staff Member</h6>
                        <PulseStaffDropdown
                            searchable={true}
                            onChange={(staff) => {
                                onRequestChanged({
                                    ...request,
                                    staffId: staff?.staffId,
                                    pageNum: 0
                                });
                            }}
                            clearable={true}
                            username={request?.staffId}
                            disabled={false}
                        />
                    </div>
                </AuthAmI>
                <div className="filter-item">
                    <h6>Report Type</h6>
                    <PulseEnumDropdown
                        value={request?.type}
                        placeholder={"Select Type..."}
                        enumOptions={getFilterOptionsForReportTypeDropdown()}
                        onChange={(option) => {
                            const type = getExtendedIncidentTypesFromString(
                                option?.value.toString()
                            );

                            onRequestChanged({
                                ...request,
                                type,
                                pageNum: 0
                            });
                        }}
                        appendToBody
                        clearable
                    />
                </div>
                <div className="filter-item">
                    <h6>Sort Order</h6>
                    <PulseEnumDropdown
                        value={request?.sortOrder}
                        enumOptions={SortOrder}
                        onChange={(option) => {
                            if (!option) return;
                            const sortOrder = getSortOrderFromString(option.value.toString());
                            onRequestChanged({
                                ...request,
                                sortOrder,
                                pageNum: 0
                            });
                        }}
                        appendToBody
                    />
                </div>
                <div className="filter-item">
                    <h6>Sort Field</h6>
                    <PulseEnumDropdown
                        value={request?.sortField}
                        enumOptions={ReportPageSortField}
                        onChange={(option) => {
                            if (!option) return;
                            const sortField = getSortFieldFromString(option.value.toString());
                            onRequestChanged({
                                ...request,
                                sortField,
                                pageNum: 0
                            });
                        }}
                        appendToBody
                    />
                </div>
                <div className="filter-item">
                    <h6>Report State</h6>
                    <PulseEnumDropdown
                        value={request?.reportState}
                        enumOptions={ReportState}
                        onChange={(option) => {
                            const reportState = getReportStateFromString(option?.value.toString());
                            onRequestChanged({
                                ...request,
                                reportState,
                                pageNum: 0
                            });
                        }}
                        appendToBody
                        clearable
                    />
                </div>
                <div className="filter-item">
                    <h6>Division</h6>
                    <CalendarListDropdown
                        calendarId={request?.calendarId}
                        onChange={(calendar) => {
                            onRequestChanged({
                                ...request,
                                calendarId: calendar?.id,
                                pageNum: 0
                            });
                        }}
                        appendToBody
                        clearable
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReportListFilters;

interface Props {
    request?: ReportPageRequest;
    onRequestChanged: (newRequest: ReportPageRequest) => void;
}
