import React, {useRef} from "react";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {ReportSaveDocRequest, ReportSaveDocResponse} from "../../../../../api/im";
import {useDispatch} from "react-redux";
import {
    saveFileToService,
    updateFileOnService
} from "../../../../../store/file/actions/FileActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {getFileDownloadLink} from "../../../../../store/fileDownload/actions/FileDownloadActions";

const UploadDownloadActions = (props: UploadDownloadActionsProps) => {
    const uploadButtonRef = useRef(null);
    const updateButtonRef = useRef(null);
    const dispatch = useDispatch();

    //Upload File
    const uploadFile = () => {
        if (uploadButtonRef && uploadButtonRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            uploadButtonRef.current.click();
        }
    };

    const onInitialFileSelected = async (event: any) => {
        const file = event?.target?.files[0];

        if (file.type !== "application/pdf") {
            showErrorToast("Uploaded file must be a PDF");
            return;
        }

        const filename = file.name;
        const mimeType = file.type;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const b64: string = await toBase64(file);
        await createUploadRequest({
            contentType: mimeType,
            filename,
            fileBase64: b64.split("base64,")[1]
        });
    };

    const createUploadRequest = async (request: ReportSaveDocRequest) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response: ReportSaveDocResponse = await dispatch(saveFileToService(request));

        if (!response) return;
        props.onFileUploaded(response.filename);
    };

    //updating file
    const updateFile = () => {
        if (updateButtonRef && updateButtonRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            updateButtonRef.current.click();
        }
    };

    const onUpdatedFileSelected = async (event: any) => {
        if (!props.fileName) {
            showErrorToast("Cannot update a file that does not exist");
            return;
        }
        const file = event?.target?.files[0];

        if (file.type !== "application/pdf") {
            showErrorToast("Uploaded file must be a PDF");
            return;
        }

        const filename = file.name;
        const mimeType = file.type;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const b64: string = await toBase64(file);
        await updateFileRequest(props.fileName, {
            contentType: mimeType,
            filename,
            fileBase64: b64.split("base64,")[1]
        });
    };

    const updateFileRequest = async (fileName: string, request: ReportSaveDocRequest) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response: ReportSaveDocResponse = await dispatch(
            updateFileOnService(fileName, request)
        );
        if (!response) return;
        props.onFileUploaded(response.filename);
    };

    const downloadFile = async () => {
        if (!props.fileName) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const file: string = await dispatch(getFileDownloadLink(props.fileName));

        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", props.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <React.Fragment>
            <input
                ref={uploadButtonRef}
                type="file"
                onChange={onInitialFileSelected}
                accept="application/pdf"
                className="d-none"
            />
            <input
                ref={updateButtonRef}
                type="file"
                onChange={onUpdatedFileSelected}
                accept="application/pdf"
                className="d-none"
            />
            {props.fileName ? (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Update File"}
                    onClick={updateFile}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            ) : (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Upload File"}
                    onClick={uploadFile}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}
            {props.fileName && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Download File"}
                    onClick={downloadFile}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}
        </React.Fragment>
    );
};

export default UploadDownloadActions;

interface UploadDownloadActionsProps {
    fileName: string | undefined;
    onFileUploaded: (newFileName: string) => void;
}

function toBase64(file: any) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
