import {UserData} from "../staff";
import {CalendarTypeEnum} from "../grs";
import moment from "moment";
import {getRandomNumber} from "../../utils/mathUtils";


/** Enums */
export enum IncidentType {
    Incident = "Incident",
    Complaint = "Complaint",
    VehicleDefect = "VehicleDefect",
    EquipmentDefect = "EquipmentDefect",
    None = "None"
}

export enum IncidentDescriptionType {
    Patients = "Patient(s)",
    Staff_Member = "Staff Member",
    Visitor_Or_Contractor = "Visitor or Contractor",
    Organisational = "Organisational"
}

export enum IncidentCategoryType {
    Accident_And_Injury = "Accident and injury",
    Clinical_Advice = "Clinical advice",
    Clinical_Assessment = "Clinical assessment",
    Clinical_Treatment = "Clinical treatment (not medicine related)",
    Communication = "Communication",
    Consent_And_Capacity = "Consent and capacity",
    Dispatch_Issue = "Dispatch issue",
    End_Of_Life_Care = "End of life care",
    Infection_Prevention_And_Control = "Infection, prevention and control (not sharps injuries)",
    Information_Governance_And_Data_Breaches = "Information governance and data breaches",
    Infrastructure = "Infrastructure (including IT and telephony issues)",
    Maternity = "Maternity",
    Medical_Equipment = "Medical equipment",
    Medicine_Error = "Medicine error (including administration errors)",
    Medicine_Issue = "Medicine issue (including controlled drug issue)",
    Moving_and_handling = "Moving and handling",
    Non_Medical_Equipment_Issue = "Non-medical equipment issue",
    Safeguarding_Concern = "Safeguarding Concern",
    Security_Issue = "Security issue (damage to property etc)",
    Sharps_Injury = "Sharps injury",
    Staff_Welfare = "Staff welfare (including abuse and violence)",
    Unexpected_Child_Death = "Unexpected child death",
    Vehicle_Related = "Vehicle related"
}

export enum YesNoType {
    Yes = "Yes",
    No = "No"
}

export enum CurrentIncidentStatusType {
    Local_Review = "Local review",
    Away_For_Review = "Away for review",
    Serious_Incident = "Serious Incident"
}

export enum OutcomeType {
    No_Adverse_Outcome = "No adverse outcome",
    Near_Miss = "Near Miss",
    Harm_Caused_By_Incident = "Harm caused by incident"
}

export enum HarmLevelType {
    No_Harm = "No harm",
    Low_Harm = "Low harm",
    Moderate_Harm = "Moderate harm",
    Severe_Harm = "Severe harm",
    Death = "Death",
    Level_Of_Harm_Unknown = "Level of harm unknown"
}

export enum SeriousIncidentReviewOutcomeType {
    Not_Applicable = "Not applicable",
    Level_1_Declared = "Level 1 declared",
    Level_2_Declared = "Level 2 declared",
    Local_Review_Only = "Local review only"
}

export enum ReportedToType {
    NHS_Provider = "NHS Provider (Quality alert)",
    Client = "Client",
    Patient = "Patient (DoC)",
    NRLS = "NRLS",
    Other = "Other"
}

export enum GradeOfComplaintType {
    High = "High",
    Medium = "Medium",
    Low = "Low"
}

export enum ComplaintStatus {
    Open = "Open",
    Closed = "Closed",
    ActionRequired = "Action Required"
}

/** INTERFACES */
export interface IncidentReportFormV1 {
    version: number;
    staffReport: StaffIncidentFormV1;
    managerReport: ManagerIncidentFormV1;
    notes: AdditionalNotes[];
}

/** INCIDENT FORM DATA STRUCTURE */

/** To only show up for Incident Type "Incident" */
/** Form composition for Incidents. First half for the staff members. */
export interface StaffIncidentFormV1 {
    incidentDetails: IncidentDetailsV1;
    reporterDetails: ReporterDetailsV1;
    incidentDescription: IncidentDescriptionV1;
}

export interface IncidentDetailsV1 {
    division: number;
    divisionName: string;
    locationOfIncident: VenueLocationDetailsV1[];
    dateOfIncident: number | undefined;
    frontlineDetails: FrontlineLocationDetailsV1[]
    cadNumber: string | undefined;
    nhsRefNumber: string | undefined;
    calendarType?: CalendarTypeEnum;
}

export interface FrontlineLocationDetailsV1 {
    publicPlace: string;
    patientsProperty: string;
    hospital: string;
    ambulance: string;
    uid: string;
}

export interface VenueLocationDetailsV1 {
    uid: string;
    venueId: number;
    calendarId: number;
    venueName?: string;
    address?: string;
    postcode?: string;
}

export interface ReporterDetailsV1 {
    name: string;
    skillLevel: string;
    telephone: string | undefined;
    email: string;
}

export interface IncidentDescriptionV1 {
    type: string;
    category: string;
    description: string;
    actionTaken: string;
    witnesses: WitnessDetailsV1[];
}

export interface WitnessDetailsV1 {
    uid: string;
    name: string;
    telephone: string;
    contactable: string;
}

/** Form composition for Incidents. Second half for the duty managers and sys admins. */
export interface ManagerIncidentFormV1 {
    investigatingManager: InvestigatingManagerV1;
    severity: IncidentSeverityV1;
    dutyOfCandour: DutyOfCandourV1;
    investigationDetails: ManagerInvestigationDetailsV1;
    closure: ClosureOfIncidentV1;
}

export interface InvestigatingManagerV1 {
    username: string;
    currentIncidentStatus: string;
}

export interface IncidentSeverityV1 {
    outcome: string;
    harmLevel: string;
    seriousIncidentReviewOutcome: string | undefined;
    filename?: string;
}

export interface DutyOfCandourV1 {
    engaged: string;
    notEngagedAnswer: string | undefined;
    nominatedIndividual: string;
    dateOfInitialContact: number | undefined;
    dateOfLetterSent: number | undefined;
    dateOfFinalLetterAndReport: number | undefined;
    filename?: string;
}

export interface ManagerInvestigationDetailsV1 {
    dateStarted: number | undefined;
    outcome: string;
    lessonsLearned: string;
}

export interface ClosureOfIncidentV1 {
    dateClosed: number | undefined;
    feedbackProvided: string;
    externalReportRequired: string;
    reportedTo: string; //Only to be visible if above property is true
    dateReported: number | undefined;
}

/** COMPLAINT FORM DATA STRUCTURE */

export interface ComplaintFormV1 {
    version: number;
    complaintDetails: ComplaintFormComplaintDetailsV1;
    investigationDetails: ComplaintFormInvestigationDetailsV1;
    notes: AdditionalNotes[];
}

export interface ComplaintFormComplaintDetailsV1 {
    referenceNumber: string;
    dateReceived: number | undefined;
    receivedBy: string;
    complaintsName: string;
    organisation: string;
    dateOfIncident: number | undefined;
    division: number;
    divisionName: string;
    venueLocationDetails: VenueLocationDetailsV1[];
    frontlineDetails: FrontlineLocationDetailsV1[];
    cadNumber: string | undefined;
    staffInvolved: string;
    detailsOfComplaint: string;
    calendarType?: CalendarTypeEnum;
}

export interface ComplaintFormInvestigationDetailsV1 {
    investigatingManager: string;
    gradeOfComplaint: string;
    investigationDetails: string;
    learning: string;
    status: string;
    complaintResponseSent: string;
    dateResponseSent: number | undefined;
    dateClosed: number | undefined;
    filename?: string;
}

export interface AdditionalNotes {
    uid: string;
    comments: string;
    versionAdded: number;
    filename?: string;
}

export enum EquipmentList {
    LifePak15 = "LifePak15",
    AED = "AED",
    LUCAS = "LUCAS",
    TrolleyBed = "TrolleyBed",
    Carry_Chair = "CarryChair",
    WheelChair = "WheelChair",
    ScoopStretcher = "ScoopStretcher",
    LongBoard = "LongBoard",
    MangarElk = "MangarElk",
    TurnDisk = "TurnDisk",
    CarrySheet = "CarrySheet",
    BananaBoard = "BananaBoard",
    MicroVent = "MicroVent",
    Other = "Other"
}

export enum DefectGrade {
    High = "High",
    Medium = "Medium",
    Low = "Low"
}
export enum EquipmentReplaceAnswer {
    Yes = "Yes",
    No = "No",
    NotApplicable = "NotApplicable"
}

export interface VehicleDefectFormV1 {
    version: number;
    staffVehicleDefectForm: StaffVehicleDefectFormV1;
    adminVehicleDefectForm: AdminVehicleDefectFormV1;
    notes: AdditionalNotes[];
}

/** Equipment Defect Form */
export interface EquipmentDefectFormV1 {
    version: number;
    staffEquipmentDefectForm: StaffEquipmentDefectFormV1;
    adminEquipmentDefectForm: AdminEquipmentDefectFormV1
    notes: AdditionalNotes[];
}

export interface StaffVehicleDefectFormV1 {
    location: GrsLocation;
    dateOfDefect?: number;
    vehicleRegistration: string;
    detailsOfDefect: string;
}

export interface AdminVehicleDefectFormV1 {
    defectGrade: DefectGrade;
    vehicleTakenOutOfCommission: YesNoType;
    vehicleNotTakenOutOfCommissionReason: string;
    dateBookedForRepair?: number;
    nameOfGarage: string;
    estimatedTimeForRepair: string;
    dateVehicleReturned?: number;
    comments: string;
    filename?: string;
    dateClosed?: number;
}

export interface AdminEquipmentDefectFormV1 {
    defectGrade: DefectGrade;
    equipmentTakenOutOfCommission: YesNoType;
    hasEquipmentBeenCorrectlyTagged: YesNoType;
    equipmentNotTakenOutOfCommission: string;
    equipmentRepairable: YesNoType;
    positiveActionTaken: string;
    negativeActionTaken: string;
    equipmentReplacedForOperationalUse: EquipmentReplaceAnswer;
    comments: string;
    filename?: string;
    dateClosed?: number;
}

export interface StaffEquipmentDefectFormV1 {
    location: GrsLocation;
    dateOfDefect?: number;
    detailsOfDefect: string;
    equipment: EquipmentList;
    otherEquipment: string;
    serialNumber: string;
    locationOfEquipment: string;
    equipmentCorrectlyTaggedAndDealtWith: YesNoType;
    detailsOfNotBeingTakenOutCorrectly: string;
}

export interface GrsLocation {
    entryName?: string;
    entryId?: number;
    calendarName: string;
    calendarId: number;
    calendarType: string;
    venueName?: string;
    venueId?: number;
}

/** METHODS */
export function createBlankIncidentReportFormV1(reporter: UserData): IncidentReportFormV1 {
    return {
        version: 0,
        staffReport: {
            incidentDetails: {
                division: 0,
                divisionName: "",
                locationOfIncident: [],
                dateOfIncident: undefined,
                frontlineDetails: [],
                cadNumber: undefined,
                nhsRefNumber: undefined
            },
            reporterDetails: {
                name: `${reporter.firstName} ${reporter.lastName}`,
                skillLevel: reporter.clinicalGrade,
                telephone: reporter.phoneNumber,
                email: reporter.email
            },
            incidentDescription: {
                type: IncidentDescriptionType.Patients,
                category: IncidentCategoryType.Accident_And_Injury,
                description: "",
                actionTaken: "",
                witnesses: []
            }
        },
        managerReport: {
            investigatingManager: {
                username: "",
                currentIncidentStatus: CurrentIncidentStatusType.Local_Review
            },
            severity: {
                outcome: OutcomeType.No_Adverse_Outcome,
                harmLevel: HarmLevelType.No_Harm,
                seriousIncidentReviewOutcome: SeriousIncidentReviewOutcomeType.Not_Applicable
            },
            dutyOfCandour: {
                engaged: YesNoType.No,
                notEngagedAnswer: undefined,
                nominatedIndividual: "",
                dateOfInitialContact: undefined,
                dateOfLetterSent: undefined,
                dateOfFinalLetterAndReport: undefined
            },
            investigationDetails: {
                dateStarted: undefined,
                outcome: "",
                lessonsLearned: ""
            },
            closure: {
                dateClosed: undefined,
                feedbackProvided: YesNoType.No,
                externalReportRequired: YesNoType.No,
                reportedTo: "",
                dateReported: undefined,
            }
        },
        notes: []
    }
}

export function createBlankComplaintFormV1(): ComplaintFormV1 {
    const date = new Date().getTime() / 1000;
    const randomNumberToAdd = getRandomNumber(0, 1000);
    const randomNumberToSubtract = getRandomNumber(0, 100000);
    const id = Math.floor(((date + randomNumberToAdd) - randomNumberToSubtract) / 2);
    return {
        version: 0,
        complaintDetails: {
            referenceNumber: `MC_COMP_${id}`,
            dateReceived: undefined,
            receivedBy: "",
            complaintsName: "",
            organisation: "",
            dateOfIncident: undefined,
            division: 0,
            divisionName: "",
            venueLocationDetails: [],
            frontlineDetails: [],
            cadNumber: undefined,
            staffInvolved: "",
            detailsOfComplaint: ""
        },
        investigationDetails: {
            investigatingManager: "",
            gradeOfComplaint: GradeOfComplaintType.Low,
            investigationDetails: "",
            learning: "",
            status: ComplaintStatus.Open,
            complaintResponseSent: YesNoType.No,
            dateResponseSent: undefined,
            dateClosed: undefined
        },
        notes: []
    }
}

export function createBlankVehicleDefectFormV1(): VehicleDefectFormV1 {
    return {
        version: 0,
        staffVehicleDefectForm: {
            dateOfDefect: moment().startOf("day").unix(),
            detailsOfDefect: "",
            vehicleRegistration: "",
            location: {
                venueName: "",
                venueId: 0,
                entryId: 0,
                entryName: "",
                calendarType: "",
                calendarName: "",
                calendarId: 0
            }
        },
        adminVehicleDefectForm: {
            defectGrade: DefectGrade.Low,
            vehicleTakenOutOfCommission: YesNoType.No,
            vehicleNotTakenOutOfCommissionReason: "",
            dateBookedForRepair: undefined,
            nameOfGarage: "",
            estimatedTimeForRepair: "",
            dateVehicleReturned: undefined,
            comments: "",
            filename: "",
            dateClosed: undefined
        },
        notes: []
    }
}

export function createBlankEquipmentDefectFormV1(): EquipmentDefectFormV1 {
    return {
        version: 0,
        staffEquipmentDefectForm: {
            dateOfDefect: moment().startOf("day").unix(),
            detailsOfDefect: "",
            equipment: EquipmentList.LifePak15,
            otherEquipment: "",
            location: {
                venueName: "",
                venueId: 0,
                entryId: 0,
                entryName: "",
                calendarType: "",
                calendarName: "",
                calendarId: 0
            },
            serialNumber: "",
            locationOfEquipment: "",
            equipmentCorrectlyTaggedAndDealtWith: YesNoType.No,
            detailsOfNotBeingTakenOutCorrectly: ""
        },
        adminEquipmentDefectForm: {
            defectGrade: DefectGrade.Low,
            equipmentTakenOutOfCommission: YesNoType.No,
            hasEquipmentBeenCorrectlyTagged: YesNoType.Yes,
            equipmentNotTakenOutOfCommission: "",
            equipmentRepairable: YesNoType.No,
            positiveActionTaken: "",
            negativeActionTaken: "",
            equipmentReplacedForOperationalUse: EquipmentReplaceAnswer.NotApplicable,
            comments: "",
            dateClosed: undefined
        },
        notes: []
    }
}
