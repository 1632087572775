import {ReportState} from "../../../../../../api/im";
import {routeNames} from "../../../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import React, {createRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {deleteReportFromService} from "../../../../../../store/reports/actions/ReportActions";
import {showSuccessToast} from "../../../../../../utils/toastUtils";
import {ReportListMobileItemProps} from "../ReportListMobileItem";

export function useReportListMobileItem({report, onReportDeleted}: ReportListMobileItemProps) {
    const history = useHistory();
    const ref = createRef<HTMLDivElement>();
    let downX: number;
    const {loading} = useSelector((rootState: RootStore) => rootState.report);
    const {searchQueries} = usePageUrl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!ref.current) return;
        ref.current.style.transform = "translate(0)";
    }, [report, ref]);

    function getAuthorInitials(): string {
        const {staffName} = report;
        const [firstName, lastName] = staffName.split(" ");

        return `${firstName.at(0)}${lastName.at(0)}`;
    }

    const viewReadonlyReport = () => {
        const {id} = report;
        history.push({
            pathname: `${routeNames.reportView.path}/${id}`,
            search: searchQueries
        });
    };

    const editReport = () => {
        const {id} = report;
        history.push({
            pathname: `${routeNames.reportEdit.path}/${id}`,
            search: searchQueries
        });
    };

    function onClick() {
        const {state} = report;
        switch (state) {
            case ReportState.CompletedReview:
            case ReportState.UnderReview:
                viewReadonlyReport();
                break;
            case ReportState.PendingReview:
                editReport();
                break;
        }
    }

    const onPointerDown = (e: React.PointerEvent<HTMLDivElement>) => {
        downX = e.clientX;
        ref.current?.addEventListener("pointermove", onPointerMove);
    };

    const onPointerMove = (e: any) => {
        if (!ref.current) return;
        const newX = e.clientX;
        const offset = newX - downX;

        if (offset < -7) {
            ref.current.style.transform = "translate(-83px)";
        } else {
            ref.current.style.transform = "translate(0)";
        }
    };

    const onPointerUp = (e: React.PointerEvent) => {
        ref.current?.removeEventListener("pointermove", onPointerMove);
    };

    const onDeleteReport = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteReportFromService(report.id));
        if (!success) {
            return false;
        }
        showSuccessToast("Deleted report");
        await onReportDeleted();
        return success;
    };

    return {
        getAuthorInitials,
        onClick,
        onPointerDown,
        onPointerUp,
        ref,
        loading,
        onDeleteReport
    };
}
