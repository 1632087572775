import React, {useEffect, useState} from "react";
import {ReportState} from "../../../../../api/im";

const ReportStateAmI = ({reportStates, currentReportState, children}: Props) => {
    const [canSee, setCanSee] = useState<boolean>(false);

    useEffect(() => {
        if (reportStates.length === 0) {
            setCanSee(true);
            return;
        }

        setCanSee(reportStates.indexOf(currentReportState) > -1);
    }, [reportStates, currentReportState]);
    return <React.Fragment>{canSee ? children : null}</React.Fragment>;
};

export default ReportStateAmI;

interface Props {
    currentReportState: ReportState;
    reportStates: ReportState[];
    children: JSX.Element | JSX.Element[] | number | string | boolean | undefined | null;
}
