import React from "react";
import {
    IncidentSeverityV1,
    SeriousIncidentReviewOutcomeType
} from "../../../../../../../../../api/reports/api";
import ReportHeader from "../../../../Components/ReportHeader";
import PrintableFormRow from "../../../../Components/PrintableFormRow";

const Severity = (props: IncidentSeverityV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"0px"}
                alignment={"left"}
                reportHeaderText={`Severity`}
            />
            <PrintableFormRow rowName={"Outcome"} rowContent={props.outcome} />
            <PrintableFormRow rowName={"Harm Level"} rowContent={props.harmLevel} />
            {props.seriousIncidentReviewOutcome && (
                <PrintableFormRow
                    rowName={"Serious Incident Review Outcome"}
                    rowContent={props.seriousIncidentReviewOutcome}
                />
            )}
            {props.seriousIncidentReviewOutcome &&
                props.seriousIncidentReviewOutcome !==
                    SeriousIncidentReviewOutcomeType.Not_Applicable && (
                    <PrintableFormRow
                        rowName={"Serious Incident File"}
                        rowContent={props.filename ? props.filename : "File not uploaded"}
                    />
                )}
        </React.Fragment>
    );
};

export default Severity;
