import React, {useEffect, useState} from "react";
import {DDProps, VenueDropdownProps} from "./Helpers/dropdownUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {Venue} from "../../api/grs";
import Select, {SingleValue} from "react-select";
import {Loading} from "store-fetch-wrappers";

/** Dropdown that will show all users assigned in an entry or all users in the database. */
const VenueDropdown = (props: VenueDropdownProps) => {
    const [venueOptions, setVenueOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);

    const venueListStore = useSelector((state: RootStore) => state.venueList);

    /** Get the calendar list upon mounting */
    useEffect(() => {
        if (!venueListStore.data) return;
        processVenueListIncoming(venueListStore.data);
    }, []);

    const processVenueListIncoming = (venues: Venue[]) => {
        const options = getVenueDropdownOptions(venues);
        setVenueOptions(options);

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
            return;
        }

        const venue = getSelectedVenueOption(venues, +props.id);
        setSelectedOption(venue ? {label: venue.name, value: venue.id} : undefined);
        props.changeOption(venue);
    };

    useEffect(() => {
        if (!venueListStore.data) return;
        const calendar = getSelectedVenueOption(venueListStore.data, +props.id);
        setSelectedOption(calendar ? {label: calendar.name, value: calendar.id} : undefined);
    }, [props.id, venueListStore.data]);

    useEffect(() => {
        if (!venueListStore.data) return;
        const filtered = venueListStore.data.filter(
            (item) => item.calendarId === props.calendarIdForVenue
        );

        const options = getVenueDropdownOptions(filtered);
        setVenueOptions(options);

        const venue = getSelectedVenueOption(venueListStore.data, +props.id);
        setSelectedOption(venue ? {label: venue.name, value: venue.id} : undefined);
        props.changeOption(venue);
    }, [props.calendarIdForVenue, venueListStore.data]);

    /** Fired when a new option is selected */
    const handleVenueChanged = (p: SingleValue<DDProps>) => {
        if (!p) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const venue = getSelectedVenueOption(venueListStore.data || [], +p.value);
        setSelectedOption(venue ? {label: venue.name, value: venue.id} : undefined);
        props.changeOption(venue);
    };

    return (
        <React.Fragment>
            {venueListStore.loading && <Loading type={"three-ring"} showLoadingText={false} />}
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={venueOptions}
                onChange={handleVenueChanged}
                isSearchable={props.searchable}
                value={selectedOption}
                noOptionsMessage={() => "No Venue Found"}
                placeholder="Select Venue"
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default VenueDropdown;

function getVenueDropdownOptions(list: Venue[]): DDProps[] {
    return list.map((item) => {
        return {
            label: item.name,
            value: item.id
        };
    });
}
function getSelectedVenueOption(list: Venue[], id: number): Venue | undefined {
    const index = list.findIndex((item) => item.id === id);

    return list[index];
}
