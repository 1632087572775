import React from "react";
import FormHeader from "../../../../../../../Form/FormHeader";
import {GrsLocation, StaffVehicleDefectFormV1} from "../../../../../../../../api/reports/api";
import {setStaffVehicleDefectDetails} from "../../../../../../../../store/reports/actions/ReportActions";
import FormRow from "../../../../../../../Form/FormRow";
import {useDispatch} from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../../../../../Button/DatePickerInputButton";
import {DebounceInput} from "react-debounce-input";
import {canUserEditDefectFormPartA} from "../../../../../../../Hooks/useCanUserEditForms";
import CalendarListDropdown from "../../../../../../../Dropdown/Components/CalendarListDropdown";

const StaffVehicleDefectDetails = (props: StaffVehicleDefectFormV1) => {
    const dispatch = useDispatch();
    const canEdit = canUserEditDefectFormPartA();
    return (
        <React.Fragment>
            <div className="mt-4">
                <FormHeader headerName={"Staff Section - Vehicle Defect Form"} />
                <FormRow rowName={"Calendar"} columnDetailClassName={"pl-0 pr-0"}>
                    <CalendarListDropdown
                        searchable={false}
                        onChange={(calendar) => {
                            if (!calendar) return;
                            const location: GrsLocation = {
                                calendarId: calendar.id,
                                calendarType: calendar.calendarType,
                                calendarName: calendar.name
                            };
                            dispatch(
                                setStaffVehicleDefectDetails({
                                    ...props,
                                    location
                                })
                            );
                        }}
                        clearable={false}
                        calendarId={props.location.calendarId}
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Incident date"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={
                            props.dateOfDefect
                                ? moment(props.dateOfDefect * 1000).toDate()
                                : undefined
                        }
                        onChange={(date) => {
                            const newDate = date ? moment(date).unix() : undefined;
                            dispatch(
                                setStaffVehicleDefectDetails({
                                    ...props,
                                    dateOfDefect: newDate
                                })
                            );
                        }}
                        dateFormat="do MMMM yyyy"
                        portalId="root-portal"
                        isClearable={canEdit}
                        customInput={<DatePickerInputButton />}
                        placeholderText="Select modified date"
                        disabled={!canEdit}
                    />
                </FormRow>
                <FormRow rowName={"Vehicle registration"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setStaffVehicleDefectDetails({
                                    ...props,
                                    vehicleRegistration: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.vehicleRegistration}
                        placeholder="Enter vehicle registration here..."
                    />
                </FormRow>
                <FormRow rowName={"Defect details"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element="textarea"
                        inputType={"input"}
                        onChange={(event) =>
                            dispatch(
                                setStaffVehicleDefectDetails({
                                    ...props,
                                    detailsOfDefect: event.target.value
                                })
                            )
                        }
                        disabled={!canEdit}
                        value={props.detailsOfDefect}
                        placeholder="Enter details here here..."
                    />
                </FormRow>
            </div>
        </React.Fragment>
    );
};

export default StaffVehicleDefectDetails;
