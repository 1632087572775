import React from "react";
import {
    ComplaintFormInvestigationDetailsV1,
    YesNoType
} from "../../../../../../../../api/reports/api";
import ReportHeader from "../../../Components/ReportHeader";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../../store/Store";
import PrintableFormRow from "../../../Components/PrintableFormRow";
import {getStaffMemberFromList} from "../../Incident/PartB/Sections/InvestigatingManager";
import {formatUnixToDDMMYYYY} from "../../../../../../../../utils/momentUtils";

const InvestigationDetails = (props: ComplaintFormInvestigationDetailsV1) => {
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Investigation Details`}
            />
            <PrintableFormRow
                rowName={"Investigating Manager"}
                rowContent={getStaffMemberFromList(staffList, props.investigatingManager)}
            />
            <PrintableFormRow rowName={"Grade"} rowContent={props.gradeOfComplaint} />
            <PrintableFormRow
                rowName={"Investigation Details"}
                rowContent={props.investigationDetails}
            />
            <PrintableFormRow rowName={"Learning"} rowContent={props.learning} />
            <PrintableFormRow rowName={"Status"} rowContent={props.status} />
            <PrintableFormRow
                rowName={"Date Closed"}
                rowContent={
                    props.dateClosed ? formatUnixToDDMMYYYY(props.dateClosed) : "Date not added"
                }
            />
            <PrintableFormRow rowName={"Response Sent?"} rowContent={props.complaintResponseSent} />
            {props.complaintResponseSent === YesNoType.Yes && (
                <React.Fragment>
                    <PrintableFormRow
                        rowName={"Date Response Sent"}
                        rowContent={
                            props.dateResponseSent
                                ? formatUnixToDDMMYYYY(props.dateResponseSent)
                                : "Date not added"
                        }
                    />
                    <PrintableFormRow
                        rowName={"Response File"}
                        rowContent={props.filename ? props.filename : "File not added"}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default InvestigationDetails;
