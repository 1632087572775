import React from "react";
import ReportHeader from "../../../Components/ReportHeader";
import {ManagerIncidentFormV1} from "../../../../../../../../api/reports/api";
import InvestigatingManager from "./Sections/InvestigatingManager";
import Severity from "./Sections/Severity";
import DutyOfCandour from "./Sections/DutyOfCandour";
import InvestigationDetails from "./Sections/InvestigationDetails";
import ClosureOfIncident from "./Sections/ClosureOfIncident";

const ManagerIncidentForm = (props: ManagerIncidentFormV1) => {
    return (
        <React.Fragment>
            <ReportHeader
                padding={"0.75rem"}
                marginTop={"15px"}
                alignment={"left"}
                reportHeaderText={`Manager Incident Form`}
            />
            <InvestigatingManager {...props.investigatingManager} />
            <Severity {...props.severity} />
            <DutyOfCandour {...props.dutyOfCandour} />
            <InvestigationDetails {...props.investigationDetails} />
            <ClosureOfIncident {...props.closure} />
        </React.Fragment>
    );
};

export default ManagerIncidentForm;
