// eslint-disable-next-line no-shadow
export enum PlatformOS {
    Mobile = "Mobile",
    Desktop = "Desktop"
}

export function usePlatform() {
    const userAgent = window.navigator.userAgent;
    const macOSPlatforms = "Macintosh";
    const windowsPlatforms = "Windows";
    const iosPlatforms = "iPhone";
    const androidPlatforms = "Android";

    if (userAgent.includes(macOSPlatforms)) {
        return PlatformOS.Desktop;
    }
    if (userAgent.includes(windowsPlatforms)) {
        return PlatformOS.Desktop;
    }
    if (userAgent.includes(iosPlatforms)) {
        return PlatformOS.Mobile;
    }
    if (userAgent.includes(androidPlatforms)) {
        return PlatformOS.Mobile;
    }

    return PlatformOS.Desktop;
}
